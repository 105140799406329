import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const AwsJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>AWS Jobs</title>
                <meta name="description" content="Find the best AWS jobs in the US and India. Connect with leading companies and advance your career with AWS Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>AWS Job Finder</h1>
        <p className={classes.subTitle}>Explore Top AWS Job Opportunities to Build and Manage Cloud Solutions</p>
      <JobFinder jobTitle={"AWS"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is AWS?</h3>
        <p>Amazon Web Services (AWS) is the world's leading cloud computing platform, offering a wide range of on-demand cloud services such as computing power, storage, networking, databases, and AI/ML solutions. AWS enables businesses to scale efficiently, reduce IT costs, and deploy applications securely.</p>
        <h3>What Do AWS Professionals Do?</h3>
        <p>AWS professionals, including AWS cloud engineers, architects, and developers, handle various tasks related to cloud infrastructure. Their key responsibilities include:</p>
        <ul>
          <li>Designing and deploying scalable cloud solutions based on business needs.</li>
          <li>Managing cloud security to ensure compliance with industry standards.</li>
          <li>Configuring and maintaining AWS services like EC2, S3, RDS, and Lambda.</li>
          <li>Implementing DevOps practices with tools like AWS CodePipeline and CloudFormation.</li>
          <li>Optimizing cloud costs by selecting appropriate pricing models.</li>
          <li>Ensuring high availability and disaster recovery for business continuity.</li>
        </ul>

        <h3>Key Features of AWS</h3>
        <ol>
          <li><b>Scalability</b> – Easily scale applications up or down based on demand.</li>
          <li><b>Security & Compliance</b> – Advanced security features and global compliance standards.</li>
          <li><b>Pay-as-you-go Pricing</b> – Cost-effective model with no upfront costs.</li>
          <li><b>Wide Range of Services</b> – Compute, storage, AI, analytics, networking, and more.</li>
          <li><b>Global Infrastructure</b> – Data centers in multiple regions ensure low latency.</li>
          <li><b>Automation & DevOps</b> – Tools like AWS Lambda, CloudFormation, and Auto Scaling.</li>
        </ol>

        <h3>Salary Expectations for AWS Professionals</h3>
        <p>AWS professionals command high salaries due to the increasing demand for cloud expertise.</p>
        <ul>
          <li><b>India:</b> AWS engineers earn between ₹8-25 LPA, while AWS architects can earn ₹30 LPA or more.</li>
          <li><b>US:</b> AWS professionals typically earn $100,000 - $180,000 per year, depending on experience and specialization.</li>
        </ul>

        <h3>Scope of AWS</h3>
        <ol>
          <li><b>Cloud Computing Boom</b> – Increasing adoption across industries like finance, healthcare, and e-commerce.</li>
          <li><b>AI & Machine Learning</b> – AWS provides cloud-based AI/ML solutions for businesses.</li>
          <li><b>Serverless Computing</b> – Services like AWS Lambda make application development more efficient.</li>
          <li><b>IoT & Edge Computing</b> – AWS enables businesses to manage IoT devices at scale.</li>
          <li><b>Big Data & Analytics</b> – AWS offers services like Redshift, Glue, and Athena for data analysis.</li>
        </ol>

        <h3>Conclusion</h3>
        <p>AWS dominates the cloud industry, making it an essential skill for IT professionals. As businesses continue to migrate to the cloud, the demand for AWS experts in cloud computing, DevOps, and cybersecurity will keep growing. AWS certification and expertise open up high-paying job opportunities worldwide, making it a valuable career path in today’s tech landscape.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default AwsJob;
