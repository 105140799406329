import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const SocialMediaJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Social Media Jobs</title>
                <meta name="description" content="Find the best social media jobs in the US and India. Connect with leading companies and advance your career with Social Media Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Social Media Job Finder</h1>
        <p className={classes.subTitle}>Discover Top Social Media Jobs to Manage Campaigns and Build Online Communities</p>
      <JobFinder jobTitle={"Social Media"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>Social Media</h3>
        <p>Social media has completely changed how individuals interact with brands, communicate, and share information. In today's digital age, social media sites like Facebook, Instagram, Twitter, LinkedIn, and TikTok are crucial for everything from personal interactions to company promotion.</p>

        <h3>The Role of Social Media</h3>
        <p>There are several uses for social media, including:</p>
        <ul>
          <li><b>Connecting People:</b> Helps users in maintaining relationships with their professional, family, and friend networks.</li>
          <li><b>Content Sharing:</b> Allows people and organizations to exchange images, videos, articles, and ideas.</li>
          <li><b>Brand Promotion:</b> Provides businesses the means to connect with, interact with, and attract new clients.</li>
          <li><b>Customer Service:</b> Social media is used by brands to provide real-time customer support and feedback.</li>
          <li><b>News & Trends:</b> Serves as the main resource for breaking news, current topics, and viral content.</li>
        </ul>

        <h3>Popular Social Media Platforms</h3>
        <ul>
          <li><b>Facebook:</b> The largest platform for social networking and business promotions.</li>
          <li><b>Instagram:</b> Focuses on visual content, ideal for influencers and brands.</li>
          <li><b>Twitter (X):</b> A hub for real-time news, updates, and discussions.</li>
          <li><b>LinkedIn:</b> A professional networking platform for job seekers and businesses.</li>
          <li><b>TikTok:</b> A leading short-video platform, popular for entertainment and marketing.</li>
        </ul>

        <h3>Salary in Social Media Jobs</h3>
        <p>Social media professionals can earn competitive salaries depending on their roles and experience:</p>

        <ul>
          <li><b>Social Media Manager:</b> $50,000 – $80,000 per year in USA and ₹4 – ₹10 LPA in India.</li>
          <li><b>Content Creator/Influencer:</b> Earnings vary based on engagement, sponsorships, and platforms.</li>
          <li><b>Digital Marketer (Social Media Focused):</b> $45,000 – $75,000 per year in USA and ₹3 – ₹8 LPA in India.</li>
          <li><b>Social Media Analyst:</b> $50,000 – $85,000 per year in USA and  ₹5 – ₹12 LPA in India.</li>
        </ul>

        <h3>Future of Social Media</h3>
        <p>AI, AR/VR, and new engagement trends are all contributing to the continuous development of social media. The future of digital interactions is being shaped by influencer marketing, short-form films, and tailored content.</p>

        <h3>Conclusion</h3>
        <p>Social media is an effective tool for marketing, communication, and brand expansion in addition to being a source of pleasure. Effective use of social media platforms, whether for personal or professional purposes, can result in success in the digital sphere.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default SocialMediaJob;
