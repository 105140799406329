import React, { useState } from 'react'
import classes from "./styles/SimpleInterest.module.css";
import RightSidebar from '../../../common/RightSidebar';
import { Helmet } from 'react-helmet';
import { FullWidthWiraaAds } from '../../../common/Ads/FullWidthWiraaAds';

const GstCalculator = () => {
    const [inputValue, setInputValue] = useState();
    const [interestDetails, setInterestDetails] = useState(null);
    const [addInput, setAddInput] = useState({"gstr" : "18"})
    const [errorMessage, setErrorMessage] = useState();


    const handleGstCalculation = () => {
      const amount = parseFloat(inputValue);
      const gstRate = parseFloat(addInput.gstr);
  
      if (isNaN(amount) || amount <= 0) {
          setErrorMessage('Please enter a valid amount.');
          setInterestDetails(null);
          return;
      }
  
      if (isNaN(gstRate) || gstRate < 0) {
          setErrorMessage('Please enter a valid GST rate.');
          setInterestDetails(null);
          return;
      }
  
      const gstAmount = (amount * gstRate) / 100;
      const totalAmount = amount + gstAmount;
  
      setInterestDetails({
          gst: gstAmount.toFixed(2),
          total: totalAmount.toFixed(2),
      });
  
      setErrorMessage('');
  };
  
  
    
      const handleClearBtn = () => {
        setInputValue("");
        setAddInput({"gstr" : "18"});
        setInterestDetails(null);
        setErrorMessage("");
      }  

  return (
    <div className={classes.page}>
      <Helmet>
                <title>GST Calculator - Wiraa tools</title>
                <meta name="description" content="Easily calculate Goods and Services Tax (GST) with our accurate GST Calculator. Get quick GST-inclusive and exclusive price calculations for India with just a few clicks." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>GST Calculator</h1>
        <p className={classes.subTitle}>Fast & Accurate GST Calculator for Inclusive & Exclusive Tax Computation</p>
      <div className={classes.innerCon}>
        <div className={classes.inputCon}>
            <p className={classes.inputplaceholder}>Total Amount</p>
            <input value={inputValue} onChange={(e) => setInputValue(e.target.value)} className={classes.inputfield} placeholder='Enter amount'/>
            {errorMessage && <p className={classes.errorMsg}>{errorMessage}</p>}
        </div>
        <div className={classes.additionalInputCon}>
                    <div className={classes.additionalInputDiv}>
                        <p className={classes.addplaceholder}>GST Rate %</p>
                        <input value={addInput.gstr} onChange={(e) => setAddInput({ ...addInput, gstr: e.target.value })} className={classes.addInputfield} placeholder='Enter gst rate %'/>
                    </div>
                </div>
        <div className={classes.btnCon}>
            <button className={classes.convertBtn} onClick={handleGstCalculation}>Calculate</button>
            <button className={classes.clearBtn} onClick={handleClearBtn}>Clear</button>
        </div>
        {interestDetails ? <div className={classes.outputSalaryCon}>
                    <div>
                      <p className={classes.outputplaceholder}>Total GST:</p>
                      <p className={classes.outputfield}>{interestDetails.gst}</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Total Amount:</p>
                      <p className={classes.outputfield}>{interestDetails.total}</p>
                    </div>  
        </div> : <div className={classes.outputSalaryCon}>
        <div>
                      <p className={classes.outputplaceholder}>Total GST:</p>
                      <p className={classes.outputfield}>0</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Total Amount:</p>
                      <p className={classes.outputfield}>0</p>
                    </div>
          </div>}
      </div>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>GST- Goods and Services Tax</h3>
        <p>GST, or Goods and Services Tax, is an indirect tax implemented in India on July 1, 2017. GST is levied on goods and services and has replaced previous indirect taxes.</p>

        <h3>What is a GST Calculator?</h3>
        <p>A GST Calculator is a tool for calculating the Goods and Services Tax (GST) payable or refundable on the sale or purchase of goods and services. It streamlines GST calculations by delivering quick and accurate results for tax liabilities, allowing users to calculate the applicable tax amount based on the GST rate and transaction value.</p>

        <h3>Key Features</h3>
        <ol>
          <li><b>GST Inclusion/Exclusion:</b> Calculate GST-inclusive or GST-exclusive amounts.</li>
          <li><b>Customizable Rates:</b> Supports different GST rates (e.g., 5%, 12%, 18%, 28%).</li>
          <li><b>Time-Saving:</b> Eliminates manual computation errors and saves time.</li>
        </ol>

        <h3>How to Calculate GST with a GST Calculator</h3>
        <ol>
          <li><b>Enter the Amount:</b> Input the base value (original cost) of the goods or services.</li>
          <li><b>Choose the GST Rate:</b> Select the applicable GST rate (e.g., 5%, 12%, 18%, or 28%) based on the product or service category.</li>
          <li>
            <b>Select Calculation Type:</b>
            <ul>
              <li><b>Add GST:</b> Calculate the final price including GST.</li>
              <li><b>Remove GST:</b> Determine the base price excluding GST from a GST-inclusive amount.</li>
            </ul>
          </li>
          <li>
            <b><b>View Results:</b> The calculator will instantly display:</b>
            <ul>
              <li>GST amount.</li>
              <li>Final price including GST (if adding).</li>
              <li>Original price excluding GST (if removing).</li>
            </ul>
          </li> 
        </ol>

        <h3>Example:</h3>
        <ul>
          <li><b>Base Amount:</b> ₹1,000</li>
          <li>
            <p><b>GST Rate:</b> 18%</p>
            <ul>
              <li>GST Amount: ₹180</li>
              <li>Final Price (with GST): ₹1,180</li>
            </ul>
          </li>
        </ul>
        <h3>Benefits of Using a GST Calculator</h3>
        <ol>
          <li><b>Accurate Tax Computations:</b> Ensures correct GST calculations, eliminating the possibility of errors in manual computations.</li>
          <li><b>Time-Saving:</b> Simplifies and expedites the process of calculating GST-inclusive or GST-exclusive prices.</li>
          <li><b>User-Friendly:</b> Easy to use, even for those with little tax understanding, and produces speedy answers with few inputs.</li>
          <li><b>Customizable for Rates:</b> Supports various GST rates (5%, 12%, 18%, 28%) applicable to different goods and services, enhancing versatility.</li>
          <li><b>Enhanced Financial Planning:</b> Helps businesses, accountants, and consumers better understand their tax liabilities, guaranteeing compliance and making educated decisions.</li>
        </ol>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default GstCalculator;
