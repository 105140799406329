import React, { useState } from 'react'
import classes from "./styles/passwordgenerator.module.css";
import RightSidebar from '../../../common/RightSidebar';
import { Helmet } from 'react-helmet';
import { FullWidthWiraaAds } from '../../../common/Ads/FullWidthWiraaAds';

const PasswordGenerator = () => {
    const [password, setPassword] = useState("");
    const [length, setLength] = useState(12);
    const [includeNumbers, setIncludeNumbers] = useState(true);
    const [includeSymbols, setIncludeSymbols] = useState(true);

    const generatePassword = () => {
        const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
        const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const numberChars = "0123456789";
        const symbolChars = "!@#$%^&*()_+-=[]{}|;:'\",.<>?/";
    
        let characterPool = lowercaseChars + uppercaseChars;
        if (includeNumbers) characterPool += numberChars;
        if (includeSymbols) characterPool += symbolChars;
    
        let newPassword = "";
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characterPool.length);
          newPassword += characterPool[randomIndex];
        }
    
        setPassword(newPassword);
      };

      const handleReset = () => {
        setPassword("");
      }


  return (
    <div className={classes.page}>
      <Helmet>
                <title>Random Password Generator - Wiraa tools</title>
                <meta name="description" content="Create strong and secure passwords with our Random Password Generator. Customize length, include numbers & symbols, and enhance security effortlessly!" />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Random Password Generator</h1>
        <p className={classes.subTitle}>Generate Secure Passwords Instantly: Strong, Random & Reliable!</p>
      <div className={classes.innerCon}>
        <div className={classes.additionalInputCon}>
            <div className={classes.additionalInputDiv}>
                <p className={classes.inputplaceholder}>Password Length: {length}</p>
                <input type="range" min="6" max="30" value={length} onChange={(e) => setLength(e.target.value)} className={classes.addInputfield}/>
            </div>
            <div className={classes.additionalInputDiv}>
                <label>
                <input type="checkbox" className={classes.largeCheckbox} checked={includeNumbers} onChange={() => setIncludeNumbers(!includeNumbers)} />
                Include Numbers
                </label>
            </div>
            <div className={classes.additionalInputDiv}>
                <label>
                <input type="checkbox" className={classes.largeCheckbox} checked={includeSymbols} onChange={() => setIncludeSymbols(!includeSymbols)}/>
                Include Symbols
                </label>
            </div>
        </div>
        <div className={classes.btnCon}>
            <button className={classes.convertBtn} onClick={generatePassword}>Generate</button>
            <button className={classes.clearBtn} onClick={handleReset}>Reset</button>
        </div>
        <div className={classes.outputCon}>
                  <p className={classes.outputplaceholder}>Password:</p>
                  <p className={classes.outputfield}>{password}</p>
                </div>
      </div>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default PasswordGenerator;
