import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const DataAnalyticsJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Data Analytics Jobs</title>
                <meta name="description" content="Find the best data analytics jobs in the US and India. Connect with leading companies and advance your career with Data Analytics Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Data Analytics Job Finder</h1>
        <p className={classes.subTitle}>Discover Top Data Analytics Jobs to Transform Complex Data into Strategic Insights</p>
      <JobFinder jobTitle={"Data Analytics"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is Data Analytics?</h3>
        <p>Data analytics is the process of studying raw data to extract relevant insights, find trends, and help decision-making. Data analytics is used by companies in various sectors to boost customer satisfaction and operational efficiency. As big data, AI, and ML have grown in popularity, data analytics has emerged as a crucial instrument for development and innovation.</p>
        <h3>What Does a Data Analyst Do?</h3>
        <p>A data analyst is instrumental in converting data into practical insights. Their tasks include:</p>
        <ul>
          <li><b>Data Gathering & Preparation:</b> Acquiring data from diverse sources and ensuring its precision. </li>
          <li><b>Exploratory Data Investigation (EDI):</b> Recognizing patterns and relationships through statistical methods.</li>
          <li><b>Data Representation:</b> Developing reports and dashboards utilizing tools such as Tableau, Power BI, or Excel.  </li>
          <li><b>Predictive Modeling:</b> Employing machine learning techniques to anticipate trends and results. </li>
          <li><b>Business Intelligence:</b> Offering insights that influence strategic choices. </li>
        </ul>
        <h3>Key Features of Data Analytics</h3>
        <ul>
          <li><b>Descriptive Analytics:</b> Examining historical data to comprehend trends.</li>
          <li><b>Diagnostic Analytics:</b> Determining the reasons behind specific trends or issues.</li>
          <li><b>Predictive Analytics:</b> Applying statistical models to project future trends.</li>
          <li><b>Prescriptive Analytics:</b> Suggesting data-informed actions to achieve improved outcomes.</li>
        </ul>
        <h3>Salary Expectations in Data Analytics</h3>
        <p>The demand for data analytics professionals is rising globally, with competitive salaries:</p>
        <ul>
          <li><b>India:</b> Entry-level salaries range from ₹6–10 LPA, while experienced analysts earn ₹15–30 LPA.</li>
          <li><b>US:</b> Salaries typically range from $70,000–$120,000, with senior roles exceeding $150,000.</li>
        </ul>
        <h3>Scope of Data Analytics</h3>
        <p>Data analytics is used in various industries, including:</p>
        <ul>
          <li><b>Finance & Banking:</b> Fraud detection, investment analysis, and risk assessment.</li>
          <li><b>E-commerce & Retail:</b> Customer behavior analysis and demand forecasting.</li>
          <li><b>Healthcare:</b> Predictive diagnostics and personalized treatment plans.</li>
          <li><b>Marketing & Advertising:</b> Measuring campaign effectiveness and consumer segmentation.</li>
          <li><b>Technology & AI:</b> Supporting machine learning and automation strategies.</li>
        </ul>
        <h3>Conclusion</h3>
        <p>Data analytics is transforming industries by enabling data-driven decision-making. As businesses continue to rely on data for strategic growth, the demand for competent data analysts and data scientists is projected to expand. Whether optimizing business operations, enhancing customer experiences, or predicting future trends, data analytics remains a powerful tool for success.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default DataAnalyticsJob;
