import React, { useEffect, useState } from 'react'
import classes from "./styles/todo.module.css";
import RightSidebar from '../../../common/RightSidebar';
import { Helmet } from 'react-helmet';
import { FullWidthWiraaAds } from '../../../common/Ads/FullWidthWiraaAds';

const OnlineNotepad = () => {

    const [title, setTitle] = useState(() => {
        const storedList = JSON.parse(localStorage.getItem("notepad-title"));
        return storedList || "";
    });
    const [inputValue, setInputValue] = useState(() => {
        const storedList = JSON.parse(localStorage.getItem("notepad"));
        return storedList || "";
    });

    useEffect(() => {
        localStorage.setItem("notepad", JSON.stringify(inputValue));
        localStorage.setItem("notepad-title", JSON.stringify(title));
    }, [inputValue, title]);


  return (
    <div className={classes.page}>
      <Helmet>
                <title>Online Notepad - Wiraa tools</title>
                <meta name="description" content="Take notes online with ease using our Online Notepad. Write, save, and access your notes from anywhere—no downloads required. Stay organized effortlessly!" />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Online Notepad</h1>
        <p className={classes.subTitle}>Effortless Note-Taking: Write, Save & Access Anytime, Anywhere!</p>
      <div className={classes.innerCon}>
        <div className={classes.inputCon} style={{marginBottom : "3rem"}}>
            <p className={classes.inputplaceholder}>Note</p>
            <input value={title} onChange={(e) => setTitle(e.target.value)} className={classes.inputfield} placeholder='Untitled Note...' style={{marginBottom : "10px"}}/>
            <textarea rows={40} value={inputValue} onChange={(e) => setInputValue(e.target.value)} className={classes.inputfield} placeholder='Enter Note...'/>
        </div>
      </div>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default OnlineNotepad;
