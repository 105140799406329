import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const DermatologistJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Dermatologist Jobs</title>
                <meta name="description" content="Explore top dermatologist job opportunities in skincare, medical aesthetics, and research. Start your career today!" />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Dermatologist Job Finder</h1>
        <p className={classes.subTitle}>Find Dermatology Jobs in Clinics, Hospitals & Research Centers</p>
      <JobFinder jobTitle={"Dermatologist"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default DermatologistJob;
