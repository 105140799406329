import React, { useState } from 'react'
import classes from "./styles/HourlytoSalary.module.css";
import RightSidebar from '../../../common/RightSidebar';
import { Helmet } from 'react-helmet';
import { FullWidthWiraaAds } from '../../../common/Ads/FullWidthWiraaAds';

const WorkHourCalculator = () => {
    const [inputValue, setInputValue] = useState();
    const [addInput, setAddInput] = useState({"hour" : ""})
    const [outputValue, setOutputValue] = useState();
    const [errorMessage, setErrorMessage] = useState();


    const handleHourlyCalculation = () => {
      if (!addInput.hour || !inputValue) {
          setErrorMessage("Please enter valid start time and end time");
          return;
      }
  
      // Convert time strings to minutes
      const timeToMinutes = (time) => {
          const [hours, minutes] = time.split(":").map(Number);
          return hours * 60 + minutes;
      };
  
      let startTime = timeToMinutes(inputValue);
      let endTime = timeToMinutes(addInput.hour);
  
      if (endTime < startTime) {
          setErrorMessage("End time must be after start time");
          return;
      }
  
      let totalMinutes = endTime - startTime;
      let hours = Math.floor(totalMinutes / 60);
      let minutes = totalMinutes % 60;
  
      setOutputValue(`${hours} hours ${minutes} minutes`);
      setErrorMessage("");
  };
    
      const handleClearBtn = () => {
        setInputValue("");
        setAddInput({ hour: "" })
        setOutputValue("");
        setErrorMessage("");
      }  

  return (
    <div className={classes.page}>
      <Helmet>
                <title>Work Hour Calculator - Wiraa tools</title>
                <meta name="description" content="Use our Work Hour Calculator to quickly compute your total work hours, breaks, and earnings. Simple, accurate, and efficient time tracking for employees and freelancers." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Work Hour Calculator</h1>
        <p className={classes.subTitle}>Easily Calculate Your Total Work Hours and Earnings</p>
      <div className={classes.innerCon}>
        <div className={classes.inputCon}>
            <p className={classes.inputplaceholder}>Start Time</p>
            <input value={inputValue} type='time' onChange={(e) => setInputValue(e.target.value)} className={classes.inputfield} placeholder='Enter starting time'/>
            {errorMessage && <p className={classes.errorMsg}>{errorMessage}</p>}
        </div>
        <div className={classes.additionalInputCon}>
            <div className={classes.additionalInputDiv}>
                <p className={classes.addplaceholder}>End Time</p>
                <input value={addInput.hour} type='time' onChange={(e) => setAddInput({ hour: e.target.value })} className={classes.addInputfield} placeholder='Enter ending time'/>
            </div>
        </div>
        <div className={classes.btnCon}>
            <button className={classes.convertBtn} onClick={handleHourlyCalculation}>Calculate</button>
            <button className={classes.clearBtn} onClick={handleClearBtn}>Clear</button>
        </div>
        <div className={classes.outputCon}>
            <p className={classes.outputplaceholder}>Hourly Salary</p>
            <p className={classes.outputfield}>{outputValue}</p>
        </div>
      </div>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>Work Hours Calculator</h3>
        <p>A tool used to determine the total number of hours worked during a given period is called a work hours calculator. To give a precise estimate of the number of hours worked, it can take into account variables like start and end times, breaks, overtime, and shifts.</p>
        <p>This tool is especially helpful for employees, freelancers, and employers to:</p>
        <ul>
          <li>Track daily, weekly, or monthly work hours.</li>
          <li>Calculate overtime and extra earnings.</li>
          <li>Simplify payroll processing.</li>
        </ul>
        <h3>Benefits of Work Hours Calculator</h3>
        <ol>
          <li><b>Accurate Time Tracking:</b> A work hours calculator provides precise calculations of total hours worked, including regular and overtime hours, reducing errors in timekeeping.</li>
          <li><b>Payroll Simplification:</b> It streamlines payroll management by providing exact data needed to calculate wages, saving time for employers and ensuring employees are paid fairly.</li>
          <li><b>Boosts Productivity:</b> By tracking time efficiently, employees can monitor their work habits and optimize productivity, while employers can better allocate resources.</li>
          <li><b>Improves Work-Life Balance:</b> Employees can use it to assess the time spent on work and identify opportunities to balance professional and personal lives.</li>
          <li><b>Transparency and Accountability:</b> Both employers and employees benefit from clear and reliable records of hours worked, reducing disputes over time and pay.</li>
        </ol>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default WorkHourCalculator;
