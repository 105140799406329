import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const BankingJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Banking Jobs</title>
                <meta name="description" content="Find the best banking jobs in the US and India. Connect with leading financial institutions and advance your career with Banking Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Banking Job Finder</h1>
        <p className={classes.subTitle}>Discover Top Banking Jobs to Shape Financial Futures and Drive Economic Growth</p>
      <JobFinder jobTitle={"Banking"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is Banking?</h3>
        <p>The banking sector is fundamental to the financial system, providing important services like deposit handling, lending, and investment options. Banks facilitate economic development by enabling effective capital flow, providing financial safety, and assisting both businesses and individuals with their financial needs.</p>
        <h3>Types of Banks</h3>
        <p>The banking sector is diverse, catering to different financial needs:</p>
        <ul>
          <li><b>Commercial Banks:</b> Offer savings, loans, and other financial services to individuals and businesses.</li>
          <li><b>Investment Banks:</b> Focus on capital markets, mergers, and acquisitions.</li>
          <li><b>Central Banks:</b> Regulate the money supply and oversee monetary policies (e.g., RBI in India, Federal Reserve in the US).</li>
          <li><b>Cooperative Banks:</b> Serve small businesses and farmers with community-focused banking.</li>
          <li><b>Digital Banks:</b> Provide online-only banking services with minimal physical branches.</li>
        </ul>
        <h3>Key Features of Banking</h3>
        <p>Banking offers a wide range of services that impact daily financial transactions:</p>
        <ul>
          <li><b>Deposits & Savings Accounts:</b> Secure storage of money with interest benefits.</li>
          <li><b>Loans & Credit:</b> Personal, business, and mortgage loans to support financial advancement.</li>
          <li><b>Payment Solutions:</b> Services like online banking, UPI, credit and debit cards, and wire transfers.</li>
          <li><b>Investment & Wealth Management:</b> Options include mutual funds, fixed deposits, and stock investments.</li>
          <li><b>Risk Management & Insurance:</b> Financial safeguards against unpredictable events.</li>
        </ul>
        <h3>Average Salary in the Banking Sector</h3>
        <p>Compensation in the banking sector differs according to role and experience:</p>
        <ul>
          <li><b>India:</b> ₹3-20 LPA, with higher salaries available in private and investment banks.</li>
          <li><b>US:</b> $50,000-$150,000 per year, where top investment banking positions can exceed $200,000.</li>
        </ul>
        <h3>Career Opportunities in Banking</h3>
        <p>Careers in banking provide stability, advancement, and varied opportunities, including:</p>
        <ul>
          <li>Retail & Corporate Banking</li>
          <li>Investment & Wealth Management</li>
          <li>Risk & Compliance Management</li>
          <li>Fintech & Digital Banking</li>
          <li>Financial Analysis & Advisory</li>
        </ul>
        <h3>Conclusion</h3>
        <p>Banking is a vital industry that promotes economic stability and financial safety. With the rise of digital banking and fintech innovations, the sector is continuously changing, presenting numerous career opportunities for individuals in search of a vibrant and rewarding career path.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default BankingJob;
