import React, { useEffect, useState } from 'react'
import classes from "./styles/todo.module.css";
import RightSidebar from '../../../common/RightSidebar';
import { Helmet } from 'react-helmet';
import { FullWidthWiraaAds } from '../../../common/Ads/FullWidthWiraaAds';

const ToDoList = () => {
    const [inputValue, setInputValue] = useState("");
    const [list, setList] = useState(() => {
        const storedList = JSON.parse(localStorage.getItem("todoList"));
        return storedList || [];
    });
    const [errorMessage, setErrorMessage] = useState();

    useEffect(() => {
        localStorage.setItem("todoList", JSON.stringify(list));
    }, [list]);

    const handleAdd = () => {
      if (inputValue === "") {
          setErrorMessage('Please enter something.');
          return;
      }
      setList([ {text : inputValue, checked : false}, ...list]);
      setInputValue("");
      setErrorMessage("");
  };

  const handleCheck = (index) => {
    setList(list.map((item, i) => i === index ? { ...item, checked: !item.checked } : item));
};

const handleDelete = (index) => {
    setList(list.filter((_, i) => i !== index));
};
    
      const handleClearBtn = () => {
        setInputValue("");
        setErrorMessage("");
        setList([]);
      }  

  return (
    <div className={classes.page}>
      <Helmet>
                <title>To-Do List - Wiraa tools</title>
                <meta name="description" content="Manage tasks effortlessly with our intuitive To-Do List. Organize, prioritize, and track your daily goals for increased productivity and efficiency." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>To-Do List</h1>
        <p className={classes.subTitle}>Stay Organized & Boost Productivity with an Efficient To-Do List</p>
      <div className={classes.innerCon}>
        <div className={classes.inputCon}>
            <p className={classes.inputplaceholder}>Task</p>
            <textarea rows={4} value={inputValue} onChange={(e) => setInputValue(e.target.value)} className={classes.inputfield} placeholder='Write your task here...'/>
            {errorMessage && <p className={classes.errorMsg}>{errorMessage}</p>}
        </div>
        <div className={classes.btnCon}>
            <button className={classes.convertBtn} onClick={handleAdd}>ADD</button>
            <button className={classes.clearBtn} onClick={handleClearBtn}>Clear All</button>
        </div>
        <div className={classes.outputCon}>
            {list.map((item, i) => {
                return <div className={item.checked ? classes.ActivetodoDiv : classes.todoDiv}>
                    <p>{item.text}</p>
                    <div className={classes.imgCon}>
                        <img className={classes.checkImg} src="https://cdn-icons-png.flaticon.com/128/5291/5291043.png" alt="tick" onClick={() => handleCheck(i)}/>
                        <img className={classes.deleteImg} src="https://cdn-icons-png.flaticon.com/128/484/484662.png" alt="bin" onClick={() => handleDelete(i)}/>
                    </div>    
                </div>
            })}
        </div>
      </div>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default ToDoList;
