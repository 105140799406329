import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const CardiologyJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Cardiology Jobs</title>
                <meta name="description" content="Find top cardiology job openings for doctors, surgeons, and researchers. Start your career in heart care today!" />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Cardiology Job Finder</h1>
        <p className={classes.subTitle}>Explore Cardiology Job Opportunities in Healthcare & Research</p>
      <JobFinder jobTitle={"Cardiology"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default CardiologyJob;
