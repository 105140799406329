import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const FashionDesignerJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Fashion Designer Jobs</title>
                <meta name="description" content="Find the best fashion designer jobs in the US and India. Connect with leading brands and advance your career with Fashion Designer Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Fashion Designer Job Finder</h1>
        <p className={classes.subTitle}>Discover Top Fashion Designer Jobs to Create Stylish and Trendsetting Collections</p>
      <JobFinder jobTitle={"Fashion Designer"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is Fashion Designing</h3>
        <p>Fashion designing is an ever-evolving and imaginative field that blends artistic creativity with technical expertise to develop garments, accessories, and footwear. Fashion designers have a significant impact on worldwide trends and play an essential role in defining how individuals express themselves through their style choices.</p>
        <h3>What Do Fashion Designers Do?</h3>
        <p>Fashion designers are tasked with generating and crafting innovative designs influenced by trends, cultural influences, and consumer preferences. Their responsibilities include:</p>
        <ul>
          <li><b>Creating Sketches:</b> Drawing and illustrating new ideas for clothing and accessories.</li>
          <li><b>Choosing Fabrics & Materials:</b> Selecting textures, colors, and materials to realize their designs.</li>
          <li><b>Crafting Patterns & Sewing:</b> Creating patterns and collaborating with tailors or manufacturers to produce the final products.</li>
          <li><b>Conducting Trend Research:</b> Analyzing global fashion trends to maintain a competitive edge in the industry.</li>
          <li><b>Organizing Fashion Shows & Branding:</b> Presenting collections during fashion shows and overseeing brand identity.</li>
        </ul>

        <h3>Salary & Job Opportunities</h3>
        <p>Fashion design salaries vary based on experience, brand reputation, and location:</p>
        <ul>
          <li><b>In India:</b> Entry-level designers earn around ₹3–6 LPA, while experienced professionals in luxury brands or independent labels can make ₹10–25 LPA.</li>
          <li><b>In the US:</b> Salaries range from $50,000 to $100,000 annually, with top designers in major fashion houses earning significantly more.</li>
        </ul>

        <h3>Skills Required for Fashion Designing</h3>
        <p>To succeed as a fashion designer, one must have:</p>
        <ul>
          <li>Creativity and artistic ability</li>
          <li>Strong knowledge of textiles and fabrics</li>
          <li>Sewing and pattern-making skills</li>
          <li>Understanding of fashion history and global trends</li>
          <li>Business and marketing knowledge for brand growth</li>
        </ul>

        <h3>The Future of Fashion Designing</h3>
        <p>The fashion industry is evolving with sustainability and technology. Eco-friendly fabrics, AI-assisted design tools, and 3D printing are shaping the future of fashion. With the rise of e-commerce and social media, independent designers also have opportunities to showcase and sell their creations globally.</p>

        <h3>Conclusion</h3>
        <p>Fashion designing is an exciting career that combines creativity with business acumen. Whether working for a luxury brand, starting an independent label, or designing for mass-market retail, fashion designers play a crucial role in influencing style and culture worldwide.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default FashionDesignerJob;
