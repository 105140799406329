import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const LegalJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Legal Jobs</title>
                <meta name="description" content="Explore the best legal job opportunities in the US and India. Connect with law firms and legal departments with Legal Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Legal Job Finder</h1>
        <p className={classes.subTitle}>Find Top Legal Jobs in Law Firms, Corporations, and Government Sectors</p>
      <JobFinder jobTitle={"Legal"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>Introduction</h3>
        <p>The legal profession serves as a crucial foundation of society, ensuring justice, enforcing laws, and safeguarding rights. Legal practitioners, such as attorneys, judges, legal consultants, and paralegals, have a vital role in understanding and implementing the law. As the legal framework becomes increasingly complex in areas like business, technology, and human rights, the need for legal knowledge is on the rise globally.  </p>
        <h3>Roles and Responsibilities</h3>
        <p>The legal sector involves various functions depending on the position:</p>
        <ul>
          <li><b>Lawyers/Attorneys:</b> Represent clients in court, offer legal counsel, and create legal documents.</li>
          <li><b>Judges:</b> Clarify laws, manage legal proceedings, and make rulings. </li>
          <li><b>Legal Advisors:</b> Guide businesses and individuals on legal issues to confirm adherence to regulations.</li>
          <li><b>Paralegals:</b> Support attorneys by performing research, organizing case files, and drafting paperwork.</li>
        </ul>
        <h3>Types of Legal Professionals</h3>
        <p>Legal careers are diverse, with several specializations:</p>
        <ul>
          <li><b>Corporate Lawyer:</b> Deals with business laws, mergers, and contracts.</li>
          <li><b>Criminal Lawyer:</b> Defends or prosecutes people who have been charged with crimes.</li>
          <li><b>Intellectual Property Lawyer:</b> Safeguards copyrights, patents, and trademarks.</li>
          <li><b>Family Lawyer:</b> Handles divorce, child custody, and family-related cases.</li>
          <li><b>Human Rights Lawyer:</b> Advocates for fundamental rights and justice.</li>
          <li><b>Immigration Lawyer:</b> Assists individuals with visas, citizenship, and legal migration issues.</li>
        </ul>
        <h3>Salary Insights</h3>
        <p>Earnings in the legal field depend on experience, specialization, and location:</p>
        <ul>
          <li><b>United States:</b> Lawyers earn between $60,000 to $200,000 per year, with top-tier attorneys making significantly more.</li>
          <li><b>India:</b> Salaries range from ₹4 LPA to ₹25 LPA, depending on the firm's reputation and expertise.</li>
        </ul>
        <h3>Scope and Future of Legal Careers</h3>
        <ul>
          <li><b>Growing Demand:</b> Legal consultants are becoming more and more necessary for startups, technology companies, and businesses.</li>
          <li><b>Legal Tech:</b> Virtual law firms and AI-powered legal research are revolutionizing the field.</li>
          <li><b>Global Opportunities:</b> International law allows legal professionals to work across borders.</li>
        </ul>
        <h3>Conclusion</h3>
        <p>A career in law provides financial stability, intellectual challenge, and a reputation. Legal professionals have a big influence on society, whether they are advising businesses, fighting for justice, or influencing public policy. The legal profession remains a highly regarded and fulfilling professional path due to changing legal frameworks and global prospects.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default LegalJob;
