import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const ArchitectJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Architect Jobs</title>
                <meta name="description" content="Find the best architect jobs in the US and India. Connect with top firms and advance your career with Architect Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Architect Job Finder</h1>
        <p className={classes.subTitle}>Discover Leading Architect Jobs to Design and Build Innovative Structures</p>
      <JobFinder jobTitle={"Architect"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is an Architect?</h3>
        <p>An architect is a specialist who plans and designs structures to ensure their structural soundness, aesthetic beauty, and functionality. Residential buildings, commercial buildings, skyscrapers, and urban developments are just a few of the projects that architects work on. They combine technical expertise with creativity to bring architectural ideals to reality while complying with safety and environmental rules.</p>
        <h3>Key Features of Architecture</h3>
        <ul>
          <li><b>Design & Planning:</b> Creating blueprints and layouts for buildings.</li>
          <li><b>Structural Integrity:</b> Ensuring buildings are safe and durable.</li>
          <li><b>Sustainability:</b> Incorporating eco-friendly and energy-efficient designs.</li>
          <li><b>Technology Integration:</b> Using software like AutoCAD, Revit, and 3D modeling.</li>
          <li><b>Regulatory Compliance:</b> Adhering to local and international building codes.</li>
        </ul>

        <h3>Types of Architects</h3>
        <p>Architecture is a broad field with several specializations:</p>

        <ul>
          <li><b>Residential Architect:</b> Designs homes, apartments, and housing complexes.</li>
          <li><b>Commercial Architect:</b> Focuses on office buildings, shopping malls, and hotels.</li>
          <li><b>Landscape Architect:</b> Plans outdoor spaces like parks and gardens.</li>
          <li><b>Urban Planner:</b> Designs city layouts, infrastructure, and public spaces.</li>
          <li><b>Interior Architect:</b> Works on interior spaces with an emphasis on aesthetics and functionality.</li>
          <li><b>Industrial Architect:</b> Designs factories, warehouses, and industrial plants.</li>
        </ul>

        <h3>Average Salary of an Architect</h3>
        <p>Architect salaries can change depending on experience, specialization, and location:</p>
        <ul>
          <li><b>India:</b> ₹4 LPA – ₹20 LPA, with senior architects earning more.</li>
          <li><b>US:</b> $60,000 – $120,000 per year, with top professionals earning beyond this range.</li>
        </ul>

        <h3>Scope of Architecture</h3>
        <p>The demand for sustainable structures and urbanization are growing demand for architects. Architecture is changing quickly with the developments in eco-friendly designs, smart cities, and 3D printing. The business is changing due to emerging trends like AI-driven planning, parametric design, and green building, which present new chances for creativity.</p>

        <h3>Conclusion</h3>
        <p>Art, engineering, and technology are all combined in the dynamic and imaginative field of architecture. Skilled architects will be in great demand as cities grow and sustainable development gains traction. For those who like creating environments that influence how others live, work, and interact, this is the perfect career choice.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default ArchitectJob;
