import React, { useState } from 'react'
import classes from "./styles/SimpleInterest.module.css";
import RightSidebar from '../../../common/RightSidebar';
import { Helmet } from 'react-helmet';
import { FullWidthWiraaAds } from '../../../common/Ads/FullWidthWiraaAds';

const CompoundInterestCalculator = () => {
    const [inputValue, setInputValue] = useState();
    const [interestDetails, setInterestDetails] = useState(null);
    const [addInput, setAddInput] = useState({"roi" : "12", "time" : "24"})
    const [errorMessage, setErrorMessage] = useState();


    const handleInterestCalculation = () => {
      if (!inputValue || isNaN(inputValue) || inputValue <= 0) {
        setErrorMessage("Please enter a valid principal amount.");
        return;
    }

    const principal = parseFloat(inputValue);
    const rate = parseFloat(addInput.roi);
    const timeInMonths = parseFloat(addInput.time);
    const timesCompounded = 12; // Assuming interest is compounded monthly

    if (isNaN(rate) || rate <= 0) {
        setErrorMessage("Please enter a valid rate of interest.");
        return;
    }

    if (isNaN(timeInMonths) || timeInMonths <= 0) {
        setErrorMessage("Please enter a valid time period.");
        return;
    }

    setErrorMessage(""); // Clear any previous error message

    const timeInYears = timeInMonths / 12;
    const ratePerPeriod = rate / (100 * timesCompounded);
    const totalAmount = principal * Math.pow(1 + ratePerPeriod, timesCompounded * timeInYears);
    const interest = totalAmount - principal;

    setInterestDetails({
        interest: interest.toFixed(2),
        total: totalAmount.toFixed(2)
    });
  };
  
    
      const handleClearBtn = () => {
        setInputValue("");
        setAddInput({"roi" : "12", "time" : "18"});
        setInterestDetails(null);
        setErrorMessage("");
      }  

  return (
    <div className={classes.page}>
      <Helmet>
                <title>Compound Interest Calculator - Wiraa tools</title>
                <meta name="description" content="Calculate compound interest easily with our free online Compound Interest Calculator. Find future investment value with different interest rates, time periods, and compounding frequencies." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Compound Interest Calculator</h1>
        <p className={classes.subTitle}>Accurately Calculate Compound Interest and Grow Your Investments Over Time!</p>
      <div className={classes.innerCon}>
        <div className={classes.inputCon}>
            <p className={classes.inputplaceholder}>Principal amount</p>
            <input value={inputValue} onChange={(e) => setInputValue(e.target.value)} className={classes.inputfield} placeholder='Enter principal amount'/>
            {errorMessage && <p className={classes.errorMsg}>{errorMessage}</p>}
        </div>
        <div className={classes.additionalInputCon}>
                    <div className={classes.additionalInputDiv}>
                        <p className={classes.addplaceholder}>Rate of interest (p.a.)</p>
                        <input value={addInput.roi} onChange={(e) => setAddInput({ ...addInput, roi: e.target.value })} className={classes.addInputfield} placeholder='Enter rate of interest'/>
                    </div>
                    <div className={classes.additionalInputDiv}>
                        <p className={classes.addplaceholder}>Time period (months)</p>
                        <input value={addInput.time} onChange={(e) => setAddInput({ ...addInput, time: e.target.value })} className={classes.addInputfield} placeholder='Enter time period'/>
                    </div>
                </div>
        <div className={classes.btnCon}>
            <button className={classes.convertBtn} onClick={handleInterestCalculation}>Calculate</button>
            <button className={classes.clearBtn} onClick={handleClearBtn}>Clear</button>
        </div>
        {interestDetails ? <div className={classes.outputSalaryCon}>
                    <div>
                      <p className={classes.outputplaceholder}>Total interest:</p>
                      <p className={classes.outputfield}>{interestDetails.interest}</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Total amount:</p>
                      <p className={classes.outputfield}>{interestDetails.total}</p>
                    </div>  
        </div> : <div className={classes.outputSalaryCon}>
        <div>
                      <p className={classes.outputplaceholder}>Total interest:</p>
                      <p className={classes.outputfield}>0</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Total amount:</p>
                      <p className={classes.outputfield}>0</p>
                    </div>
          </div>}
      </div>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is Compound Interest?</h3>
        <p>Interest that is computed on both the initial principal—the initial sum of money—and the interest accrued over time is known as compound interest. To put it another way, compound interest enables interest to "compound" over time, so you pay or earn interest on both the initial amount and any cumulative interest.</p>
        <br /><p>The formula for compound interest is:</p>
        <p>A=P(1+rn)/nt</p>
        <p>Where:</p>
        <ul>
          <li>A is the amount of money accumulated after interest (principal + interest).</li>
          <li>P is the principal amount (the initial sum of money).</li>
          <li>r is the annual interest rate (decimal form).</li>
          <li>n is the number of times interest is compounded per year.</li>
          <li>t is the number of years the money is invested or borrowed.</li>
        </ul>
        <p>Compound interest is widely used in savings accounts, investments, and loans because it allows money to grow faster compared to simple interest.</p>
        <h3>Benefits of a Compound Interest Calculator</h3>
        <ol>
          <li><b>Time-Saving:</b> Automatically calculates compound interest, saving users time and effort compared to manual calculations.</li>
          <li><b>Accurate Projections:</b> Provides precise predictions of how investments or loans will grow over time, factoring in the compounding effect.</li>
          <li><b>Financial Planning:</b> Helps investors and borrowers understand the long-term impact of interest on their principal, enabling better savings and debt management strategies.</li>
          <li><b>Comparison Tool:</b> Allows users to compare different interest rates, compounding frequencies, or investment durations to make informed financial decisions.</li>
          <li><b>Visualization of Growth:</b> Offers a clear view of how principal amounts will grow due to compounding, which can motivate saving and investing.</li>
        </ol>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default CompoundInterestCalculator;
