import React, { useState } from 'react'
import classes from "./styles/SimpleInterest.module.css";
import RightSidebar from '../../../common/RightSidebar';
import { Helmet } from 'react-helmet';
import { FullWidthWiraaAds } from '../../../common/Ads/FullWidthWiraaAds';

const SavingsCalculator = () => {
    const [inputValue, setInputValue] = useState();
    const [interestDetails, setInterestDetails] = useState(null);
    const [addInput, setAddInput] = useState({"contri" : "" ,"roi" : "4", "time" : "5"})
    const [errorMessage, setErrorMessage] = useState();


    const handleInterestCalculation = () => {
      const principal = parseFloat(inputValue) || 0;
      const monthlyContribution = parseFloat(addInput.contri) || 0;
      const annualRate = parseFloat(addInput.roi) || 0;
      const timeYears = parseFloat(addInput.time) || 0;

      if (principal < 0 || monthlyContribution < 0 || annualRate < 0 || timeYears <= 0) {
        setErrorMessage("Please enter valid positive values.");
        return;
      }

      setErrorMessage(""); // Clear previous errors if any

      const r = annualRate / 100 / 12; // Monthly interest rate
      const n = 12; // Compounded monthly
      const totalMonths = timeYears * n;

      // Compound Interest on Initial Deposit
      const compoundInterest = principal * Math.pow(1 + r, totalMonths);

      // Future Value of Monthly Contributions
      const futureValueOfContributions =
      monthlyContribution * ((Math.pow(1 + r, totalMonths) - 1) / r);

      // Total savings after time period
      const totalSavings = compoundInterest + futureValueOfContributions;
      const interestEarned = totalSavings - (principal + monthlyContribution * totalMonths);

      setInterestDetails({
        interest: interestEarned.toFixed(2),
        total: totalSavings.toFixed(2),
      });
  };
  
    
      const handleClearBtn = () => {
        setInputValue("");
        setAddInput({"contri" : "" ,"roi" : "4", "time" : "5"});
        setInterestDetails(null);
        setErrorMessage("");
      }  

  return (
    <div className={classes.page}>
      <Helmet>
                <title>Savings Calculator - Wiraa tools</title>
                <meta name="description" content="Use our Savings Calculator to estimate how your savings will grow over time with interest. Plan your financial future effortlessly!" />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Savings Calculator</h1>
        <p className={classes.subTitle}>Estimate Your Savings Growth Over Time with Ease</p>
      <div className={classes.innerCon}>
        <div className={classes.inputCon}>
            <p className={classes.inputplaceholder}>Initial deposit</p>
            <input value={inputValue} onChange={(e) => setInputValue(e.target.value)} className={classes.inputfield} placeholder='Enter principal amount'/>
            {errorMessage && <p className={classes.errorMsg}>{errorMessage}</p>}
        </div>
        <div className={classes.additionalInputCon}>
                    <div className={classes.additionalInputDiv}>
                        <p className={classes.addplaceholder}>Monthly contribution</p>
                        <input value={addInput.contri} onChange={(e) => setAddInput({ ...addInput, contri: e.target.value })} className={classes.addInputfield} placeholder='Enter monthly contribution'/>
                    </div>
                    <div className={classes.additionalInputDiv}>
                        <p className={classes.addplaceholder}>Interest Rate (p.a.)</p>
                        <input value={addInput.roi} onChange={(e) => setAddInput({ ...addInput, roi: e.target.value })} className={classes.addInputfield} placeholder='Enter rate of interest'/>
                    </div>
                    <div className={classes.additionalInputDiv}>
                        <p className={classes.addplaceholder}>Time period (yr)</p>
                        <input value={addInput.time} onChange={(e) => setAddInput({ ...addInput, time: e.target.value })} className={classes.addInputfield} placeholder='Enter time period'/>
                    </div>
                </div>
        <div className={classes.btnCon}>
            <button className={classes.convertBtn} onClick={handleInterestCalculation}>Calculate</button>
            <button className={classes.clearBtn} onClick={handleClearBtn}>Clear</button>
        </div>
        {interestDetails ? <div className={classes.outputSalaryCon}>
                    <div>
                      <p className={classes.outputplaceholder}>Interest Earned:</p>
                      <p className={classes.outputfield}>{interestDetails.interest}</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Total Savings:</p>
                      <p className={classes.outputfield}>{interestDetails.total}</p>
                    </div>  
        </div> : <div className={classes.outputSalaryCon}>
        <div>
                      <p className={classes.outputplaceholder}>Interest Earned:</p>
                      <p className={classes.outputfield}>0</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Total Savings:</p>
                      <p className={classes.outputfield}>0</p>
                    </div>
          </div>}
      </div>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is a Savings Calculator?</h3>
        <p>A savings calculator is a tool that helps people predict how much their savings will increase over time by taking into account variables including interest rates, the initial deposit, monthly payments, and the length of time they plan to save. It enables users to estimate how much money they will have saved in the future and how long it will take them to attain a particular savings target.</p>
        <h3>Key Features</h3>
        <ul>
          <li><b>Initial Deposit:</b> Enter the amount of money you start with.</li>
          <li><b>Monthly Contributions:</b> Set the amount you plan to deposit regularly.</li>
          <li><b>Interest Rate:</b> Input the expected annual interest rate or return rate on your savings.</li>
          <li><b>Time Period:</b> Determine the number of months or years you plan to save.</li>
        </ul>
        <h3>Benefits of Saving Calculator</h3>
        <p>Planning for future financial objectives like home ownership, retirement, or an emergency fund is made simpler with the help of a savings calculator, which gives consumers a clear picture of how their savings will grow over time. Illustrating the effects of changes in interest rates, contributions, or time, can also assist you in modifying your savings plan.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default SavingsCalculator;
