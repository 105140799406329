import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const CreativeJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Creative Jobs</title>
                <meta name="description" content="Find the best creative jobs in the US and India. Connect with leading companies and advance your career with Creative Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Creative Job Finder</h1>
        <p className={classes.subTitle}>Explore Top Creative Job Opportunities to Showcase Your Talent and Innovation</p>
      <JobFinder jobTitle={"Creative"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is Creativity?</h3>
        <p>Creativity is the ability to think creatively, come up with original concepts, and develop innovative solutions. It calls for creativity, originality, and problem-solving abilities—all of which are critical in a variety of industries, such as technology, writing, marketing, and design. Being creative is not just for artists; it is a useful ability in everyday life, business, and science.</p>

        <h3>Key Features of Creativity</h3>
        <ul>
          <li><b>Innovation:</b> Bringing new concepts and ideas to life.</li>
          <li><b>Problem-Solving:</b> Finding unique solutions to problems.</li>
          <li><b>Adaptability:</b> The capacity to think creatively and adapt to new situations.</li>
          <li><b>Expression:</b> Using a variety of media, such as literature, painting, or design, to convey ideas.</li>
          <li><b>Risk-Taking:</b> Trying out new approaches without fear of failure.</li>
        </ul>

        <h3>Types of Creative Fields</h3>
        <p>Creativity applies to many industries, including:</p>

        <ul>
          <li><b>Design:</b> Graphic design, UI/UX, interior design.</li>
          <li><b>Writing:</b> Content creation, storytelling, scriptwriting.</li>
          <li><b>Technology:</b> Software development, game design, AI innovations.</li>
          <li><b>Marketing:</b> Advertising, branding, and social media campaigns.</li>
          <li><b>Performing Arts:</b> Music, acting, dance, filmmaking.</li>
        </ul>

        <h3>Average Salary in Creative Fields</h3>
        <p>The income of creative professionals varies based on expertise and industry:</p>
        <ul>
          <li><b>India:</b> ₹5 LPA – ₹20 LPA, depending on experience and specialization.</li>
          <li><b>US:</b> $50,000 – $150,000 per year, with higher earnings in digital and tech-related creative roles.</li>
        </ul>
        <h3>Scope of Creativity</h3>
        <p>As companies depend on cutting-edge branding, user experiences, and content to captivate audiences, there is an increasing need for creative employees. AI, digital platforms, and developing technology have increased creative potential in the corporate, freelance, and entrepreneurial sectors.</p>
        <h3>Conclusion</h3>
        <p>Across all industries, creativity is a potent talent that drives innovation and problem-solving. Creative minds, whether in business, technology, or the arts, influence the future by adopting new viewpoints. The value of creativity keeps increasing as sectors change, making it a desirable ability in today's society.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default CreativeJob;
