import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const CounselingJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Counseling Jobs</title>
                <meta name="description" content="Find the best counseling jobs in the US and India. Connect with leading organizations and advance your career with Counseling Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Counseling Job Finder</h1>
        <p className={classes.subTitle}>Discover Top Counseling Jobs to Provide Support and Guidance for Mental Well-being</p>
      <JobFinder jobTitle={"Counseling"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is Counseling?</h3>
        <p>Counseling is a professional service that helps individuals navigate personal, emotional, and psychological challenges. It involves active listening, problem-solving, and therapeutic techniques to support mental well-being and personal development. Counselors work in various fields, including mental health, career guidance, and relationship counseling, helping individuals make informed decisions and lead fulfilling lives.</p>
        <h3>What Does a Counselor Do?</h3>
        <p>Counselors provide emotional and psychological support to individuals dealing with various issues. Their key responsibilities include:</p>
        <ul>
          <li><b>Active Listening & Guidance:</b> Understanding clients' concerns and providing meaningful advice.</li>
          <li><b>Emotional Support:</b> Helping individuals cope with stress, anxiety, and personal challenges.</li>
          <li><b>Career Counseling:</b> Assisting clients in choosing the right career path based on their skills and interests.</li>
          <li><b>Relationship Counseling:</b> Helping individuals and couples resolve conflicts and improve communication.</li>
          <li><b>Mental Health Support:</b> Using therapeutic techniques to promote emotional well-being.</li>
        </ul>
        <h3>Features of Effective Counseling</h3>
        <ul>
          <li><b>Empathy & Understanding:</b> Creating a safe space for clients to express their feelings.</li>
          <li><b>Confidentiality:</b> Maintaining trust by keeping client information private.</li>
          <li><b>Personalized Approach:</b> Tailoring guidance based on individual needs.</li>
          <li><b>Problem-Solving Skills:</b> Helping clients identify solutions and coping strategies.</li>
          <li><b>Continuous Learning:</b> Staying updated with new counseling techniques and psychology trends.</li>
        </ul>
        <h3>Salary Expectations in Counseling</h3>
        <p>Counselors earn varying salaries depending on their specialization and experience:</p>
        <ul>
          <li><b>India:</b> Entry-level salaries range from ₹3–6 LPA, while experienced counselors can earn ₹10–20 LPA.</li>
          <li><b>US:</b> Salaries typically range from $45,000 to $80,000, with specialized counselors earning over $100,000.</li>
        </ul>
        <h3>Scope of Counseling</h3>
        <p>Counseling services are essential in multiple fields, including:</p>
        <ul>
          <li><b>Mental Health Counseling:</b> Supporting individuals dealing with stress, depression, and trauma.</li>
          <li><b>Career Counseling:</b> Helping students and professionals choose the right career path.</li>
          <li><b>Family & Relationship Counseling:</b> Assisting with marital issues and family conflicts.</li>
          <li><b>Educational Counseling:</b> Guiding students in academics and skill development.</li>
          <li><b>Corporate Counseling:</b> Providing workplace mental health support and career growth strategies.</li>
        </ul>
        <h3>Conclusion</h3>
        <p>Counseling plays a vital role in enhancing emotional well-being, decision-making, and personal growth. By offering guidance, support, and practical solutions, counselors help individuals overcome challenges and lead healthier, more fulfilling lives. Whether in mental health, career guidance, or relationships, counseling remains a valuable profession dedicated to improving lives.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default CounselingJob;
