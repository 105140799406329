import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const HumanResourceJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Human Resource Jobs</title>
                <meta name="description" content="Find the best human resource job opportunities in the US and India. Connect with top companies and grow your HR career with Human Resource Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Human Resource Job Finder</h1>
        <p className={classes.subTitle}>Explore Top Human Resource Jobs to Recruit, Manage, and Develop Talent</p>
      <JobFinder jobTitle={"Human Resource"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>Introduction</h3>
        <p>Human Resource (HR) is the backbone of any organization, responsible for managing the workforce, ensuring employee well-being, and shaping company culture. HR professionals handle recruitment, training, payroll, compliance, and conflict resolution, making them vital for business success. In today’s evolving work environment, HR is also adopting technology, such as AI-driven recruitment and remote work management, to enhance efficiency.</p>
        <h3>Key Functions of HR</h3>
        <p>HR is responsible for multiple tasks, such as:</p>
        <ul>
          <li><b>Recruitment & Hiring:</b> Identifying, interviewing, and onboarding new employees.</li>
          <li><b>Employee Relations:</b> Resolving disputes at work and creating a supportive environment.</li>
          <li><b>Training & Development:</b> Employee skill enhancement for career advancement.</li>
          <li><b>Pay and Benefits:</b> Managing pay, incentives, and benefits.</li>
          <li><b>Legal and Compliance Issues:</b> Ensuring labor laws and workplace rules are followed.</li>
        </ul>
        <h3>Career Opportunities in HR</h3>
        <p>HR offers various roles based on expertise and industry:</p>
        <ul>
          <li><b>HR Manager:</b> Oversees the HR department and policies.</li>
          <li><b>Recruiter:</b> Specializes in hiring and talent acquisition.</li>
          <li><b>Training & Development Specialist:</b> Focuses on employee growth and upskilling.</li>
          <li><b>Payroll & Benefits Administrator:</b> Manages salaries, benefits, and incentives.</li>
          <li><b>HR Consultant:</b> Advises businesses on HR strategies and compliance.</li>
        </ul>
        <h3>Salary Insights</h3>
        <p>Salaries in HR depend on experience, location, and specialization:</p>
        <ul>
          <li><b>United States:</b> HR professionals earn between $50,000 to $120,000 per year, with HR managers making more.</li>
          <li><b>India:</b> Salaries range from ₹4 LPA to ₹25 LPA, with senior HR roles commanding higher pay.</li>
        </ul>
        <h3>Future of HR</h3>
        <p>The HR field is evolving with technological advancements:</p>
        <ul>
          <li><b>HR Tech & AI:</b> Automated recruitment processes and AI-driven HR analytics are improving efficiency.</li>
          <li><b>Remote Work Management:</b> HR is adapting to hybrid work models and virtual employee engagement.</li>
          <li><b>Diversity & Inclusion:</b> Companies are focusing more on workplace equality and employee well-being.</li>
        </ul>
        <h3>Conclusion</h3>
        <p>Human resources are crucial for building a productive and engaged workforce. As organizations grow and adapt to new trends, HR professionals will play a pivotal role in shaping company culture and driving business success. A career in HR offers stability, opportunities for leadership, and the ability to impact workplace dynamics positively.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default HumanResourceJob;
