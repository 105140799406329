import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const ThreeDJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>3D Jobs</title>
                <meta name="description" content="Find the best 3D jobs in the US and India. Connect with leading companies and advance your career with 3D Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>3D Job Finder</h1>
        <p className={classes.subTitle}>Explore Top 3D Job Opportunities to Bring Ideas to Life in Three Dimensions</p>
      <JobFinder jobTitle={"3D"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is a 3D Job?</h3>
        <p>A 3D job refers to any profession that involves three-dimensional design, modeling, and visualization. Professionals in this field work across industries like gaming, animation, architecture, manufacturing, and film production. They use specialized software to create 3D objects, characters, environments, and simulations.</p>

        <h3>Key Features of 3D Jobs</h3>
        <ul>
          <li><b>3D Modeling:</b> Creating digital representations of objects, characters, or environments.</li>
          <li><b>Animation & Rigging:</b> Adding movement to models for games, movies, or simulations.</li>
          <li><b>Rendering & Texturing:</b> Enhancing models with colors, lighting, and materials.</li>
          <li><b>Virtual & Augmented Reality:</b> Designing immersive experiences for various industries.</li>
          <li><b>3D Printing & Prototyping:</b> Converting digital models into real-world objects.</li>
        </ul>

        <h3>Types of 3D Jobs</h3>
        <p>3D jobs span various industries, including:</p>
        <ul>
          <li><b>3D Artist:</b> Designs detailed models for films, games, and virtual environments.</li>
          <li><b>3D Animator:</b> Brings characters and objects to life with motion.</li>
          <li><b>3D Modeler:</b> Focuses on creating high-quality 3D assets for different uses.</li>
          <li><b>3D Designer:</b> Works on product design, architecture, and industrial modeling.</li>
          <li><b>Game Developer:</b> Builds 3D environments and characters for video games.</li>
          <li><b>VFX Artist:</b> Creates visual effects for movies, advertisements, and TV shows.</li>
          <li><b>Architectural Visualizer:</b> Designs realistic building and interior models.</li>
        </ul>

        <h3>Average Salary of 3D Professionals</h3>
        <p>The salary of a 3D professional depends on their expertise, experience, and industry:</p>
        <ul>
          <li><b>India:</b> ₹3 LPA – ₹15 LPA, with experienced professionals earning more.</li>
          <li><b>US:</b> $45,000 – $120,000 per year, depending on specialization.</li>
        </ul>
        <h3>Scope of 3D Jobs</h3>
        <p>With advancements in gaming, virtual reality, and digital design, 3D professionals are in high demand. Industries like film production, real estate, automotive design, and education are adopting 3D technology, creating more job opportunities. The rise of AI-generated 3D assets and real-time rendering tools is also shaping the future of this field.</p>
        <h3>Conclusion</h3>
        <p>A career in 3D design is both creative and technically demanding. Whether working in entertainment, engineering, or product design, 3D professionals have endless opportunities to innovate and push the boundaries of digital creation. If you have a passion for visual storytelling and design, the 3D industry offers exciting career paths.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default ThreeDJob;
