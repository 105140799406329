import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const AnalyticsJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Analytics Jobs</title>
                <meta name="description" content="Find the best analytics jobs in the US and India. Connect with leading companies and advance your career with Analytics Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Analytics Job Finder</h1>
        <p className={classes.subTitle}>Discover Top Analytics Jobs to Transform Data into Actionable Insights and Drive Business Growth</p>
      <JobFinder jobTitle={"Analytics"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is Analytics?</h3>
        <p>The process of analyzing data to derive practical insights that help in decision-making is known as analytics. To find trends, patterns, and relationships, data must be gathered, processed, and analyzed. Analytics is essential in today's digital environment for businesses, healthcare, marketing, finance, and technology, helping them maximize their performance and strategy.</p>
        <h3>Types of Analytics</h3>
        <p>Analytics can be classified into four main categories:</p>
        <ol>
          <li><b>Descriptive Analytics:</b> Explains what happened in the past using historical data.</li>
          <li><b>Diagnostic Analytics:</b> Analyzes data to determine why something occurred.</li>
          <li><b>Predictive Analytics:</b> Uses statistical models and AI to predict future trends.</li>
          <li><b>Prescriptive Analytics:</b> Suggests actionable solutions based on predictive insights.</li>
        </ol>
        <h3>Skills Required in Analytics</h3>
        <p>Professionals working in analytics must possess:</p>
        <ul>
          <li><b>Data Analysis Skills:</b> Understanding and analyzing substantial datasets.</li>
          <li><b>Coding Proficiency:</b> Knowledge of Python, R, SQL, or Excel for data processing. </li>
          <li><b>Statistical and Mathematical Skills:</b> Skill in applying statistical methods to practical situations.</li>
          <li><b>Data Visualization:</b> Expertise in using tools such as Power BI, Tableau, and Google Data Studio.</li>
          <li><b>Analytical Thinking Skills:</b> Identifying problems and suggesting solutions based on data.</li>
        </ul>
        <h3>Salary Trends</h3>
        <p>Salaries in analytics vary based on experience, location, and industry:</p>
        <ul>
          <li><b>India:</b> ₹5-25 LPA, with growing demand in AI, finance, and digital marketing.</li>
          <li><b>US:</b> $60,000-$130,000 annually, with lucrative roles in data science and business intelligence.</li>
        </ul>
        <h3>Future Scope & Career Opportunities</h3>
        <p>The demand for analytics professionals is growing with advancements in big data, machine learning, and AI. Career paths include:</p>
        <ul>
          <li>Data Analyst</li>
          <li>Business Analyst</li>
          <li>Marketing Analyst</li>
          <li>Financial Analyst</li>
          <li>Risk Analyst</li>
          <li>AI & Machine Learning Analyst</li>
        </ul>
        <h3>Conclusion</h3>
        <p>Analytics is a game-changer in today’s data-driven world. It helps businesses make strategic decisions, improve efficiency, and gain a competitive edge. With the rise of AI and big data, a career in analytics offers vast opportunities, job security, and career growth for aspiring professionals.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default AnalyticsJob;
