import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const MicrosoftJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Microsoft Jobs</title>
                <meta name="description" content="Find the latest Microsoft job openings in the US and India. Join a leading tech company and advance your career with Microsoft Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Microsoft Job Finder</h1>
        <p className={classes.subTitle}>Explore Exciting Job Opportunities at Microsoft to Drive Innovation and Growth</p>
      <JobFinder jobTitle={"Microsoft"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>Introduction</h3>
        <p>Microsoft is one of the world’s largest and most influential technology companies, known for its software, hardware, and cloud services. Founded in 1975 by Bill Gates and Paul Allen, Microsoft has revolutionized the tech industry with products like Windows, Office Suite, Azure, and Xbox. The company plays a significant role in shaping digital transformation across industries, from personal computing to enterprise solutions.</p>
        <h3>Major Products and Services</h3>
        <p>Microsoft provides multiple products and services, including:</p>
        <ul>
          <li><b>Operating Systems:</b> Windows OS is the most widely used operating system.</li>
          <li><b>Productivity Software:</b> Microsoft 365 (Word, Excel, PowerPoint, Outlook).</li>
          <li><b>Cloud Computing:</b> Microsoft Azure is a leading cloud platform for businesses.</li>
          <li><b>Development Tools:</b> Visual Studio, .NET framework for software developers.</li>
          <li><b>Gaming:</b> Xbox consoles and Game Pass subscription services.</li>
          <li><b>Enterprise Solutions:</b> SharePoint, Microsoft Teams, and Dynamics 365.</li>
          <li><b>Artificial Intelligence & Machine Learning:</b> AI-powered tools like Copilot and Cognitive Services.</li>
        </ul>
        <h3>Career Opportunities at Microsoft</h3>
        <p>Microsoft offers diverse career paths across different domains, including:</p>
        <ul>
          <li><b>Software Development:</b> Engineers work on Windows, Azure, AI, and gaming innovations.</li>
          <li><b>Cloud Computing & AI:</b> Careers in cloud architecture, cybersecurity, and AI research.</li>
          <li><b>Data Science & Analytics:</b> Roles in big data, business intelligence, and machine learning.</li>
          <li><b>Marketing & Sales:</b> Managing brand strategies, product promotions, and enterprise sales.</li>
          <li><b>Customer Support & IT Services:</b> Handling troubleshooting, consulting, and customer experience.</li>
        </ul>
        <h3>Salary Insights</h3>
        <p>Microsoft is known for competitive salaries and employee benefits:</p>
        <ul>
          <li>
            <b>United States:</b>
            <ul>
              <li>Software Engineers: $120,000 - $180,000 per year</li>
              <li>Data Scientists: $130,000 - $200,000 per year</li>
              <li>Cloud Architects: $140,000 - $210,000 per year</li>
            </ul>
          </li>
          <li>
            <b>India:</b>
            <ul>
              <li>Software Engineers: ₹12 LPA - ₹30 LPA</li>
              <li>Data Scientists: ₹15 LPA - ₹35 LPA</li>
              <li>Cloud Architects: ₹18 LPA - ₹40 LPA</li>
            </ul>
          </li>
        </ul>
        <h3>Future Scope of Microsoft</h3>
        <ul>
          <li><b>AI & Automation:</b> With advancements in AI, Microsoft is integrating intelligent solutions into its ecosystem.</li>
          <li><b>Cloud Expansion:</b> Microsoft Azure continues to grow as a leading cloud service provider.</li>
          <li><b>Quantum Computing:</b> Research into quantum computing positions Microsoft as a pioneer in the field.</li>
          <li><b>Metaverse & Mixed Reality:</b> Investments in HoloLens and virtual environments hint at future innovations.</li>
        </ul>
        <h3>Conclusion</h3>
        <p>Microsoft remains a global leader in technology, offering innovative solutions across software, cloud computing, and AI. With its vast ecosystem and commitment to technological advancement, Microsoft continues to shape the digital world and provide promising career opportunities. Whether you’re a developer, data scientist, or IT professional, a career at Microsoft can be highly rewarding.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default MicrosoftJob;
