import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const YogaJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Yoga Jobs</title>
                <meta name="description" content="Explore top yoga job openings in fitness centers, wellness retreats, and private studios. Apply now for roles as a certified yoga instructor or trainer!" />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Yoga Job Finder</h1>
        <p className={classes.subTitle}>Find Yoga Instructor Job Opportunities – Start Your Career in Health and Wellness</p>
      <JobFinder jobTitle={"Yoga"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default YogaJob;
