import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const CivilEngineerJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Civil Engineer Jobs</title>
                <meta name="description" content="Find the best civil engineer jobs in the US and India. Connect with leading companies and advance your career with Civil Engineer Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Civil Engineer Job Finder</h1>
        <p className={classes.subTitle}>Discover Top Civil Engineer Jobs to Design, Build, and Improve Infrastructure Projects</p>
      <JobFinder jobTitle={"Civil Engineer"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is Civil Engineering?</h3>
        <p>Civil Engineering is one of the oldest and most essential engineering disciplines, focusing on designing, constructing, and maintaining infrastructure. Civil engineers are essential in creating anything from buildings and water supply systems to roads and bridges. Through sustainable engineering solutions, their work ensures that cities run smoothly, buildings are secure, and the environment is preserved.</p>
        <h3>Key Responsibilities of Civil Engineers</h3>
        <p>Civil engineers are involved in various aspects of construction and infrastructure development. Their responsibilities include:</p>
        <ul>
          <li><b>Structural Design & Analysis:</b> Buildings, bridges, and tunnels that can endure natural and man-made loads are designed.</li>
          <li><b>Construction Management:</b> It is the process of supervising building projects to make sure they adhere to safety, quality, and financial specifications.</li>
          <li><b>Transportation Engineering:</b> Creating effective transportation networks, such as roads, trains, and airports.</li>
          <li><b>Geotechnical Engineering:</b> Analyzing the properties of rock and soil to evaluate the stability and strength of a foundation.</li>
          <li><b>Environmental Engineering:</b> The design of waste management, pollution prevention, and water treatment systems.</li>
          <li><b>Urban & Infrastructure Planning:</b> Contributing to the development of smart cities and sustainable urban environments.</li>
        </ul>
        <h3>Salary Insights</h3>
        <p>Civil engineers' earnings depend on experience, industry, and location.</p>
        <ul>
          <li><b>In the United States:</b> The average salary ranges from $65,000 to $110,000 per year. Senior professionals or those specializing in high-demand areas can earn even more.</li>
          <li><b>In India:</b> The salary typically falls between ₹3,00,000 to ₹15,00,000 per year, with higher earnings for experienced engineers in metro cities and large infrastructure projects.</li>
        </ul>
        <h3>Future Scope & Career Opportunities</h3>
        <p>The demand for civil engineers is on the rise due to urban growth, an increasing population, and the necessity for sustainable infrastructure. Key sectors that will drive future job opportunities include:</p>
        <ul>
          <li><b>Smart Cities & Sustainable Development:</b> There is a growing investment in environmentally friendly buildings and smart infrastructure.</li>
          <li><b>High-Speed Rail & Transportation Initiatives:</b> There is a rising demand for advanced transportation systems. </li>
          <li><b>Disaster-Resistant Construction:</b> Developing infrastructure capable of enduring natural disasters. </li>
          <li><b>Renewable Energy Projects:</b> Civil engineers play a role in the development of wind farms, hydroelectric facilities, and sustainable energy initiatives.</li>
        </ul>
        <h3>Conclusion</h3>
        <p>Civil engineering is a fulfilling profession that directly impacts society by constructing modern infrastructure. With technological advancement and increased focus on sustainability, the demand for civil engineers will persist. This career path provides stability, innovation, and the chance to make a difference in the future of cities and communities globally.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default CivilEngineerJob;
