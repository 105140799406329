import React, { useState } from 'react'
import classes from "./styles/SimpleInterest.module.css";
import RightSidebar from '../../../common/RightSidebar';
import { Helmet } from 'react-helmet';
import { FullWidthWiraaAds } from '../../../common/Ads/FullWidthWiraaAds';

const UsIncomeTaxCalculator = () => {
    const [inputValue, setInputValue] = useState();
    const [taxDetails, setTaxDetails] = useState(null);
    const [addInput, setAddInput] = useState({"status" : "Single"})
    const [errorMessage, setErrorMessage] = useState();


    const handleTaxCalculation = () => {
      const income = parseFloat(inputValue);
  if (isNaN(income) || income < 0) {
    setErrorMessage("Please enter a valid income.");
    return;
  }

  const status = addInput.status;
  let tax = 0;
  let bracket = 0;

  const brackets = {
    Single: [
      { rate: 0.10, threshold: 11925 },
      { rate: 0.12, threshold: 48475 },
      { rate: 0.22, threshold: 103350 },
      { rate: 0.24, threshold: 197300 },
      { rate: 0.32, threshold: 250525 },
      { rate: 0.35, threshold: 626350 },
      { rate: 0.37, threshold: Infinity }
    ],
    Married_Jointly: [
      { rate: 0.10, threshold: 23850 },
      { rate: 0.12, threshold: 96950 },
      { rate: 0.22, threshold: 206700 },
      { rate: 0.24, threshold: 394600 },
      { rate: 0.32, threshold: 501050 },
      { rate: 0.35, threshold: 751600 },
      { rate: 0.37, threshold: Infinity }
    ],
    Married_Separately: [
      { rate: 0.10, threshold: 11925 },
      { rate: 0.12, threshold: 48475 },
      { rate: 0.22, threshold: 103350 },
      { rate: 0.24, threshold: 197300 },
      { rate: 0.32, threshold: 250525 },
      { rate: 0.35, threshold: 375800 },
      { rate: 0.37, threshold: Infinity }
    ],
    Household: [
      { rate: 0.10, threshold: 17000 },
      { rate: 0.12, threshold: 64850 },
      { rate: 0.22, threshold: 103350 },
      { rate: 0.24, threshold: 197300 },
      { rate: 0.32, threshold: 250525 },
      { rate: 0.35, threshold: 626350 },
      { rate: 0.37, threshold: Infinity }
    ]
  };

  const selectedBrackets = brackets[status];
  let previousThreshold = 0;

  for (const bracketInfo of selectedBrackets) {
    if (income > bracketInfo.threshold) {
      tax += (bracketInfo.threshold - previousThreshold) * bracketInfo.rate;
      previousThreshold = bracketInfo.threshold;
    } else {
      tax += (income - previousThreshold) * bracketInfo.rate;
      bracket = bracketInfo.rate * 100;
      break;
    }
  }

  setTaxDetails({
    bracket: `${bracket}%`,
    total: tax.toFixed(2)
  });
  setErrorMessage("");
  };
  
    
      const handleClearBtn = () => {
        setInputValue("");
        setAddInput({"status" : "Single"});
        setTaxDetails(null);
        setErrorMessage("");
      }  

  return (
    <div className={classes.page}>
      <Helmet>
                <title>US Income Tax Calculator - Wiraa tools</title>
                <meta name="description" content="Use our US Income Tax Calculator to estimate your federal tax liability based on your income, deductions, and filing status. Get accurate tax insights instantly!" />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>US Income Tax Calculator</h1>
        <p className={classes.subTitle}>Calculate Your Federal Income Tax Liability Quickly and Accurately with the 2025 Tax Brackets</p>
      <div className={classes.innerCon}>
        <div className={classes.inputCon}>
            <p className={classes.inputplaceholder}>Annual Income</p>
            <input value={inputValue} onChange={(e) => setInputValue(e.target.value)} className={classes.inputfield} placeholder='Enter Annual Income'/>
            {errorMessage && <p className={classes.errorMsg}>{errorMessage}</p>}
        </div>
        <div className={classes.additionalInputCon}>
                    <div className={classes.additionalInputDiv}>
                                    <p className={classes.inputplaceholder}>Filing status</p>
                                    <select className={classes.selectOption}
                                      onChange={(e) => setAddInput({ ...addInput, status: e.target.value })}>
                                      <option value="Single">Single</option>
                                      <option value="Married_Jointly">Married, Filing Jointly</option>
                                      <option value="Married_Separately">Married, Filing Separately</option>
                                      <option value="Household">Head of Household</option>
                                    </select>
                                </div>
                </div>
        <div className={classes.btnCon}>
            <button className={classes.convertBtn} onClick={handleTaxCalculation}>Calculate</button>
            <button className={classes.clearBtn} onClick={handleClearBtn}>Clear</button>
        </div>
        {taxDetails ? <div className={classes.outputSalaryCon}>
                    <div>
                      <p className={classes.outputplaceholder}>Tax Bracket:</p>
                      <p className={classes.outputfield}>${taxDetails.bracket}</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Total Tax:</p>
                      <p className={classes.outputfield}>${taxDetails.total}</p>
                    </div>  
        </div> : <div className={classes.outputSalaryCon}>
        <div>
                      <p className={classes.outputplaceholder}>Tax Bracket:</p>
                      <p className={classes.outputfield}>$0</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Total Tax:</p>
                      <p className={classes.outputfield}>$0</p>
                    </div>
          </div>}
      </div>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is a US Income Tax Calculator?</h3>
        <p>A US Income Tax Calculator is a tool that allows people to estimate their federal and state income taxes depending on their income, filing status, deductions, and credits. By entering information such as annual income, marital status, and dependents, the calculator estimates the amount of taxes payable or the potential return.</p>

        <h3>Key Features</h3>
        <ul>
          <li><b>Taxable Income Calculation:</b> Accounts for deductions and exemptions to determine taxable income.</li>
          <li><b>Tax Bracket Analysis:</b> Identifies which federal and state tax brackets apply to your earnings.</li>
          <li><b>Customizable Inputs:</b> Considers factors like pre-tax deductions, tax credits, and retirement contributions.</li>
        </ul>

        <h3>Benefits of a US Income Tax Calculator</h3>
        <ul>
          <li><b>Accurate Tax Estimation:</b> Provides a clear and precise estimate of federal and state income tax liabilities, helping individuals avoid surprises during tax season.</li>
          <li><b>Financial Planning:</b> Helps users plan their finances by estimating potential refunds or additional taxes owed, enabling better budgeting throughout the year.</li>
          <li><b>Saves Time:</b> Simplifies the complex process of calculating taxes, saving time compared to manual computations or extensive research on tax brackets.</li>
          <li><b>Maximizes Deductions and Credits:</b> By factoring in deductions and tax credits, the calculator helps users identify opportunities to reduce their taxable income.</li>
          <li><b>Scenario Comparison:</b> Allows individuals to explore how changes in income, filing status, or contributions (e.g., to retirement accounts) can impact their tax liability.</li>
        </ul>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default UsIncomeTaxCalculator;
