import React, { useState } from 'react'
import classes from "./styles/SimpleInterest.module.css";
import RightSidebar from '../../../common/RightSidebar';
import { Helmet } from 'react-helmet';
import { FullWidthWiraaAds } from '../../../common/Ads/FullWidthWiraaAds';

const SimpleInterestCalculator = () => {
    const [inputValue, setInputValue] = useState();
    const [interestDetails, setInterestDetails] = useState(null);
    const [addInput, setAddInput] = useState({"roi" : "12", "time" : "18"})
    const [errorMessage, setErrorMessage] = useState();


    const handleInterestCalculation = () => {
      if (!inputValue || isNaN(inputValue) || inputValue <= 0) {
          setErrorMessage("Please enter a valid principal amount.");
          return;
      }
  
      const principal = parseFloat(inputValue);
      const rate = parseFloat(addInput.roi);
      const timeInMonths = parseFloat(addInput.time);
  
      if (isNaN(rate) || rate <= 0) {
          setErrorMessage("Please enter a valid rate of interest.");
          return;
      }
  
      if (isNaN(timeInMonths) || timeInMonths <= 0) {
          setErrorMessage("Please enter a valid time period.");
          return;
      }
  
      setErrorMessage(""); // Clear any previous error message
  
      const timeInYears = timeInMonths / 12;
      const interest = (principal * rate * timeInYears) / 100;
      const totalAmount = principal + interest;
  
      setInterestDetails({
          interest: interest.toFixed(2),
          total: totalAmount.toFixed(2)
      });
  };
  
    
      const handleClearBtn = () => {
        setInputValue("");
        setAddInput({"roi" : "12", "time" : "18"});
        setInterestDetails(null);
        setErrorMessage("");
      }  

  return (
    <div className={classes.page}>
      <Helmet>
                <title>Simple Interest Calculator - Wiraa tools</title>
                <meta name="description" content="Use our free Simple Interest Calculator to quickly compute interest on your principal amount. Enter principal, rate, and time to get accurate results instantly!" />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Simple Interest Calculator</h1>
        <p className={classes.subTitle}>Effortlessly Calculate Simple Interest Online</p>
      <div className={classes.innerCon}>
        <div className={classes.inputCon}>
            <p className={classes.inputplaceholder}>Principal amount</p>
            <input value={inputValue} onChange={(e) => setInputValue(e.target.value)} className={classes.inputfield} placeholder='Enter principal amount'/>
            {errorMessage && <p className={classes.errorMsg}>{errorMessage}</p>}
        </div>
        <div className={classes.additionalInputCon}>
                    <div className={classes.additionalInputDiv}>
                        <p className={classes.addplaceholder}>Rate of interest (p.a.)</p>
                        <input value={addInput.roi} onChange={(e) => setAddInput({ ...addInput, roi: e.target.value })} className={classes.addInputfield} placeholder='Enter rate of interest'/>
                    </div>
                    <div className={classes.additionalInputDiv}>
                        <p className={classes.addplaceholder}>Time period (months)</p>
                        <input value={addInput.time} onChange={(e) => setAddInput({ ...addInput, time: e.target.value })} className={classes.addInputfield} placeholder='Enter time period'/>
                    </div>
                </div>
        <div className={classes.btnCon}>
            <button className={classes.convertBtn} onClick={handleInterestCalculation}>Calculate</button>
            <button className={classes.clearBtn} onClick={handleClearBtn}>Clear</button>
        </div>
        {interestDetails ? <div className={classes.outputSalaryCon}>
                    <div>
                      <p className={classes.outputplaceholder}>Total interest:</p>
                      <p className={classes.outputfield}>{interestDetails.interest}</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Total amount:</p>
                      <p className={classes.outputfield}>{interestDetails.total}</p>
                    </div>  
        </div> : <div className={classes.outputSalaryCon}>
        <div>
                      <p className={classes.outputplaceholder}>Total interest:</p>
                      <p className={classes.outputfield}>0</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Total amount:</p>
                      <p className={classes.outputfield}>0</p>
                    </div>
          </div>}
      </div>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is Simple Interest?</h3>
        <p>Interest is the expense you pay to borrow money or the recompense you receive for lending money. Interest may be paid on credit cards or auto loans, or interest may be earned on cash deposits made into interest-bearing accounts such as certificates of deposit (CDs) or savings accounts.</p>
        <p>Interest that is just computed on the original amount (the "principal") borrowed or deposited is known as simple interest. For the period of a loan, simple interest is often set as a fixed percentage. Simple interest only applies to this initial principal amount, regardless of how frequently it is computed. Stated differently, interest that has already been accrued will not impact future interest payments.</p>

        <h3>What is a Simple Interest Calculator?</h3>
        <p>One useful tool for calculating interest on loans or savings without compounding is a basic interest calculator. On a daily, monthly, or annual basis, you can compute the simple interest on the principal amount. You can enter the principal amount, yearly rate, and duration in days, months, or years in the formula box of the basic interest calculator. Interest on the loan or investment will be shown by the calculator.</p>

        <h3>How Do Simple Interest Calculators Work?</h3>
        <p>The simple interest calculator will show the accrued amount that includes both the principal and the interest. The simple interest calculator works on the mathematical formula:</p>
        <p>A = P (1+rt)</p>
        <ul>
          <li>P = Principal Amount</li>
          <li>R = Rate of interest</li>
          <li>t = Number of years</li>
          <li>A = Total accrued amount (Both principal and interest)</li>
        </ul>
        <p>Interest = A – P.</p>
        <p>Let’s understand the workings of the simple interest calculator with an example. The principal amount is Rs 10,000, the rate of interest is 10% and the number of years is six. You can calculate the simple interest as:</p>
        <p>A = 10,000 (1+0.1*6) = Rs 16,000.</p>
        <p>Interest = A – P = 16000 – 10000 = Rs 6,000.</p>

        <h3>Benefits of a Simple Interest Calculator</h3>
        <ol>
          <li><b>Easy Calculation:</b> Simplifies the process of calculating simple interest, saving time and eliminating manual errors.</li>
          <li><b>Accurate Estimates:</b> Helps people comprehend the expenses of loans or investments by providing accurate interest calculations based on principal, rate, and period.</li>
          <li><b>Financial Planning:</b> This shows how much interest will be collected or paid over a given time, which aids investors and borrowers in financial planning.</li>
          <li><b>Loan Comparison:</b> Allows users to compare simple interest rates across different loans or investments, enabling better decision-making.</li>
          <li><b>Transparent Insight:</b> This makes it easier to track how much interest accrues on a loan or investment, providing clear, straightforward information.</li>
        </ol>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default SimpleInterestCalculator;
