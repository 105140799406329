import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const CyberSecurityJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Cyber Security Jobs</title>
                <meta name="description" content="Find the best cyber security jobs in the US and India. Connect with leading companies and advance your career with Cyber Security Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Cyber Security Job Finder</h1>
        <p className={classes.subTitle}>Discover Top Cyber Security Jobs to Protect Digital Assets and Ensure Data Safety</p>
      <JobFinder jobTitle={"Cyber Security"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is Cyber Security?</h3>
        <p>Cyber security refers to the practice of protecting systems, networks, and data from cyber threats, including hacking, malware, phishing, and data breaches. As technology advances, businesses and individuals rely heavily on digital platforms, making cybersecurity a crucial field for safeguarding sensitive information.</p>

        <h3>Key Features of Cyber Security</h3>
        <ul>
          <li><b>Threat Protection:</b> Identifies and prevents cyber attacks such as malware, ransomware, and phishing attempts.</li>
          <li><b>Data Encryption:</b> Ensures secure communication and data storage to prevent unauthorized access.</li>
          <li><b>Network Security:</b> Protects internal networks from potential breaches and unauthorized users.</li>
          <li><b>Risk Management:</b> Evaluates and mitigates risks to prevent security vulnerabilities.</li>
          <li><b>Compliance and Regulations:</b> Ensures organizations follow security standards like GDPR, HIPAA, and ISO 27001.</li>
        </ul>

        <h3>Job Roles in Cyber Security</h3>
        <p>Cyber security offers various career opportunities, including:</p>
        <ul>
          <li><b>Cyber Security Analyst:</b> Monitors security incidents and implements protective measures.</li>
          <li><b>Ethical Hacker (Penetration Tester):</b> Identifies vulnerabilities in systems by simulating attacks.</li>
          <li><b>Security Architect:</b> Designs secure IT infrastructures to protect data and networks.</li>
          <li><b>Incident Responder:</b> Handles cyber incidents and minimizes damage from breaches.</li>
          <li><b>Compliance Officer:</b> Ensures companies follow security regulations and best practices.</li>
        </ul>

        <h3>Average Salary of Cyber Security Professionals</h3>
        <p>Cyber security salaries vary based on expertise and location:</p>
        <ul>
          <li><b>India:</b> ₹6 LPA – ₹20 LPA, with experienced professionals earning more.</li>
          <li><b>US:</b> $80,000 – $150,000 annually, depending on the role and experience.</li>
        </ul>
        <h3>Scope of Cyber Security</h3>
        <p>The demand for cyber security professionals is growing rapidly due to increasing cyber threats. Businesses across industries, including finance, healthcare, and e-commerce, require skilled security experts to safeguard their digital assets. With the rise of AI and cloud computing, cyber security will continue to evolve, offering long-term career stability.</p>
        <h3>Conclusion</h3>
        <p>Cyber security is a critical field that ensures the safety of digital information and infrastructure. As cyber threats become more sophisticated, skilled professionals in this domain will always be in demand. Whether you're a beginner or an experienced IT professional, cyber security offers a promising career path with great growth opportunities.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default CyberSecurityJob;
