import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const EducationJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Education Jobs</title>
                <meta name="description" content="Find the best education jobs in the US and India. Connect with top institutions and advance your career with Education Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Education Job Finder</h1>
        <p className={classes.subTitle}>Discover Top Education Jobs to Shape Minds and Inspire Future Generations</p>
      <JobFinder jobTitle={"Education"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is Education?</h3>
        <p>Education is the process of acquiring values, critical thinking skills, knowledge, and abilities through formal or informal learning. It is the foundation for both professional and personal development, empowering people to make significant contributions to society. Schools, colleges, and universities can all offer education, as can self-study and online resources.</p>

        <h3>Importance of Education</h3>
        <ul>
          <li>Enhances cognitive and intellectual abilities.</li>
          <li>Improves the ability to solve problems and make decisions.</li>
          <li>Creates job opportunities and economic growth.</li>
          <li>Promotes ethical values and social awareness.</li>
          <li>Encourages technical development and innovation.</li>
        </ul>

        <h3>Career Opportunities in Education</h3>
        <p>A variety of professional routes are available in the education sector, including:</p>
        <ul>
          <li><b>Professors and Teachers:</b> Educate students in colleges, universities, and schools.</li>
          <li><b>Educational Consultants:</b>  Guide curriculum and teaching strategies.</li>
          <li><b>Corporate Trainers:</b> Train staff members in companies and institutions.</li>
          <li><b>E-learning Experts:</b> Create digital educational resources and online courses</li>
          <li><b>School Administrators:</b> Manage institutions and academic programs.</li>
        </ul>

        <h3>Salary Insights</h3>
        <p>Earnings in the education field vary based on role, experience, and location:</p>
        <ul>
          <li><b>India:</b> ₹3–12 LPA (depending on experience and position)</li>
          <li><b>US:</b> $40,000–$90,000 per year (varies by role and institution)</li>
        </ul>

        <h3>The Future of Education</h3>
        <p>The rise of digital learning, AI-driven education, and skill-based training is transforming the education sector. Online learning platforms, virtual classrooms, and interactive teaching methods are shaping the future of learning.</p>

        <h3>Conclusion</h3>
        <p>Education is a continuous process that molds individuals as well as communities. Learning is always changing, whether it is done in traditional classrooms or online, making information more widely available.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default EducationJob;
