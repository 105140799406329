import React, { useState } from 'react'
import classes from "./styles/SimpleInterest.module.css";
import RightSidebar from '../../../common/RightSidebar';
import { Helmet } from 'react-helmet';
import { FullWidthWiraaAds } from '../../../common/Ads/FullWidthWiraaAds';

const VatCalculator = () => {
    const [inputValue, setInputValue] = useState();
    const [interestDetails, setInterestDetails] = useState(null);
    const [addInput, setAddInput] = useState({"gstr" : "18", "type" : "add"})
    const [errorMessage, setErrorMessage] = useState();


    const handleVatCalculation = () => {
      const amount = parseFloat(inputValue);
      const vatRate = parseFloat(addInput.gstr);
      const type = addInput.type;
  
      if (isNaN(amount) || amount <= 0) {
          setErrorMessage('Please enter a valid amount.');
          setInterestDetails(null);
          return;
      }
  
      if (isNaN(vatRate) || vatRate < 0) {
          setErrorMessage('Please enter a valid VAT rate.');
          setInterestDetails(null);
          return;
      }
  
      let vatAmount = 0;
      let totalAmount = 0;
  
      if (type === "add") {
          // Adding VAT
          vatAmount = (amount * vatRate) / 100;
          totalAmount = amount + vatAmount;
      } else if (type === "remove") {
          // Removing VAT (corrected formula)
          vatAmount = (amount * vatRate) / (100 + vatRate);
          totalAmount = amount - vatAmount;
      } else {
          setErrorMessage("Invalid VAT type selected.");
          return;
      }
  
      setInterestDetails({
          gst: vatAmount.toFixed(2),
          total: totalAmount.toFixed(2),
      });
  
      setErrorMessage('');
  };
    
      const handleClearBtn = () => {
        setInputValue("");
        setAddInput({"gstr" : "18", "type" : "add"});
        setInterestDetails(null);
        setErrorMessage("");
      }  

  return (
    <div className={classes.page}>
      <Helmet>
                <title>VAT Calculator - Wiraa tools</title>
                <meta name="description" content="Easily calculate Value Added Tax (VAT) with our accurate VAT Calculator. Compute VAT-inclusive and exclusive prices instantly for seamless tax calculations." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>VAT Calculator</h1>
        <p className={classes.subTitle}>Quick & Accurate VAT Calculator for Inclusive & Exclusive Tax Computation</p>
      <div className={classes.innerCon}>
        <div className={classes.inputCon}>
            <p className={classes.inputplaceholder}>Total Amount</p>
            <input value={inputValue} onChange={(e) => setInputValue(e.target.value)} className={classes.inputfield} placeholder='Enter amount'/>
            {errorMessage && <p className={classes.errorMsg}>{errorMessage}</p>}
        </div>
        <div className={classes.additionalInputCon}>
          <div className={classes.additionalInputDiv}>
                          <p className={classes.addplaceholder}>Type</p>
                          <div className={classes.radioInputCon}>
                          <input className={classes.radioInput} type="radio" id="add" name="type" value="add" onChange={(e) => setAddInput({ ...addInput, type: e.target.value })}/>
                              <label htmlFor="add" className={classes.radioInputlabel}>Add VAT</label>
                          <input className={classes.radioInput2} type="radio" id="remove" name="type" value="remove" onChange={(e) => setAddInput({ ...addInput, type: e.target.value })}/>
                              <label htmlFor="remove" className={classes.radioInputlabel}>Remove VAT</label>
                          </div>
                      </div>
                    <div className={classes.additionalInputDiv}>
                        <p className={classes.addplaceholder}>VAT Rate %</p>
                        <input value={addInput.gstr} onChange={(e) => setAddInput({ ...addInput, gstr: e.target.value })} className={classes.addInputfield} placeholder='Enter vat rate %'/>
                    </div>
                </div>
        <div className={classes.btnCon}>
            <button className={classes.convertBtn} onClick={handleVatCalculation}>Calculate</button>
            <button className={classes.clearBtn} onClick={handleClearBtn}>Clear</button>
        </div>
        {interestDetails ? <div className={classes.outputSalaryCon}>
                    <div>
                      <p className={classes.outputplaceholder}>Total VAT:</p>
                      <p className={classes.outputfield}>{interestDetails.gst}</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Total Amount:</p>
                      <p className={classes.outputfield}>{interestDetails.total}</p>
                    </div>  
        </div> : <div className={classes.outputSalaryCon}>
        <div>
                      <p className={classes.outputplaceholder}>Total VAT:</p>
                      <p className={classes.outputfield}>0</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Total Amount:</p>
                      <p className={classes.outputfield}>0</p>
                    </div>
          </div>}
      </div>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>VAT- Value Added Tax</h3>
        <p>VAT - or Value Added Tax - is charged by businesses at the point of sale of goods and services sold in the UK and the Isle of Man. It is a tax on business transactions. VAT (value-added tax) is a sort of indirect consumption tax levied on the value added to goods or services at various points of the supply chain, such as manufacture, wholesale, distribution, supply, or any other stage that adds value to the product. VAT is widely employed by governments around the world as a primary source of revenue, accounting for roughly 20% of total tax collection. It is the world's most common consumption tax, with enforcement in over 160 nations. All nations in the European Union (EU) are legally compelled to implement a minimum VAT rate, and European VAT rates have steadily climbed since their establishment in the twentieth century.</p>
        <h3>What is a VAT Calculator?</h3>
        <p>A VAT Calculator is a tool that calculates the value-added tax (VAT) on products and services. It allows users to calculate the VAT-inclusive price, VAT-exclusive pricing, or VAT amount for a particular transaction using the applicable VAT rate.</p>
        <h3>Key Features</h3>
        <ul>
          <li><b>VAT Inclusion/Exclusion:</b> Calculate prices with or without VAT.</li>
          <li><b>Customizable VAT Rates:</b> Accommodates varying VAT percentages as per local regulations.</li>
          <li><b>Quick and Accurate Results:</b> Ensures precision in VAT-related computations.</li>
        </ul>
        <h3>How to Calculate VAT with a VAT Calculator</h3>
        <ol>
          <li><b>Enter the Amount:</b> Provide the base price of the goods or services.</li>
          <li><b>Choose the VAT Rate:</b> Select the applicable VAT rate (e.g., 5%, 12%, 20%) based on the local tax regulations.</li>
          <li>
            <b>Select the Calculation Type:</b>
            <ul>
              <li><b>Add VAT:</b> To calculate the total price, including VAT.</li>
              <li><b>Remove VAT:</b> To find the original price, excluding VAT, from a VAT-inclusive amount.</li>
            </ul>
          </li>
          <li>
            <b>Get the Results: The calculator will display:</b>
            <ul>
              <li>The VAT amount.</li>
              <li>The total amount (if adding VAT) or base price (if removing VAT).</li>
            </ul>
          </li>
        </ol>
        <h3>Example:</h3>
        <ul>
          <li><b>Base Price:</b> $1,000</li>
          <li>
            <p><b>VAT Rate:</b> 20%</p>
            <ul>
              <li>VAT Amount: $200</li>
              <li>Total Price (with VAT): $1,200</li>
            </ul>
          </li>
        </ul>

        <h3>Benefits of Using a VAT Calculator</h3>
        <ol>
          <li><b>Accurate Calculations:</b> Ensures precise VAT computations, minimizing errors in tax-related calculations.</li>
          <li><b>Time-Saving:</b> Quickly determines VAT-inclusive or VAT-exclusive prices without needing manual calculations.</li>
          <li><b>Easy to Use:</b> User-friendly, making it accessible for individuals and businesses with little or no tax knowledge.</li>
          <li><b>Customizable Rates:</b> Supports various VAT rates based on the type of goods or services, ensuring versatility in usage.</li>
          <li><b>Helps with Compliance:</b> Assists businesses in maintaining accurate tax records and staying compliant with local tax regulations.</li>
        </ol>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default VatCalculator;
