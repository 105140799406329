import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const ResearcherJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Researcher Jobs</title>
                <meta name="description" content="Find the best researcher jobs in the US and India. Connect with leading organizations and advance your career with Researcher Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Researcher Job Finder</h1>
        <p className={classes.subTitle}>Explore Top Researcher Jobs to Drive Innovation and Expand Knowledge Frontiers</p>
      <JobFinder jobTitle={"Researcher"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>Who is the Researcher?</h3>
        <p>A researcher is a professional dedicated to investigating, evaluating, and advancing knowledge in various fields. In the fields of science, technology, health, and the social sciences, researchers are essential to expanding human knowledge and resolving pressing issues. To promote innovation and advancement, they carry out tests, analyze data, and publish their findings.</p>

        <h3>Fields of Research</h3>
        <p>Research is conducted in multiple fields, each with its contributions and challenges:</p>
        <ul>
          <li><b>Medicine & Healthcare:</b> Clinical trials, medication development, and disease prevention.</li>
          <li><b>Engineering & Technology:</b> Cybersecurity, artificial intelligence, and sustainable energy.</li>
          <li><b>Social Sciences:</b> Cultural research, economic trends, and behavioral studies.</li>
          <li><b>Market & Business Research:</b> Product development, industry forecasting, and consumer analysis.</li>
        </ul>

        <h3>Salary Insights</h3>
        <p>Earnings for researchers vary based on experience, specialization, and location:</p>
        <ul>
          <li><b>India:</b> ₹6–20 LPA (entry-level to experienced)</li>
          <li><b>US:</b> $50,000–$120,000 per year, depending on the field and funding availability</li>
        </ul>

        <h3>Key Responsibilities</h3>
        <ul>
          <li>Performing both qualitative and quantitative research.</li>
          <li>Using advanced tools for data collection and analysis.</li>
          <li>Publishing research findings in scholarly publications or business reports.</li>
          <li>Presenting findings in seminars and conferences.</li>
          <li>Securing funds via sponsorships and grants.</li>
        </ul>

        <h3>The Role of a Researcher</h3>
        <ul>
          <li>Creating and carrying out investigations or experiments.</li>
          <li>Data analysis and conclusion-making.</li>
          <li>Writing reports and research papers.</li>
          <li>Creating grant and funding applications.</li>
          <li>Collaborating with academic institutions, organizations, or businesses.</li>
        </ul>

        <h3>Future Scope of Research</h3>
        <p>Research careers are more important than ever because of technological developments and global issues like pandemics and climate change. Research in areas like biotechnology, AI, and sustainability is growing and presents exciting prospects for new researchers.</p>

        <h3>Conclusion</h3>
        <p>A research career is ideal for those with curiosity, analytical skills, and a passion for discovery. Whether in academia, healthcare, or industry, researchers contribute to shaping the future by uncovering new knowledge and solutions.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default ResearcherJob;
