import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const DataScientistJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Data Scientist Jobs</title>
                <meta name="description" content="Find the best data scientist jobs in the US and India. Connect with leading companies and advance your career with Data Scientist Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Data Scientist Job Finder</h1>
        <p className={classes.subTitle}>Explore Top Data Scientist Jobs to Uncover Insights and Drive Data-Driven Decisions</p>
      <JobFinder jobTitle={"Data Scientist"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is a Data Scientist?</h3>
        <p>A <b>Data Scientist</b> is a professional who analyzes complex data to extract valuable insights that help businesses make informed decisions. Using a mix of statistical analysis, machine learning, and data visualization, data scientists uncover patterns, trends, and correlations in large datasets. They work with structured and unstructured data to drive business strategies, optimize processes, and predict future trends.</p>
        <h3>Key Features of Data Science</h3>
        <ul>
          <li><b>Data Analysis:</b> Identifies patterns and trends in data to generate actionable insights.</li>
          <li><b>Machine Learning:</b> Uses algorithms to build predictive models and automate decision-making.</li>
          <li><b>Data Visualization:</b> Presents data insights using charts, graphs, and dashboards.</li>
          <li><b>Big Data Processing:</b> Handles large datasets using tools like Hadoop and Spark.</li>
          <li><b>Programming Skills:</b> Works with languages like Python, R, and SQL to manipulate data.</li>
        </ul>

        <h3>Job Roles in Data Science</h3>
        <p>The field of data science offers various career opportunities, including:</p>
        <ul>
          <li><b>Data Scientist:</b> Extracts insights from data and develops predictive models.</li>
          <li><b>Data Analyst:</b> Interprets data to provide business insights and improve decision-making.</li>
          <li><b>Machine Learning Engineer:</b> Designs and implements machine learning models.</li>
          <li><b>Business Intelligence Analyst:</b> Translates data into business strategies.</li>
          <li><b>Data Engineer:</b> Builds and maintains data pipelines and databases.</li>
        </ul>

        <h3>Average Salary of a Data Scientist</h3>
        <p>Salaries for data scientists vary depending on experience, industry, and location:</p>
        <ul>
          <li><b>India:</b> ₹8 LPA – ₹25 LPA, with senior roles earning even more.</li>
          <li><b>US:</b> $90,000 – $160,000 per year, based on expertise and company size.</li>
        </ul>

        <h3>Scope of Data Science</h3>
        <p>The demand for data scientists is increasing across industries such as finance, healthcare, e-commerce, and technology. Businesses rely on data-driven insights to improve operations and customer experience. With the rise of AI, IoT, and automation, the scope for data science will continue to grow, making it a future-proof career.</p>

        <h3>Conclusion</h3>
        <p>Data Science is a dynamic field that plays a crucial role in modern business strategies. As companies generate more data, the need for skilled data scientists will keep rising. Whether you're an aspiring data scientist or an experienced professional, this field offers immense career growth, competitive salaries, and opportunities to work with cutting-edge technologies.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default DataScientistJob;
