import React, { useState } from 'react'
import classes from "./styles/SimpleInterest.module.css";
import RightSidebar from '../../../common/RightSidebar';
import { Helmet } from 'react-helmet';
import { FullWidthWiraaAds } from '../../../common/Ads/FullWidthWiraaAds';

const MortgageCalculator = () => {
    const [inputValue, setInputValue] = useState();
    const [interestDetails, setInterestDetails] = useState(null);
    const [addInput, setAddInput] = useState({"dp" : "", "roi" : "12", "time" : ""})
    const [errorMessage, setErrorMessage] = useState();


    const handleCalculation = () => {
      const homePrice = parseFloat(inputValue);
      const downPayment = parseFloat(addInput.dp);
      const annualRate = parseFloat(addInput.roi);
      const loanTermYears = parseFloat(addInput.time);
  
      if (isNaN(homePrice) || isNaN(downPayment) || isNaN(annualRate) || isNaN(loanTermYears)) {
          setErrorMessage("Please enter valid numeric values.");
          return;
      }
  
      if (homePrice <= 0 || downPayment < 0 || annualRate <= 0 || loanTermYears <= 0) {
          setErrorMessage("Values must be greater than zero.");
          return;
      }
  
      if (downPayment >= homePrice) {
          setErrorMessage("Down payment cannot be greater than or equal to home price.");
          return;
      }
  
      setErrorMessage("");
  
      const principal = homePrice - downPayment;
      const monthlyRate = annualRate / 12 / 100;
      const loanTermMonths = loanTermYears * 12;
  
      let emi, totalInterest, totalAmount;
  
      if (monthlyRate === 0) {
          emi = principal / loanTermMonths;
      } else {
          emi = (principal * monthlyRate * Math.pow(1 + monthlyRate, loanTermMonths)) /
                (Math.pow(1 + monthlyRate, loanTermMonths) - 1);
      }
  
      totalAmount = emi * loanTermMonths;
      totalInterest = totalAmount - principal;
  
      setInterestDetails({
          emi: emi.toFixed(2),
          interest: totalInterest.toFixed(2),
          total: totalAmount.toFixed(2),
      });
  };
  
  
    
      const handleClearBtn = () => {
        setInputValue("");
        setAddInput({"dp" : "", "roi" : "12", "time" : ""});
        setInterestDetails(null);
        setErrorMessage("");
      }  

  return (
    <div className={classes.page}>
      <Helmet>
                <title>Mortgage Calculator - Wiraa tools</title>
                <meta name="description" content="Use our Mortgage Calculator to estimate your monthly loan payments, interest, and total cost. Plan your home financing with accurate and quick mortgage calculations." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Mortgage Calculator</h1>
        <p className={classes.subTitle}>Easily Calculate Your Monthly Mortgage Payments & Interest</p>
      <div className={classes.innerCon}>
        <div className={classes.inputCon}>
            <p className={classes.inputplaceholder}>Home Price</p>
            <input value={inputValue} onChange={(e) => setInputValue(e.target.value)} className={classes.inputfield} placeholder='Enter home price'/>
            {errorMessage && <p className={classes.errorMsg}>{errorMessage}</p>}
        </div>
        <div className={classes.additionalInputCon}>
                    <div className={classes.additionalInputDiv}>
                        <p className={classes.addplaceholder}>Down Payment</p>
                        <input value={addInput.dp} onChange={(e) => setAddInput({ ...addInput, dp: e.target.value })} className={classes.addInputfield} placeholder='Enter down payment amount'/>
                    </div>
                    <div className={classes.additionalInputDiv}>
                        <p className={classes.addplaceholder}>Rate of interest (p.a.)</p>
                        <input value={addInput.roi} onChange={(e) => setAddInput({ ...addInput, roi: e.target.value })} className={classes.addInputfield} placeholder='Enter rate of interest'/>
                    </div>
                    <div className={classes.additionalInputDiv}>
                        <p className={classes.addplaceholder}>Loan Term (yr)</p>
                        <input value={addInput.time} onChange={(e) => setAddInput({ ...addInput, time: e.target.value })} className={classes.addInputfield} placeholder='Enter time period'/>
                    </div>
                </div>
        <div className={classes.btnCon}>
            <button className={classes.convertBtn} onClick={handleCalculation}>Calculate</button>
            <button className={classes.clearBtn} onClick={handleClearBtn}>Clear</button>
        </div>
        {interestDetails ? <div className={classes.outputSalaryCon}>
                    <div>
                      <p className={classes.outputplaceholder}>Monthly EMI:</p>
                      <p className={classes.outputfield}>{interestDetails.emi}</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Total interest:</p>
                      <p className={classes.outputfield}>{interestDetails.interest}</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Total amount:</p>
                      <p className={classes.outputfield}>{interestDetails.total}</p>
                    </div>  
        </div> : <div className={classes.outputSalaryCon}>
                    <div>
                      <p className={classes.outputplaceholder}>Monthly EMI:</p>
                      <p className={classes.outputfield}>0</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Total interest:</p>
                      <p className={classes.outputfield}>0</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Total amount:</p>
                      <p className={classes.outputfield}>0</p>
                    </div>
          </div>}
      </div>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is a Mortgage Calculator?</h3>
        <p>Borrowed money to pay for real estate. In essence, the lender assists the buyer in paying the seller of a home, and the buyer promises to repay the borrowed funds over a set length of time, typically 15 or 30 years in the United States. Each month, the buyer makes a payment to the lender. The principle is a component of the monthly payment that represents the original amount borrowed. The remaining portion is the interest, which is the cost paid to the lender for using the funds. An escrow account may be used to cover the expense of property taxes and insurance. The buyer is not considered the full owner of the mortgaged property until the final monthly payment is made.</p>
        <h3>Brief History of Mortgages in the U.S.</h3>
        <p>In the early twentieth century, purchasing a property required a substantial down payment. Borrowers would be required to put down 50%, take out a three or five-year loan, and then make a balloon payment at the end of the period.</p>
        <p>Under such conditions, only four out of every ten Americans could buy a home. During the Great Depression, one-quarter of all homeowners lost their homes.</p>
        <p>To address this issue, the federal government established the Federal Housing Administration (FHA) and Fannie Mae in the 1930s to provide liquidity, stability, and affordability to the mortgage market. Both organizations contributed to the introduction of 30-year mortgages with lower down payments as well as standardized construction requirements.</p>
        <p>These initiatives also assisted returning soldiers in purchasing a home following the end of World War II, sparking a construction boom in the following decades. The FHA also assisted borrowers during difficult periods, such as the 1970s inflation crisis and the 1980s oil price decline.</p>
        <p>In 2001, the homeownership rate had reached a record high of 68.1%.</p>
        <p>Government intervention was also beneficial during the 2008 financial crisis. The crisis required a federal takeover of Fannie Mae, which lost billions of dollars due to huge defaults, but the company returned to profitability in 2012.</p>
        <p>A bigger share of mortgages are being backed by the Federal Reserve. This helped to stabilize the property market by 2013. Both companies continue to actively insure millions of single-family homes and other residential properties.</p>
      

      <h3>Benefits of Using a Mortgage Calculator</h3>
      <ol>
        <li><b>Accurate Payment Estimation:</b> Provides precise estimates of monthly mortgage payments, including principal, interest, taxes, and insurance.</li>
        <li><b>Helps with Budgeting:</b> Assists in planning by giving a clear picture of how much you can afford to borrow and how payments will fit into your budget.</li>
        <li><b>Time-Saving:</b> Quickly computes mortgage details without needing to manually calculate interest rates, loan terms, and other factors.</li>
        <li><b>Comparison Tool:</b> Allows you to compare different loan options (e.g., different interest rates, and loan terms) to find the best deal.</li>
        <li><b>Improves Decision-Making:</b> Provides insight into how loan terms affect long-term payments and overall costs, helping you make informed decisions.</li>
      </ol>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default MortgageCalculator;
