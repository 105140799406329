import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const ManufacturingEngineerJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Manufacturing Engineer Jobs</title>
                <meta name="description" content="Find the best manufacturing engineer jobs in the US and India. Connect with leading companies and advance your career with Manufacturing Engineer Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Manufacturing Engineer Job Finder</h1>
        <p className={classes.subTitle}>Explore Top Manufacturing Engineer Jobs to Optimize Production and Drive Innovation</p>
      <JobFinder jobTitle={"Manufacturing Engineer"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>Who is a Manufacturing Engineer?</h3>
        <p>A Manufacturing Engineer is a specialist who focuses on designing, developing, and improving manufacturing systems and processes. They work in industries such as automotive, aerospace, electronics, pharmaceuticals, and consumer goods, ensuring production runs smoothly, efficiently, and cost-effectively. Their role involves integrating new technologies, automating processes, and optimizing workflow to enhance product quality and reduce waste.</p>
        <h3>Key Responsibilities</h3>
        <ul>
          <li><b>Process Design & Optimization:</b> Develop and refine manufacturing processes for efficiency and productivity.</li>
          <li><b>Quality Control:</b> Ensure products meet industry and company standards through testing and inspection.</li>
          <li><b>Automation & Technology Integration:</b> Implement robotics, AI, and IoT to streamline production.</li>
          <li><b>Cost Reduction:</b> Identify ways to minimize material waste and production costs.</li>
          <li><b>Safety Compliance:</b> Ensure all processes adhere to safety and environmental regulations.</li>
          <li><b>Collaboration:</b> Work with design, production, and supply chain teams to enhance manufacturing capabilities.</li>
        </ul>
        <h3>Skills Required</h3>
        <ul>
          <li>Strong analytical and problem-solving abilities.</li>
          <li>Proficiency in CAD software and automation tools.</li>
          <li>Knowledge of lean manufacturing and Six Sigma principles.</li>
          <li>Excellent communication and teamwork skills.</li>
          <li>Understanding of materials science and production techniques.</li>
        </ul>
        <h3>Salary Insights</h3>
        <ul>
          <li><b>India:</b> ₹6-12 LPA, depending on experience and industry.</li>
          <li><b>US:</b> $70,000-$100,000 per year on average.</li>
        </ul>
        <h3>Career Scope</h3>
        <p>With the rise of Industry 4.0, manufacturing engineers are in high demand, particularly in automation, sustainable manufacturing, and smart factories. This career offers excellent growth opportunities in industrial innovation and production technology.</p>
        <h3>Conclusion</h3>
        <p>A career as a Manufacturing Engineer is ideal for individuals passionate about optimizing processes, integrating new technologies, and improving efficiency. It is a dynamic and evolving field that plays a critical role in industrial development and innovation.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default ManufacturingEngineerJob;
