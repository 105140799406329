import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const UiUxJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>UI/UX Jobs</title>
                <meta name="description" content="Find the best UI/UX jobs in the US and India. Connect with leading companies and advance your career with UI/UX Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>UI/UX Job Finder</h1>
        <p className={classes.subTitle}>Explore Top UI/UX Designer Jobs to Build Engaging and User-Friendly User Interfaces</p>
      <JobFinder jobTitle={"UI/UX"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is UI/UX Design?</h3>
        <p>UI/UX design refers to the process of creating digital products that provide a visually captivating interface (UI – User Interface) alongside a smooth, user-friendly experience (UX – User Experience). While UI is concerned with visual elements like colors, typography, and layouts, UX aims at facilitating easy usage, improving accessibility, and maximizing overall user satisfaction. Together, they collaboratively work to develop engaging and functional applications, websites, and software.</p>

        <h3>Key Features of UI/UX Design</h3>
        <ul>
          <li><b>User-Focused Approach:</b> Emphasizes the priorities of users and their behaviors.</li>
          <li><b>Wireframing & Prototyping:</b> Involves sketches and interactive models to assist in design planning.</li>
          <li><b>Visual Uniformity:</b> Guarantees a consistent appearance throughout the product.</li>
          <li><b>Usability & Accessibility:</b> Improves navigation and interaction efficiency.</li>
          <li><b>Collaboration with Development & Product Teams:</b> Connects design with functional execution.</li>
        </ul>

        <h3>Types of UI/UX Designers</h3>
        <p>UI/UX design can be divided into various specializations:</p>
        <ul>
          <li><b>Web UI/UX Designer:</b> Specialized in crafting websites that ensure a smooth user journey.</li>
          <li><b>Mobile UI/UX Designer:</b> Focused on developing user-friendly mobile application experiences.</li>
          <li><b>E-commerce UI/UX Designer:</b> Optimizes online shopping websites.</li>
          <li><b>Game UI/UX Designer:</b> Work on enhancing user experiences within gaming interfaces.</li>
          <li><b>Enterprise UI/UX Designer:</b> Designs complex software solutions for businesses.</li>
        </ul>

        <h3>Average Salary of a UI/UX Designer</h3>
        <p>The average salary of UI/UX designers is:</p>
        <ul>
          <li><b>India:</b> ₹6 LPA – ₹20 LPA, depending on expertise.</li>
          <li><b>US:</b> $75,000 – $140,000 per year, with senior designers earning more.</li>
        </ul>
        <h3>Scope of UI/UX Designing</h3>
        <p>With the increasing popularity of digital products, businesses are prioritizing UI/UX design to boost customer interaction and satisfaction. The need for UI/UX experts is rising in sectors such as fintech, healthcare, gaming, and e-commerce. New trends like AI-driven design, voice interfaces, and augmented reality (AR) are creating more opportunities in this area. </p>
        <h3>Conclusion</h3>
        <p>UI/UX design is a fast-changing field with a strong demand for talented professionals. As companies keep investing in digital experiences, UI/UX designers are essential in crafting intuitive and engaging products. This profession is perfect for creative individuals who enjoy solving problems and improving user satisfaction.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default UiUxJob;
