import React, { useEffect, useState } from 'react'
import classes from "./styles/Timer.module.css";
import RightSidebar from '../../../common/RightSidebar';
import { Helmet } from 'react-helmet';
import { FullWidthWiraaAds } from '../../../common/Ads/FullWidthWiraaAds';

const OnlineStopwatch = () => {
  const [isTimerStart, setIsTimerStart] = useState(false);
  const [totalMilliseconds, setTotalMilliseconds] = useState(0);

  useEffect(() => {
    let interval;

    if (isTimerStart) {
        interval = setInterval(() => {
            setTotalMilliseconds(prev => prev + 100);
        }, 100); // Updated to 100ms interval
    } else {
        clearInterval(interval);
    }

    return () => clearInterval(interval);
}, [isTimerStart]);

const handleStartPause = () => {
    setIsTimerStart(prevState => !prevState);
};

const handleRestart = () => {
    setIsTimerStart(false);
    setTotalMilliseconds(0);
};

const formatTime = (time, digits = 2) => String(time).padStart(digits, '0');
const formatTime2 = (time, digits = 1) => String(time).padStart(digits, '0');

const hours = Math.floor(totalMilliseconds / 3600000);
const minutes = Math.floor((totalMilliseconds % 3600000) / 60000);
const seconds = Math.floor((totalMilliseconds % 60000) / 1000);
const milliseconds = Math.floor((totalMilliseconds % 1000) / 100);


  return (
    <div className={classes.page}>
      <Helmet>
                <title>Online Stopwatch - Wiraa tools</title>
                <meta name="description" content="Track time effortlessly with our Online Stopwatch. Start, pause, and reset with precision for workouts, study sessions, or any timed activity. No downloads required!" />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Online Stopwatch</h1>
        <p className={classes.subTitle}>Accurate Time Tracking: Start, Pause, and Manage Your Stopwatch with Ease!</p>
      <div className={classes.innerCon}>
        <div className={classes.stopwatchCon}>
        <p className={classes.stopwatch}>{minutes > 0 && formatTime(hours) + ":"}{formatTime(minutes)}:{formatTime(seconds)}.<span>{formatTime2(milliseconds)}</span></p>
        </div>
        <div className={classes.btnCon} style={{marginBottom : "3rem"}}>
            <button className={classes.convertBtn} onClick={handleStartPause}>{isTimerStart ? "Pause" : "Start"}</button>
            <button className={classes.clearBtn} onClick={handleRestart}>Reset</button>
        </div>
      </div>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default OnlineStopwatch;
