import React, { useState } from 'react'
import classes from "./styles/SimpleInterest.module.css";
import RightSidebar from '../../../common/RightSidebar';
import { Helmet } from 'react-helmet';
import { FullWidthWiraaAds } from '../../../common/Ads/FullWidthWiraaAds';

const MarginCalculator = () => {
    const [inputValue, setInputValue] = useState();
    const [interestDetails, setInterestDetails] = useState(null);
    const [addInput, setAddInput] = useState({"gstr" : ""})
    const [errorMessage, setErrorMessage] = useState();


    const handleGstCalculation = () => {
      const amount = parseFloat(inputValue);
      const gstRate = parseFloat(addInput.gstr);
  
      if (isNaN(amount) || amount <= 0) {
          setErrorMessage('Please enter a valid amount.');
          setInterestDetails(null);
          return;
      }
  
      if (isNaN(gstRate) || gstRate < 0) {
          setErrorMessage('Please enter a valid GST rate.');
          setInterestDetails(null);
          return;
      }
  
      const gstAmount = (amount * gstRate) / 100;
      const totalAmount = amount + gstAmount;
  
      setInterestDetails({
          gst: gstAmount.toFixed(2),
          total: totalAmount.toFixed(2),
      });
  
      setErrorMessage('');
  };
  
  
    
      const handleClearBtn = () => {
        setInputValue("");
        setAddInput({"gstr" : ""});
        setInterestDetails(null);
        setErrorMessage("");
      }  

  return (
    <div className={classes.page}>
      <Helmet>
                <title>Margin Calculator - Wiraa tools</title>
                <meta name="description" content="Use our Margin Calculator to quickly calculate profit margins, cost prices, and selling prices. Get accurate insights to maximize your business profitability!" />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Margin Calculator</h1>
        <p className={classes.subTitle}>Effortless Margin Calculator – Instantly Determine Profit Margins & Selling Prices</p>
      <div className={classes.innerCon}>
        <div className={classes.inputCon}>
            <p className={classes.inputplaceholder}>Cost</p>
            <input value={inputValue} onChange={(e) => setInputValue(e.target.value)} className={classes.inputfield} placeholder='Enter amount'/>
            {errorMessage && <p className={classes.errorMsg}>{errorMessage}</p>}
        </div>
        <div className={classes.additionalInputCon}>
                    <div className={classes.additionalInputDiv}>
                        <p className={classes.addplaceholder}>Margin %</p>
                        <input value={addInput.gstr} onChange={(e) => setAddInput({ ...addInput, gstr: e.target.value })} className={classes.addInputfield} placeholder='Enter margin %'/>
                    </div>
                </div>
        <div className={classes.btnCon}>
            <button className={classes.convertBtn} onClick={handleGstCalculation}>Calculate</button>
            <button className={classes.clearBtn} onClick={handleClearBtn}>Clear</button>
        </div>
        {interestDetails ? <div className={classes.outputSalaryCon}>
                    <div>
                      <p className={classes.outputplaceholder}>Margin:</p>
                      <p className={classes.outputfield}>{interestDetails.gst}</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Revenue:</p>
                      <p className={classes.outputfield}>{interestDetails.total}</p>
                    </div>  
        </div> : <div className={classes.outputSalaryCon}>
        <div>
                      <p className={classes.outputplaceholder}>Margin:</p>
                      <p className={classes.outputfield}>0</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Revenue:</p>
                      <p className={classes.outputfield}>0</p>
                    </div>
          </div>}
      </div>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is a Margin Calculator?</h3>
        <p>If you know the cost of an item and the required profit margin percentage, this margin calculator will be your best buddy in determining its income. That's not all; you can use any two of the other values to compute any of the important variables in the sales process, including the cost of goods sold (how much you paid for the items you sell), profit margin, revenue (how much you sell them for), and profit. In general, your profit margin indicates how healthy your firm is—with low margins, you're skating on thin ice, and any move for the worse could spell disaster. High-profit margins leave plenty of space for error and poor luck.</p>

        <h3>How to Calculate Margin:</h3>
        <ol>
          <li><b>Find COGS (Cost of Goods Sold):</b><p>Example: $30</p></li>
          <li><b>Determine Revenue (Selling Price):</b><p>Example: $50</p></li>
          <li><b>Calculate Gross Profit:</b><p>Subtract the COGS from the revenue. <br />Gross Profit = Revenue - COGS = $50 - $30 = $20</p></li>
          <li><b>Calculate Profit Margin:</b> <p>Divide the gross profit by the revenue. <br />Profit Margin = Gross Profit / Revenue = $20 / $50 = 0.4</p></li>
          <li><b>Express as Percentage:</b><p>Multiply the result by 100 to express it as a percentage. <br />Profit Margin = 0.4 × 100 = 40%</p></li>
        </ol>

        <h3>Key Difference Between Margin and Markup:</h3>
        <p>The margin is calculated based on revenue, while the markup is based on the cost of goods sold (COGS). This makes profit margin a better indicator of profitability from a revenue standpoint.</p>

        <h3>Benefits of Using a Margin Calculator</h3>
        <ol>
          <li><b>Quick and Accurate Calculations:</b> Instantly determines profit margins, ensuring accurate results without manual calculations.</li>
          <li><b>Saves Time:</b> Speeds up the process of calculating margin percentages, improving efficiency in financial planning.</li>
          <li><b>Helps with Pricing Strategy:</b> Assists businesses in setting the right prices by understanding their profit margins, ensuring profitability.</li>
          <li><b>Easy to Use:</b> Simplifies the process for individuals and business owners with minimal financial knowledge.</li>
          <li><b>Improves Decision-Making:</b> Provides insights into profitability, helping businesses make informed decisions regarding cost management, sales, and pricing.</li>
        </ol>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default MarginCalculator;
