import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const DotnetJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>DotNet Jobs</title>
                <meta name="description" content="Find the best DotNet developer jobs in the US and India. Connect with leading companies and advance your career with DotNet Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>DotNet Job Finder</h1>
        <p className={classes.subTitle}>Discover Top DotNet Developer Jobs to Build Powerful Applications and Solutions</p>
      <JobFinder jobTitle={"DotNet"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is .NET?</h3>
        <p>.NET (Dotnet) is a powerful open-source development platform created by Microsoft. It allows developers to build applications for web, mobile, desktop, cloud, gaming, and IoT devices. .NET supports multiple programming languages, including C#, F#, and VB.NET, making it a versatile framework for software development.</p>

        <h3>What Do .NET Developers Do?</h3>
        <p>.NET developers are responsible for designing, building, and maintaining applications using the .NET framework. Their key responsibilities include:</p>

        <ul>
          <li>Developing web applications using ASP.NET and .NET Core.</li>
          <li>Building mobile apps with Xamarin and .NET MAUI.</li>
          <li>Creating desktop applications with WPF and Windows Forms.</li>
          <li>Managing databases using Entity Framework and SQL Server.</li>
          <li>Implementing cloud solutions with Azure integration.</li>
          <li>Enhancing performance and security in applications.</li>
          <li>Collaborating with frontend developers for seamless integration.</li>
        </ul>

        <h3>Key Features of .NET</h3>
        <ol>
          <li><b>Cross-Platform Development:</b> Develop applications for Windows, macOS, and Linux.</li>
          <li><b>Multi-Language Support:</b> Works with C#, F#, and VB.NET.</li>
          <li><b>Scalability & Performance:</b> Optimized for high-performance applications.</li>
          <li><b>Cloud & AI Integration:</b> Seamlessly integrates with Microsoft Azure and AI tools.</li>
          <li><b>Rich Libraries & Frameworks:</b> Extensive libraries for web, mobile, and enterprise solutions.</li>
        </ol>

        <h3>Salary Expectations for .NET Developers</h3>
        <p>.NET developers are in demand across various industries, with competitive salaries:</p>
        <ul>
          <li><b>India:</b> Entry-level .NET developers earn ₹4-8 LPA, while experienced professionals earn ₹12-25 LPA.</li>
          <li><b>US:</b> Salaries range from $80,000 to $140,000 per year, depending on expertise and location.</li>
        </ul>
        <h3>Scope of .NET Development</h3>
        <ol>
          <li><b>Web Development Growth</b> – .NET remains a top choice for building enterprise web applications.</li>
          <li><b>Cloud & AI Adoption</b> – Integration with Azure makes .NET valuable for cloud-based solutions.</li>
          <li><b>Game Development</b> – .NET is used in Unity for game development.</li>
          <li><b>Cross-Platform Applications</b> – .NET Core enables apps for different operating systems.</li>
          <li><b>Enterprise Solutions</b> – Large organizations rely on .NET for robust and scalable applications.</li>
        </ol>
        <h3>Conclusion</h3>
        <p>.NET development offers a stable and rewarding career with opportunities in web, mobile, cloud, and enterprise applications. As businesses continue to adopt .NET Core and cloud-based solutions, skilled .NET developers will remain in high demand in India, the US, and worldwide.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default DotnetJob;
