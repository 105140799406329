import React, { useState } from 'react'
import classes from "./styles/HourlytoSalary.module.css";
import RightSidebar from '../../../common/RightSidebar';
import { Helmet } from 'react-helmet';
import { FullWidthWiraaAds } from '../../../common/Ads/FullWidthWiraaAds';

const SalaryCalculator = () => {
    const [inputValue, setInputValue] = useState();
    const [salaryDetails, setSalaryDetails] = useState(null);
    const [errorMessage, setErrorMessage] = useState();


    const handleSalaryCalculation = () => {
      const annualCTC = parseFloat(inputValue);
      if (isNaN(annualCTC) || annualCTC <= 0) {
          setErrorMessage("Please enter a valid Annual CTC amount.");
          return;
      }

      setErrorMessage(""); // Clear previous errors

      // Assumptions
      const basicSalary = annualCTC * 0.50; // 50% of CTC
      const monthlyBasicSalary = basicSalary / 12;
      const pf = monthlyBasicSalary * 0.12; // 12% of Basic Salary
      const insurance = 2000; // Fixed ₹2000 deduction per month

      // Approximate Tax Calculation (Assuming no other deductions)
      let annualTax = 0;
      if (annualCTC > 2500000) {
          annualTax = (annualCTC - 2500000) * 0.30 + 125000;
      } else if (annualCTC > 1000000) {
          annualTax = (annualCTC - 1000000) * 0.20 + 125000;
      } else if (annualCTC > 500000) {
          annualTax = (annualCTC - 500000) * 0.05;
      }

      const monthlyTax = annualTax / 12;
      const inHandSalary = (annualCTC / 12) - pf - monthlyTax - insurance;

      setSalaryDetails({
          monthlyInHand: inHandSalary.toFixed(2),
          pf: pf.toFixed(2),
          tax: monthlyTax.toFixed(2),
          insurance: insurance.toFixed(2)
      });
  };
    
      const handleClearBtn = () => {
        setInputValue("");
        setSalaryDetails(null);
        setErrorMessage("");
      }  

  return (
    <div className={classes.page}>
      <Helmet>
                <title>Salary Calculator - Wiraa tools</title>
                <meta name="description" content="Use our Salary Calculator to estimate your Monthly In-Hand Salary, Provident Fund (PF), Income Tax, and Insurance deductions based on your Annual CTC. Quick, accurate, and easy to use!" />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Salary Calculator</h1>
        <p className={classes.subTitle}>Calculate your Monthly In-Hand Salary, PF, Tax, and Insurance deductions instantly.</p>
      <div className={classes.innerCon}>
        <div className={classes.inputCon}>
            <p className={classes.inputplaceholder}>Annual CTC</p>
            <input value={inputValue} onChange={(e) => setInputValue(e.target.value)} className={classes.inputfield} placeholder='Enter annual CTC...'/>
            {errorMessage && <p className={classes.errorMsg}>{errorMessage}</p>}
        </div>
        <div className={classes.btnCon}>
            <button className={classes.convertBtn} onClick={handleSalaryCalculation}>Calculate</button>
            <button className={classes.clearBtn} onClick={handleClearBtn}>Clear</button>
        </div>
        {salaryDetails ? <div className={classes.outputSalaryCon}>
                    <div>
                      <p className={classes.outputplaceholder}>In-Hand Salary:</p>
                      <p className={classes.outputfield}>₹{salaryDetails.monthlyInHand}</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Provident Fund (PF):</p>
                      <p className={classes.outputfield}>₹{salaryDetails.pf}</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Income Tax:</p>
                      <p className={classes.outputfield}>₹{salaryDetails.tax}</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Insurance:</p>
                      <p className={classes.outputfield}>₹{salaryDetails.insurance}</p>
                    </div>   
        </div> : <div className={classes.outputSalaryCon}>
        <div>
                      <p className={classes.outputplaceholder}>In-Hand Salary:</p>
                      <p className={classes.outputfield}>₹ 0</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Provident Fund (PF):</p>
                      <p className={classes.outputfield}>₹ 0</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Income Tax:</p>
                      <p className={classes.outputfield}>₹ 0</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Insurance:</p>
                      <p className={classes.outputfield}>₹ 0</p>
                    </div>   
          </div>}
      </div>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>Salary</h3>
        <p>An employee receives a salary or wages from their employer in exchange for their labor and time. Many nations implement minimum salaries set by the national or local governments to safeguard their workers. Additionally, unions may be established to establish norms within particular businesses or sectors.</p>
        <p>A salary is normally paid regularly, and the amount normally does not fluctuate based on the quality or quantity of work performed. An employee's salary is commonly defined as an annual figure in an employment contract that is signed upon hiring. Salary can sometimes be accompanied by additional compensation such as goods or services.</p>

        <h3>How Unadjusted and Adjusted Salaries are calculated?</h3>
        <p>Using a $30 hourly rate, an average of eight hours worked each day, and 260 working days a year (52 weeks multiplied by 5 working days a week), the annual unadjusted salary can be calculated as:</p>
        <p>$30 × 8 × (260) = $62,400</p>
        <p>As can be seen, the hourly rate is multiplied by the number of working days a year (unadjusted) and subsequently multiplied by the number of hours in a working day. The adjusted annual salary can be calculated as:</p>
        <p>$30 × 8 × (260 - 25) = $56,400</p>
        <p>Using 10 holidays and 15 paid vacation days a year, subtract these non-working days from the total number of working days a year.</p>
        <p>All bi-weekly, semi-monthly, monthly, and quarterly figures are derived from these annual calculations. It is important to make the distinction between bi-weekly, which happens every two weeks, and semi-monthly, which occurs twice per month, usually on the fifteenth and final day of the month.</p>

        <h3>Why Is a Salary Calculator Useful?</h3>
        <ol>
          <li><b>Accurate Earnings Estimation:</b> With accurate computations that account for hourly pay, yearly salaries, bonuses, and deductions, a salary calculator assists people in figuring out their overall income.</li>
          <li><b>Financial Planning:</b> Users may efficiently budget for spending, savings, and investments by knowing their take-home salary.</li>
          <li><b>Job Offer Comparison:</b> It allows job seekers to compare salaries across different roles or industries, helping them make informed career choices.</li>
          <li><b>Time-Saving Tool:</b> Reduces the possibility of errors and does away with the necessity for manual computation by automating difficult calculations.</li>
          <li><b>Customizable Insights:</b> Many calculators consider variables like tax rates, overtime, or benefits, providing a personalized view of earnings.</li>
        </ol>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default SalaryCalculator;
