import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const DataScienceJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Data Science Jobs</title>
                <meta name="description" content="Explore top data science jobs in the US and India. Connect with top companies and grow your career with Data Science Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Data Science Job Finder</h1>
        <p className={classes.subTitle}>Discover Leading Data Science Opportunities to Propel Your Career Forward</p>
      <JobFinder jobTitle={"Data Science"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is Data Science?</h3>
        <p>The goal of the multidisciplinary area of data science is to extract insightful information from both organized and unstructured data. Data science helps organizations in making data-driven decisions using statistical tools, programming, and machine-learning techniques. It is a rapidly expanding profession in both India and the US, where data is crucial to the innovation and operational optimization of sectors including healthcare, banking, technology, and retail.</p>

        <h3>Why is Data Science Important?</h3>
        <ol>
          <li><b>Business Insights:</b> Data Science allows organizations to identify patterns and trends, helping them to understand consumer behavior and market dynamics.</li>
          <li><b>Predictive Analysis:</b> It enables companies to anticipate results, like sales or customer needs, assisting in forward-thinking decision-making.</li>
          <li><b>Operational Efficiency:</b> Through process analysis, Data Science can pinpoint inefficiencies and recommend enhancements.</li>
          <li><b>Customization:</b> Companies can provide personalized experiences to users, like suggesting products or services according to personal preferences.</li>
          <li><b>Solution-Finding:</b> Data Science offers creative answers for complex issues in areas such as climate change, healthcare, and logistics.</li>
        </ol>

        <h3>Skills Needed for Data Science</h3>
        <ul>
          <li><b>Programming Proficiency:</b> The primary languages for data analysis and manipulation include Python, R, and SQL.</li>
          <li><b>Expertise in Machine Learning:</b> Understanding techniques such as decision trees, clustering, and neural networks.</li>
          <li><b>Statistical Knowledge:</b> Strong understanding of probability, distributions, and hypothesis testing.</li>
          <li><b>Tools for Data Visualization:</b> Proficiency with Tableau, Power BI, or Python packages such as Seaborn and Matplotlib.</li>
          <li><b>Big Data Tools:</b> Proficiency with cloud-based systems, Hadoop, or Spark for managing large datasets.</li>
        </ul>

        <h3>Average Salaries for Data Scientists</h3>
        <ul>
          <li><b>India:</b> ₹6,00,000 to ₹20,00,000 annually, with top companies offering higher packages.</li>
          <li><b>US:</b> $90,000 to $170,000 per year, especially in technology hubs like Silicon Valley or New York City.</li>
        </ul>
        
        <h3>Scope of Data Science</h3>
        <p>The need for data science experts continues to rise globally. It is important in sectors including banking, healthcare, and e-commerce in India. Tech, retail, and entertainment are the US industries that use data science the most. Future developments in cloud computing, IoT, and artificial intelligence further broaden its possible uses, making it a profession with long-term growth potential.</p>

        <h3>Conclusion</h3>
        <p>The future of business and technology is being shaped in large part by the field of data science. It is essential across industries due to its capacity to extract significant insights from data. Pursuing a career in data science offers fascinating chances and a bright future, whether in the US or India.</p>
      

      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default DataScienceJob;
