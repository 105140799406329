import React, { useState } from 'react';
import classes from "./styles/passwordgenerator.module.css";
import RightSidebar from '../../../common/RightSidebar';
import { Helmet } from 'react-helmet';
import { FullWidthWiraaAds } from '../../../common/Ads/FullWidthWiraaAds';

const PasswordStrengthChecker = () => {
    const [password, setPassword] = useState("");
    const [output, setOutput] = useState("");
    const [strength, setStrength] = useState(0); // Track strength for animation

    const checkPassword = () => {
        const lengthCriteria = password.length >= 8;
        const numberCriteria = /\d/.test(password);
        const upperCaseCriteria = /[A-Z]/.test(password);
        const lowerCaseCriteria = /[a-z]/.test(password);
        const specialCharCriteria = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        let strengthCount = 0;

        if (lengthCriteria) ++strengthCount;
        if (numberCriteria) ++strengthCount;
        if (upperCaseCriteria) ++strengthCount;
        if (lowerCaseCriteria) ++strengthCount;
        if (specialCharCriteria) ++strengthCount;

        setStrength(strengthCount);

        if (strengthCount === 5) {
            setOutput("Very Strong");
        } else if (strengthCount === 4) {
            setOutput("Strong");
        } else if (strengthCount === 3) {
            setOutput("Medium");
        } else if (strengthCount === 2) {
            setOutput("Weak");
        } else {
            setOutput("Very Weak");
        }
    };

    const handleReset = () => {
        setPassword("");
        setOutput("");
        setStrength(0); // Reset strength
    };

    // Dynamic progress bar styles
    const progressBarStyles = {
        width: `${(strength / 5) * 100}%`,
        backgroundColor: strength === 5 ? 'green' : 
                         strength === 4 ? 'blue' : 
                         strength === 3 ? 'orange' : 
                         strength === 2 ? 'red' : 'gray',
        transition: 'width 0.5s ease', // Animation for smooth transition
    };

    return (
        <div className={classes.page}>
            <Helmet>
                <title>Password Strength Checker - Wiraa tools</title>
                <meta name="description" content="Boost your online security by using the Password Strength Checker. Easily evaluate and strengthen your password's strength to safeguard your accounts from potential breaches." />
            </Helmet>
            <div className={classes.container}>
                <h1 className={classes.title}>Password Strength Checker</h1>
                <p className={classes.subTitle}>Ensure Stronger Security with Password Strength Checker – Validate and Improve Your Password's Integrity</p>
                <div className={classes.innerCon}>
                    <div className={classes.additionalInputCon}>
                        <div className={classes.additionalInputDiv}>
                            <p className={classes.inputplaceholder}>Password</p>
                            <input type="text" value={password} onChange={(e) => {setPassword(e.target.value);}} className={classes.addInputfield} />
                        </div>
                    </div>
                    <div className={classes.btnCon}>
                        <button className={classes.convertBtn} onClick={checkPassword}>Check</button>
                        <button className={classes.clearBtn} onClick={handleReset}>Reset</button>
                    </div>
                    <div className={classes.outputCon}>
                        <p className={classes.outputplaceholder}>Result</p>
                        <p className={classes.outputfield}>{output}</p>
                    </div>

                    {/* Progress Bar for Password Strength */}
                    <div className={classes.progressBarWrapper}>
                        <div className={classes.progressBar} style={progressBarStyles}></div>
                    </div>
                </div>

                <div className={classes.ads}>
                    <FullWidthWiraaAds />
                </div>

                <div className={classes.contentContainer}>
                </div>
            </div>
            <div className={classes.rightsidebar}>
                <RightSidebar />
            </div>
        </div>
    );
}

export default PasswordStrengthChecker;
