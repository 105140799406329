import React, { useState } from 'react'
import classes from "./styles/SimpleInterest.module.css";
import RightSidebar from '../../../common/RightSidebar';
import { Helmet } from 'react-helmet';
import { FullWidthWiraaAds } from '../../../common/Ads/FullWidthWiraaAds';

const IncomeTaxCalculatorIndia = () => {
    const [inputValue, setInputValue] = useState();
    const [taxDetails, setTaxDetails] = useState(null);
    const [errorMessage, setErrorMessage] = useState();


    const handleTaxCalculation = () => {
      const income = parseFloat(inputValue);
      if (isNaN(income) || income < 0) {
          setErrorMessage('Please enter a valid annual income.');
          setTaxDetails(null);
          return;
      }

      // Apply standard deduction for salaried individuals
      const standardDeduction = 75000;
      let taxableIncome = Math.max(income - standardDeduction, 0);

      let tax = 0;

      if (taxableIncome > 2400000) {
          tax += (taxableIncome - 2400000) * 0.30;
          taxableIncome = 2400000;
      }
      if (taxableIncome > 2000000) {
          tax += (taxableIncome - 2000000) * 0.25;
          taxableIncome = 2000000;
      }
      if (taxableIncome > 1600000) {
          tax += (taxableIncome - 1600000) * 0.20;
          taxableIncome = 1600000;
      }
      if (taxableIncome > 1200000) {
          tax += (taxableIncome - 1200000) * 0.15;
          taxableIncome = 1200000;
      }
      if (taxableIncome > 800000) {
          tax += (taxableIncome - 800000) * 0.10;
          taxableIncome = 800000;
      }
      if (taxableIncome > 400000) {
          tax += (taxableIncome - 400000) * 0.05;
          taxableIncome = 400000;
      }

      // Determine tax bracket
      let taxBracket = '';
      if (income <= 400000) {
          taxBracket = '0%';
      } else if (income <= 800000) {
          taxBracket = '5%';
      } else if (income <= 1200000) {
          taxBracket = '10%';
      } else if (income <= 1600000) {
          taxBracket = '15%';
      } else if (income <= 2000000) {
          taxBracket = '20%';
      } else if (income <= 2400000) {
          taxBracket = '25%';
      } else {
          taxBracket = '30%';
      }

      setTaxDetails({
          bracket: taxBracket,
          total: tax.toFixed(2)
      });
      setErrorMessage('');
  };
  
    
      const handleClearBtn = () => {
        setInputValue("");
        setTaxDetails(null);
        setErrorMessage("");
      }  

  return (
    <div className={classes.page}>
      <Helmet>
                <title>Income Tax Calculator India - Wiraa tools</title>
                <meta name="description" content="Use our India Income Tax Calculator to estimate your federal tax liability based on your income, deductions, and filing status. Get accurate tax insights instantly!" />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Income Tax Calculator India</h1>
        <p className={classes.subTitle}>Calculate Your Federal Income Tax Liability Quickly and Accurately with the 2025 Tax Slabs</p>
      <div className={classes.innerCon}>
        <div className={classes.inputCon}>
            <p className={classes.inputplaceholder}>Annual Income</p>
            <input value={inputValue} onChange={(e) => setInputValue(e.target.value)} className={classes.inputfield} placeholder='Enter Annual Income'/>
            {errorMessage && <p className={classes.errorMsg}>{errorMessage}</p>}
        </div>
        <div className={classes.btnCon}>
            <button className={classes.convertBtn} onClick={handleTaxCalculation}>Calculate</button>
            <button className={classes.clearBtn} onClick={handleClearBtn}>Clear</button>
        </div>
        {taxDetails ? <div className={classes.outputSalaryCon}>
                    <div>
                      <p className={classes.outputplaceholder}>Tax Slabs:</p>
                      <p className={classes.outputfield}>₹{taxDetails.bracket}</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Total Tax:</p>
                      <p className={classes.outputfield}>₹{taxDetails.total}</p>
                    </div>  
        </div> : <div className={classes.outputSalaryCon}>
        <div>
                      <p className={classes.outputplaceholder}>Tax Slabs:</p>
                      <p className={classes.outputfield}>₹0</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Total Tax:</p>
                      <p className={classes.outputfield}>₹0</p>
                    </div>
          </div>}
      </div>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is an Income Tax Calculator?</h3>
        <p>An Income Tax calculator is a tool that estimates the amount of income tax payable based on a person's salary, filing status, and any applicable deductions or credits. It streamlines the process of calculating tax liabilities by taking into account criteria such as gross income, tax brackets, exemptions, and standard or itemized deductions.</p>
        <p>This calculator is widely used by individuals and businesses to:</p>
        <ul>
            <li>Plan for tax payments.</li>
            <li>Estimate refunds or liabilities.</li>
            <li>Optimize tax-saving strategies.</li>
        </ul>
        <h3>How to Use the Income Tax Calculator for FY 2024-25 (AY 2025-26) and FY 2023-24(AY 2024-25)?</h3>
        <ol>
            <li><b>Enter Your Income Details:</b> Start by providing your annual income, including salary, business income, and other sources like rental or interest income.</li>
            <li><b>Select Your Tax Regime:</b> Choose between the old tax regime (with deductions and exemptions) or the new tax regime (lower rates but fewer deductions).</li>
            <li><b>Add Deductions and Exemptions:</b> If using the old regime, input deductions like Section 80C (investments), 80D (health insurance), and HRA exemptions.</li>
            <li><b>Provide Other Information:</b> Enter additional details such as age (to account for senior citizen benefits), residential status, and any applicable tax credits.</li>
            <li><b>Review Your Tax Liability:</b> The calculator will display your estimated tax liability or refund for the selected financial year. Compare results across regimes to choose the most beneficial option.</li>
            <li><b>Adjust for Changes:</b> Update inputs to reflect changes in income, investments, or exemptions for an accurate calculation.</li>
        </ol>

        <h3>Benefits of Using Income Tax Calculator</h3>
        <p>Using our online Income tax calculator offers several benefits such as:</p>
        <ul>
            <li><b>Accuracy:</b> The income tax calculator calculates your tax liability accurately eliminating any potential errors that result from calculating the tax liability manually. </li>
            <li><b>Swift and Hassle-free:</b> The Income tax calculator is a tool that calculates your tax liability swiftly within a few seconds and can be used anytime and anywhere.</li>
            <li><b>User-friendly:</b> The Income tax calculator is a very user-friendly tool. Simply input your income and deduction details to accurately calculate your tax liability.</li>
            <li><b>Better Financial Management and Tax Planning:</b> Understanding your tax liability in advance is critical for successful financial planning. Knowing your possible tax requirements allows you to budget effectively and invest in tax-saving products such as ELSS, PPF, and so on.</li>
        </ul>

        <h3>Income tax calculation for the Salaried</h3>
        <p>To calculate income tax for salaried individuals in India, follow these steps:</p>
        <ol>
            <li>
                <b>Calculate Gross Salary:</b>
                <p>Include all components of your salary such as:</p>
                <ul>
                    <li>Basic salary</li>
                    <li>House Rent Allowance (HRA)</li>
                    <li>Special allowances</li>
                    <li>Any bonuses</li>
                </ul>
            </li>
            <li>
                <b>Deduct Exemptions:</b>
                <p>Under the old tax regime, subtract eligible exemptions like:</p>
                <ul>
                    <li><b>HRA:</b> If you live in rented accommodation and meet HRA conditions.</li>
                    <li><b>Standard Deduction:</b> ₹50,000 for all salaried individuals.</li>
                    <li>Leave Travel Allowance (LTA), if applicable.</li>
                </ul>
            </li>
            <li>
                <b>Add Income from Other Sources:</b>
                <p>Include any income outside salary, such as:</p>
                <ul>
                    <li>Interest from savings accounts or fixed deposits.</li>
                    <li>Rental income.</li>
                </ul>
            </li>
            <li>
                <b>Subtract Deductions:</b>
                <p>Under SSections80C, 80D, and others, subtract eligible deductions:</p>
                <ul>
                    <li><b>80C:</b> Investments like EPF, PPF, ELSS, or life insurance (up to ₹1,50,000).</li>
                    <li><b>80D:</b> Health insurance premiums.</li>
                    <li>Other relevant deductions.</li>
                </ul>
            </li>
            <li>
                <b>Determine Taxable Income:</b>
                <p>Taxable Income = Gross Salary - Exemptions - Deductions</p>
            </li>
            <li>
                <b>Apply Tax Slabs:</b>
                <ul>
                    <li><b>Old Tax Regime:</b> Calculate based on applicable tax brackets with exemptions and deductions.</li>
                    <li><b>New Tax Regime:</b> Calculate using reduced tax rates without exemptions and deductions.</li>
                </ul>
            </li>
        </ol>

        <h3>Example:</h3>
        <ul>
            <li><b>Gross Salary:</b> ₹8,00,000</li>
            <li><b>Exemptions:</b> ₹1,00,000 (HRA + Standard Deduction)</li>
            <li><b>Deductions:</b> ₹1,50,000 (Section 80C)</li>
        </ul>
        <p><b>Taxable Income:</b> ₹8,00,000 - ₹1,00,000 - ₹1,50,000 = ₹5,50,000</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default IncomeTaxCalculatorIndia;
