import React, { useState } from 'react'
import classes from "./styles/HourlytoSalary.module.css";
import RightSidebar from '../../../common/RightSidebar';
import { Helmet } from 'react-helmet';
import { FullWidthWiraaAds } from '../../../common/Ads/FullWidthWiraaAds';

const SalarytoHourlyCalculator = () => {
    const [inputValue, setInputValue] = useState();
    const [addInput, setAddInput] = useState({"hour" : "8", "day" : "6"})
    const [outputValue, setOutputValue] = useState();
    const [errorMessage, setErrorMessage] = useState();


    const handleHourlyCalculation = () => {
        // Validate salary input
        const salary = parseFloat(inputValue);
        if (!salary || salary < 0) {
            setErrorMessage("Please enter a valid salary.");
            setOutputValue("");
            return;
        }
    
        const hour = parseFloat(addInput.hour);
        const day = parseFloat(addInput.day);
    
        // Validate additional inputs
        if (
            isNaN(hour) || hour <= 0 ||
            isNaN(day) || day <= 0 || day > 7
        ) {
            setErrorMessage("Please enter valid numbers for hours per day and days per week.");
            setOutputValue("");
            return;
        }
    
        setErrorMessage(""); // Clear errors
    
        const weeklyHours = hour * day; // Total weekly hours
        const yearlySalary = salary * 12; // Convert monthly salary to yearly
        const hourlyWage = yearlySalary / (weeklyHours * 52); // Convert to hourly rate
    
        setOutputValue(hourlyWage.toFixed(2));
    };
    
      const handleClearBtn = () => {
        setInputValue("");
        setOutputValue("");
        setErrorMessage("");
      }  

  return (
    <div className={classes.page}>
      <Helmet>
                <title>Salary To Hourly Calculator - Wiraa tools</title>
                <meta name="description" content="Use our Salary to Hourly Calculator to quickly convert your annual, monthly, or weekly salary into an hourly wage. Get accurate calculations based on your work hours!" />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Salary To Hourly Calculator</h1>
        <p className={classes.subTitle}>Easily Convert Your Salary to an Hourly Wage</p>
      <div className={classes.innerCon}>
        <div className={classes.inputCon}>
            <p className={classes.inputplaceholder}>Monthly salary</p>
            <input value={inputValue} onChange={(e) => setInputValue(e.target.value)} className={classes.inputfield} placeholder='Enter your monthly salary'/>
            {errorMessage && <p className={classes.errorMsg}>{errorMessage}</p>}
        </div>
        <div className={classes.additionalInputCon}>
            <div className={classes.additionalInputDiv}>
                <p className={classes.addplaceholder}>Hours a day</p>
                <input value={addInput.hour} onChange={(e) => setAddInput({ ...addInput, hour: e.target.value })} className={classes.addInputfield} placeholder='Working hours in a day...'/>
            </div>
            <div className={classes.additionalInputDiv}>
                <p className={classes.addplaceholder}>Days in week</p>
                <input value={addInput.day} onChange={(e) => setAddInput({ ...addInput, day: e.target.value })} className={classes.addInputfield} placeholder='Working days in a week...'/>
            </div>
        </div>
        <div className={classes.btnCon}>
            <button className={classes.convertBtn} onClick={handleHourlyCalculation}>Calculate</button>
            <button className={classes.clearBtn} onClick={handleClearBtn}>Clear</button>
        </div>
        <div className={classes.outputCon}>
            <p className={classes.outputplaceholder}>Hourly Salary</p>
            <p className={classes.outputfield}>{outputValue}</p>
        </div>
      </div>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>Salary</h3>
        <p>An employee receives a salary or wages from their employer in exchange for their labor and time. Many nations implement minimum salaries set by the national or local governments to safeguard their workers. Additionally, unions may be established to establish norms within particular businesses or sectors.</p>
        <p>Using our calculator, you may determine your hourly compensation based on your monthly or annual salary. To discover how to manually calculate your hourly rate and the corresponding hourly rate for some of the most popular yearly salaries, keep scrolling.</p>

        <h3>The formula for calculating Hourly Rate</h3>
        <p>The formula for calculating an Hourly Rate depends on the type of salary structure and the number of hours worked. Here are common scenarios:</p>

        <h3>1. For Annual Salary</h3>
        <p>Hourly Rate=Annual Salary/Total Hours Worked Per Year</p>

        <h3>2. For Weekly Salary</h3>
        <p>Hourly Rate=Weekly Salary/Hours Worked Per Week</p>

        <h3>3. For Monthly Salary</h3>
        <p>Hourly Rate=Monthly Salary×12/Total Hours Worked Per Year</p>
        <p>These formulas allow you to determine your hourly rate based on your annual, monthly, or weekly income, making it useful for understanding your earning potential.</p>

        <h3>$40,000 a year is how much per hour?</h3>
        <div className={classes.tableCon}>
        <table>
        <tr>
            <th>Period</th>
            <th>Amount</th>
        </tr>
        <tr>
            <td>Yearly</td>
            <td>$40,000</td>
        </tr>
        <tr>
            <td>Monthly</td>
            <td>$3,333.33</td>
        </tr>
        <tr>
            <td>Weekly</td>
            <td>$769.23</td>
        </tr>
        <tr>
            <td>Hourly</td>
            <td>$19.23</td>
        </tr>
    </table>
        </div>

        <h3>Why Is Salary to Hourly Converters Useful?</h3>
        <ol>
          <li><b>Clarity in Earnings:</b> Converts annual or monthly salaries into an hourly rate, giving employees and freelancers a clear understanding of their earnings per hour.</li>
          <li><b>Job Comparison:</b> Helps individuals compare salaried positions with hourly job offers to make informed career decisions.</li>
          <li><b>Work-Life Balance Insights:</b> By understanding hourly earnings, individuals can assess whether their workload justifies their pay and make adjustments if necessary.</li>
          <li><b>Accurate Budgeting:</b> Knowing the hourly rate enables better financial planning, especially for overtime, part-time work, or side gigs.</li>
          <li><b>Tailored Calculations:</b> These converters account for variations in work hours, such as overtime or part-time schedules, providing precise and personalized results.</li>
        </ol>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default SalarytoHourlyCalculator;
