import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const AdobeJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Adobe Jobs</title>
                <meta name="description" content="Find the best Adobe jobs in the US and India. Connect with leading companies and advance your career with Adobe Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Adobe Job Finder</h1>
        <p className={classes.subTitle}>Explore Top Adobe Job Opportunities to Design Exceptional User Interfaces</p>
      <JobFinder jobTitle={"Adobe"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is Adobe?</h3>
        <p>Adobe is a software company that is known worldwide for its creative, design, and multimedia products. It offers leading tools for graphic design, video production, web development, photography, and digital marketing. Adobe's collection features well-known programs such as Photoshop, Illustrator, Premiere Pro, After Effects, and Adobe XD, commonly utilized by designers, filmmakers, and marketing experts.</p>

        <h3>Essential Features of Adobe Software</h3>
        <ul>
          <li><b>Graphic Design & Illustration:</b> Applications like Photoshop, Illustrator, and InDesign assist in producing eye-catching visuals.</li>
          <li><b>Video & Animation Editing:</b> Premiere Pro and After Effects facilitate the creation of high-quality video content. </li>
          <li><b>UI/UX & Web Design:</b> Adobe XD and Dreamweaver aid in designing websites and user interfaces.</li>
          <li><b>PDF & Document Management:</b> Adobe Acrobat offers robust capabilities for PDF editing and electronic signatures.</li>
          <li><b>Cloud-Based Workflow:</b> Adobe Creative Cloud enables users to store, access, and collaborate on projects online.</li>
        </ul>
        <h3>Types of Jobs Using Adobe</h3>
        <p>The average salary of Adobe software is:</p>
        <ul>
          <li><b>Graphic Designers:</b> Use Photoshop and Illustrator for branding, marketing materials, and digital art.</li>
          <li><b>Video Editors & Animators:</b> Edit films, create motion graphics, and work on special effects using Premiere Pro and After Effects.</li>
          <li><b>UI/UX Designers:</b> Build digital interfaces with Adobe XD.</li>
          <li><b>Photographers:</b> Edit and enhance images with Lightroom and Photoshop.</li>
          <li><b>Marketing Professionals:</b> Use Adobe tools for social media content, ad creation, and digital campaigns.</li>
        </ul>

        <h3>Average Salary of Professionals Using Adobe</h3>
        <p>Salaries vary based on job role, experience, and location:</p>
        <ul>
          <li><b>India:</b> ₹3 LPA – ₹12 LPA for graphic designers and video editors.</li>
          <li><b>US:</b> $50,000 – $120,000 per year for creative professionals.</li>
        </ul>

        <h3>Scope of Adobe in the Creative Industry</h3>
        <p>Adobe applications continue to be a top preference for creative professionals globally. The need for proficiency in Adobe tools is on the rise in fields such as digital marketing, entertainment, branding, and content creation. With the introduction of advanced AI-driven features, Adobe is streamlining the processes of design and video editing, making them more user-friendly and accessible. </p>

        <h3>Conclusion</h3>
        <p>Adobe offers an extensive array of tools for creative professionals in diverse industries. Whether you are a graphic designer, video producer, or marketing specialist, gaining expertise in Adobe software can boost your career opportunities. As digital transformation accelerates, Adobe skills have become increasingly important.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default AdobeJob;
