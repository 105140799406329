import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const WebDesignerJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Web Designer Jobs</title>
                <meta name="description" content="Find the best web designer jobs in the US and India. Connect with leading companies and advance your career with Web Designer Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Web Designer Job Finder</h1>
        <p className={classes.subTitle}>Explore Top Web Designer Jobs to Build Engaging and User-Friendly Websites</p>
      <JobFinder jobTitle={"Web Designer"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is a Web Designer?</h3>
        <p>A professional who designs and develops visually appealing and user-friendly websites is known as a web designer. They make sure that visitors have an engaging experience by focusing on a website's layout, color scheme, font, and general appearance. To make sure the design is responsive and functional on various devices, web designers collaborate closely with developers.</p>
        <h3>Key Features of Web Designing</h3>
        <ul>
          <li><b>Visual Design:</b> Creating eye-catching layouts, color schemes, and typography for websites.</li>
          <li><b>User Experience:</b> Providing accessibility and simple navigation.</li>
          <li><b>Responsive Design:</b> Making websites mobile-friendly and screen-adaptable.</li>
          <li><b>Graphics & Multimedia:</b> Including images, animations, and videos.</li>
          <li><b>Coding Knowledge:</b> Basic knowledge of JavaScript, HTML, and CSS for design integration.</li>
        </ul>

        <h3>Types of Web Designers</h3>
        <p>Web design has various specializations, including:</p>

        <ul>
          <li><b>UI Designer:</b> Focuses on the website’s appearance and visual elements.</li>
          <li><b>UX Designer:</b> Enhances the user experience and site navigation.</li>
          <li><b>Graphic Web Designer:</b> Creates banners, logos, and visual assets.</li>
          <li><b>Frontend Designer:</b> Works on both design and frontend coding for website implementation.</li>
        </ul>

        <h3>Average Salary of a Web Designer</h3>
        <p>Salaries for web designers vary based on experience, skills, and location:</p>
        <ul>
          <li><b>India:</b> ₹3 LPA – ₹10 LPA, depending on expertise and projects.</li>
          <li><b>US:</b> $50,000 – $90,000 per year, with senior designers earning more.</li>
        </ul>

        <h3>Scope of Web Designing</h3>
        <p>Web design is a growing industry with significant demand in various sectors, including online retail, corporate sites, personal branding, and mobile apps. As companies keep enhancing their online presence, proficient web designers will always be in demand.</p>

        <h3>Conclusion</h3>
        <p>Web design is an evolving profession that combines artistic creativity and technical know-how. With the appropriate skills, designers can operate as freelancers, work in agencies, or be employed by large corporations. As the digital environment progresses, web designers are presented with limitless possibilities to innovate and craft impressive online experiences.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default WebDesignerJob;
