import React from 'react';
import SidebarLayout from '../layouts/SidebarLayout';
import ReactJs from '../tools/productivity/jobs/it jobs/ReactJsJob';
import { Route, Routes } from 'react-router-dom';
import DeveloperJob from '../tools/productivity/jobs/it jobs/DeveloperJob';
import WebDeveloperJob from '../tools/productivity/jobs/it jobs/WebDeveloperJob';
import AppDeveloperJob from '../tools/productivity/jobs/it jobs/AppDeveloperJob';
import ReactNativeJob from '../tools/productivity/jobs/it jobs/ReactNativeJob';
import JavaJob from '../tools/productivity/jobs/it jobs/JavaJob';
import AngularJob from '../tools/productivity/jobs/it jobs/AngularJob';
import CssJob from '../tools/productivity/jobs/it jobs/CssJob';
import HtmlJob from '../tools/productivity/jobs/it jobs/HtmlJob';
import NextJsJob from '../tools/productivity/jobs/it jobs/NextJsJob';
import WordpressJob from '../tools/productivity/jobs/it jobs/WordpressJob';
import NodeJsJob from '../tools/productivity/jobs/it jobs/NodeJsJob';
import PythonJob from '../tools/productivity/jobs/it jobs/PythonJob';
import DataScienceJob from '../tools/productivity/jobs/it jobs/DataScienceJob';
import ArtificialIntelligenceJob from '../tools/productivity/jobs/it jobs/ArtificialIntelligenceJob';
import DeepLearningJob from '../tools/productivity/jobs/it jobs/DeepLearningJob';
import MachineLearningJob from '../tools/productivity/jobs/it jobs/MachineLearningJob';
import DataAnalysisJob from '../tools/productivity/jobs/it jobs/DataAnalysisJob';
import IosJob from '../tools/productivity/jobs/it jobs/IosJob';
import FlutterJob from '../tools/productivity/jobs/it jobs/FlutterJob';
import AndroidJob from '../tools/productivity/jobs/it jobs/AndroidJob';
import KotlinJob from '../tools/productivity/jobs/it jobs/KotlinJob';
import SwiftJob from '../tools/productivity/jobs/it jobs/SwiftJob';
import UnrealEngineJob from '../tools/productivity/jobs/it jobs/UnrealEngineJob';
import UnityJob from '../tools/productivity/jobs/it jobs/UnityJob';
import GameDeveloperJob from '../tools/productivity/jobs/it jobs/GameDeveloper';
import DatabaseJob from '../tools/productivity/jobs/it jobs/DatabaseJob';
import SqlJob from '../tools/productivity/jobs/it jobs/SqlJob';
import MySqlJob from '../tools/productivity/jobs/it jobs/MySqlJob';
import MongoDBJob from '../tools/productivity/jobs/it jobs/MongoDbJob';
import QaEngineerJob from '../tools/productivity/jobs/it jobs/QaEngineer';
import BackendJob from '../tools/productivity/jobs/it jobs/BackendJob';
import TestingJob from '../tools/productivity/jobs/it jobs/TestingJob';
import FrontendJob from '../tools/productivity/jobs/it jobs/FrontendJob';
import FullStackJob from '../tools/productivity/jobs/it jobs/FullStackJob';
import SystemArchitectJob from '../tools/productivity/jobs/it jobs/SystemArchitectJob';
import SolutionArchitectJob from '../tools/productivity/jobs/it jobs/SolutionArchitect';
import AwsJob from '../tools/productivity/jobs/it jobs/AwsJob';
import AzureJob from '../tools/productivity/jobs/it jobs/AzureJob';
import CloudEngineerJob from '../tools/productivity/jobs/it jobs/CloudEngineerJob';
import DotnetJob from '../tools/productivity/jobs/it jobs/DotnetJob';
import NetworkEngineerJob from '../tools/productivity/jobs/it jobs/NetworkEngineerJob';
import CyberSecurityJob from '../tools/productivity/jobs/it jobs/CyberSecurityJob';
import DataScientistJob from '../tools/productivity/jobs/it jobs/DataScientist';
import DesignerJob from '../tools/productivity/jobs/creative jobs/DesignerJob';
import CreativeJob from '../tools/productivity/jobs/creative jobs/CreativeJob';
import GameDesignerJob from '../tools/productivity/jobs/creative jobs/GameDesignerJob';
import WebDesignerJob from '../tools/productivity/jobs/creative jobs/WebDesignerJob';
import ArchitectJob from '../tools/productivity/jobs/creative jobs/ArchitectJob';
import ThreeDJob from '../tools/productivity/jobs/creative jobs/3DJob';
import GraphicDesignerJob from '../tools/productivity/jobs/creative jobs/GraphicDesignerJob';
import FigmaJob from '../tools/productivity/jobs/creative jobs/FigmaJob';
import AdobeJob from '../tools/productivity/jobs/creative jobs/AdobeJob';
import MerchandiseJob from '../tools/productivity/jobs/creative jobs/MerchandiseJob';
import InteriorDesignerJob from '../tools/productivity/jobs/creative jobs/InteriorDesignerJob';
import UiDesignerJob from '../tools/productivity/jobs/creative jobs/UiDesignerJob';
import UxDesignerJob from '../tools/productivity/jobs/creative jobs/UxDesignerJob';
import UiUxJob from '../tools/productivity/jobs/creative jobs/UiUxJob';
import AnimationJob from '../tools/productivity/jobs/creative jobs/AnimationJob';
import VideoEditorJob from '../tools/productivity/jobs/creative jobs/VideoEditorJob';
import MusicJob from '../tools/productivity/jobs/creative jobs/MusicJob';
import AudioJob from '../tools/productivity/jobs/creative jobs/AudioJob';
import FashionDesignerJob from '../tools/productivity/jobs/creative jobs/FashionDesignerJob';
import MarketingJob from '../tools/productivity/jobs/marketing jobs/MarketingJob';
import BrandingJob from '../tools/productivity/jobs/marketing jobs/BrandingJob';
import AdvertisingJob from '../tools/productivity/jobs/marketing jobs/AdvertisingJob';
import InfluencerJob from '../tools/productivity/jobs/marketing jobs/InfluencerJob';
import AffiliateJob from '../tools/productivity/jobs/marketing jobs/AffiliateJob';
import SeoJob from '../tools/productivity/jobs/marketing jobs/SeoJob';
import ContentJob from '../tools/productivity/jobs/marketing jobs/ContentJob';
import FacebookAdsJob from '../tools/productivity/jobs/marketing jobs/FacebookAdsJob';
import GoogleAdsJob from '../tools/productivity/jobs/marketing jobs/GoogleAdsJob';
import EmailJob from '../tools/productivity/jobs/marketing jobs/EmailJob';
import EcommerceJob from '../tools/productivity/jobs/marketing jobs/EcommerceJob';
import VideoProductionJob from '../tools/productivity/jobs/marketing jobs/VideoProductionJob';
import SocialMediaJob from '../tools/productivity/jobs/marketing jobs/SocialMediaJob';
import PublicRelationJob from '../tools/productivity/jobs/marketing jobs/PublicRelationJob';
import WriterJob from '../tools/productivity/jobs/writing jobs/WriterJob';
import WritingJob from '../tools/productivity/jobs/writing jobs/WritingJob';
import ContentWriterJob from '../tools/productivity/jobs/writing jobs/ContentWriterJob';
import ProposalWriterJob from '../tools/productivity/jobs/writing jobs/ProposalWriterJob';
import TechnicalWriterJob from '../tools/productivity/jobs/writing jobs/TechnicalWriterJob';
import MedicalWriterJob from '../tools/productivity/jobs/writing jobs/MedicalWriterJob';
import BusinessWriterJob from '../tools/productivity/jobs/writing jobs/BusinessWriterJob';
import SeoWriterJob from '../tools/productivity/jobs/writing jobs/SeoWriterJob';
import ProofreadingJob from '../tools/productivity/jobs/writing jobs/ProofreadingJob';
import TranslatorJob from '../tools/productivity/jobs/writing jobs/TranslatorJob';
import EducationJob from '../tools/productivity/jobs/education jobs/EducationJob';
import LanguageJob from '../tools/productivity/jobs/education jobs/LanguageJob';
import ProfessionJob from '../tools/productivity/jobs/education jobs/ProfessionJob';
import ResearcherJob from '../tools/productivity/jobs/education jobs/ResearcherJob';
import TeacherJob from '../tools/productivity/jobs/education jobs/TeacherJob';
import TrainerJob from '../tools/productivity/jobs/education jobs/TrainerJob';
import TutorJob from '../tools/productivity/jobs/education jobs/TutorJob';
import AutomationEngineerJob from '../tools/productivity/jobs/engineering jobs/AutomationEngineerJob';
import BiomedicalEngineerJob from '../tools/productivity/jobs/engineering jobs/BiomedicalEngineerJob';
import ChemicalEngineerJob from '../tools/productivity/jobs/engineering jobs/ChemicalEngineerJob';
import CivilEngineerJob from '../tools/productivity/jobs/engineering jobs/CivilEngineerJob';
import ElectricalEngineerJob from '../tools/productivity/jobs/engineering jobs/ElectricalEngineerJob';
import ElectronicEngineerJob from '../tools/productivity/jobs/engineering jobs/ElectronicEngineerJob';
import EngineerJob from '../tools/productivity/jobs/engineering jobs/EngineerJob';
import EnvironmentalEngineerJob from '../tools/productivity/jobs/engineering jobs/EnvironmentalEngineerJob';
import ManufacturingEngineerJob from '../tools/productivity/jobs/engineering jobs/ManufacturingEngineerJob';
import MechanicalEngineerJob from '../tools/productivity/jobs/engineering jobs/MechanicalEngineerJob';
import SoftwareEngineerJob from '../tools/productivity/jobs/engineering jobs/SoftwareEngineerJob';
import StructuralEngineerJob from '../tools/productivity/jobs/engineering jobs/StructuralEngineerJob';
import AccountantJob from '../tools/productivity/jobs/business jobs/AccountantJob';
import AdministrationJob from '../tools/productivity/jobs/business jobs/AdministrationJob';
import AnalysisJob from '../tools/productivity/jobs/business jobs/AnalysisJob';
import AnalyticsJob from '../tools/productivity/jobs/business jobs/AnalyticsJob';
import AuditJob from '../tools/productivity/jobs/business jobs/AuditJob';
import BankingJob from '../tools/productivity/jobs/business jobs/BankingJob';
import BlockchainJob from '../tools/productivity/jobs/business jobs/BlockchainJob';
import BookkeepingJob from '../tools/productivity/jobs/business jobs/BookkeepingJob';
import BusinessJob from '../tools/productivity/jobs/business jobs/BusinessJob';
import BusinessDevelopmentJob from '../tools/productivity/jobs/business jobs/BusinessDevelopmentJob';
import CommunicationJob from '../tools/productivity/jobs/business jobs/CommunicationJob';
import ConsultantJob from '../tools/productivity/jobs/business jobs/ConsultantJob';
import CounselingJob from '../tools/productivity/jobs/business jobs/CounselingJob';
import CryptoJob from '../tools/productivity/jobs/business jobs/Crypto';
import CustomerSupportJob from '../tools/productivity/jobs/business jobs/CustomerSupportJob';
import DataAnalystJob from '../tools/productivity/jobs/business jobs/DataAnalystJob';
import DataAnalyticsJob from '../tools/productivity/jobs/business jobs/DataAnalyticsJob';
import DataEntryJob from '../tools/productivity/jobs/business jobs/DataEntryJob';
import DataModelingJob from '../tools/productivity/jobs/business jobs/DataModelingJob';
import EntrepreneurshipJob from '../tools/productivity/jobs/business jobs/EntrepreneurshipJob';
import FinanceJob from '../tools/productivity/jobs/business jobs/FinanceJob';
import HumanResourceJob from '../tools/productivity/jobs/business jobs/HumanResource';
import JournalistJob from '../tools/productivity/jobs/business jobs/JournalistJob';
import LegalJob from '../tools/productivity/jobs/business jobs/LegalJob';
import LogisticsJob from '../tools/productivity/jobs/business jobs/LogisticsJob';
import ManagerJob from '../tools/productivity/jobs/business jobs/ManagerJob';
import MicrosoftJob from '../tools/productivity/jobs/business jobs/MicrosoftJob';
import OperationJob from '../tools/productivity/jobs/business jobs/OperationJob';
import OracleJob from '../tools/productivity/jobs/business jobs/OracleJob';
import PayrollJob from '../tools/productivity/jobs/business jobs/PayrollJob';
import PersonalAssistantJob from '../tools/productivity/jobs/business jobs/PersonalAssistantJob';
import ProductManagerJob from '../tools/productivity/jobs/business jobs/ProductManagerJob';
import ProjectManagerJob from '../tools/productivity/jobs/business jobs/ProjectManagerJob';
import QualityAnalystJob from '../tools/productivity/jobs/business jobs/QualityAnalystJob';
import QualityManagerJob from '../tools/productivity/jobs/business jobs/QualityManagerJob';
import RecruitingJob from '../tools/productivity/jobs/business jobs/RecruitingJob';
import ResearchJob from '../tools/productivity/jobs/business jobs/ResearchJob';
import SalesJob from '../tools/productivity/jobs/business jobs/SalesJob';
import SapJob from '../tools/productivity/jobs/business jobs/SapJob';
import StartupJob from '../tools/productivity/jobs/business jobs/StartupJob';
import SupplyChainJob from '../tools/productivity/jobs/business jobs/SupplyChainJob';
import SupportJob from '../tools/productivity/jobs/business jobs/SupportJob';
import TaxesJob from '../tools/productivity/jobs/business jobs/TaxesJob';
import TechnicalSupportJob from '../tools/productivity/jobs/business jobs/TechnicalSupportJob';
import TradingJob from '../tools/productivity/jobs/business jobs/TradingJob';
import TypingJob from '../tools/productivity/jobs/business jobs/TypingJob';
import VirtualAssistantJob from '../tools/productivity/jobs/business jobs/VirtualAssistant';
import VolunteerJob from '../tools/productivity/jobs/business jobs/VolunteerJob';
import CardiologyJob from '../tools/productivity/jobs/lifestyle jobs/CardiologyJob';
import DermatologistJob from '../tools/productivity/jobs/lifestyle jobs/DermatologistJob';
import DietitianJob from '../tools/productivity/jobs/lifestyle jobs/DietitianJob';
import DoctorJob from '../tools/productivity/jobs/lifestyle jobs/DoctorJob';
import GastroenterologyJob from '../tools/productivity/jobs/lifestyle jobs/GastroenterologyJob';
import LabJob from '../tools/productivity/jobs/lifestyle jobs/LabJob';
import LabScientistJob from '../tools/productivity/jobs/lifestyle jobs/LabScientistJob';
import LabTechnicianJob from '../tools/productivity/jobs/lifestyle jobs/LabTechnicianJob';
import LabTechnologistJob from '../tools/productivity/jobs/lifestyle jobs/LabTechnologist';
import MedicalJob from '../tools/productivity/jobs/lifestyle jobs/MedicalJob';
import MedicalAssistantJob from '../tools/productivity/jobs/lifestyle jobs/MedicalAssistantJob';
import MedicalTechnicianJob from '../tools/productivity/jobs/lifestyle jobs/MedicalTechnicianJob';
import NeurologistJob from '../tools/productivity/jobs/lifestyle jobs/NeurologistJob';
import NeuroradiologistJob from '../tools/productivity/jobs/lifestyle jobs/NeuroradiologistJob';
import NurseJob from '../tools/productivity/jobs/lifestyle jobs/NurseJob';
import NutritionJob from '../tools/productivity/jobs/lifestyle jobs/NutritionJob';
import OncologyJob from '../tools/productivity/jobs/lifestyle jobs/OncologyJob';
import OrthopaedicsJob from '../tools/productivity/jobs/lifestyle jobs/OrthopaedicsJob';
import PathologistJob from '../tools/productivity/jobs/lifestyle jobs/PathologistJob';
import PharmacyJob from '../tools/productivity/jobs/lifestyle jobs/PharmacyJob';
import PhysicianJob from '../tools/productivity/jobs/lifestyle jobs/PhysicianJob';
import PsychiatristJob from '../tools/productivity/jobs/lifestyle jobs/PsychiatristJob';
import RadiologyJob from '../tools/productivity/jobs/lifestyle jobs/RadiologyJob';
import TherapistJob from '../tools/productivity/jobs/lifestyle jobs/TherapistJob';
import YogaJob from '../tools/productivity/jobs/lifestyle jobs/YogaJob';

const JobsRouter = () => {
  return (
    <Routes>
      <Route path="/productivity/developer-jobs" element={<SidebarLayout><DeveloperJob /></SidebarLayout>} />
      <Route path="/productivity/web-developer-jobs" element={<SidebarLayout><WebDeveloperJob /></SidebarLayout>} />
      <Route path="/productivity/app-developer-jobs" element={<SidebarLayout><AppDeveloperJob /></SidebarLayout>} />
      <Route path="/productivity/react-js-jobs" element={<SidebarLayout><ReactJs /></SidebarLayout>} />
      <Route path="/productivity/react-native-jobs" element={<SidebarLayout><ReactNativeJob /></SidebarLayout>} />
      <Route path="/productivity/java-jobs" element={<SidebarLayout><JavaJob /></SidebarLayout>} />
      <Route path="/productivity/angular-jobs" element={<SidebarLayout><AngularJob /></SidebarLayout>} />
      <Route path="/productivity/css-jobs" element={<SidebarLayout><CssJob /></SidebarLayout>} />
      <Route path="/productivity/html-jobs" element={<SidebarLayout><HtmlJob /></SidebarLayout>} />
      <Route path="/productivity/next-js-jobs" element={<SidebarLayout><NextJsJob /></SidebarLayout>} />
      <Route path="/productivity/node-js-jobs" element={<SidebarLayout><NodeJsJob /></SidebarLayout>} />
      <Route path="/productivity/wordpress-jobs" element={<SidebarLayout><WordpressJob /></SidebarLayout>} />
      <Route path="/productivity/python-jobs" element={<SidebarLayout><PythonJob /></SidebarLayout>} />
      <Route path="/productivity/data-science-jobs" element={<SidebarLayout><DataScienceJob /></SidebarLayout>} />
      <Route path="/productivity/artificial-intelligence-jobs" element={<SidebarLayout><ArtificialIntelligenceJob /></SidebarLayout>} />
      <Route path="/productivity/deep-learning-jobs" element={<SidebarLayout><DeepLearningJob /></SidebarLayout>} />

      <Route path="/productivity/machine-learning-jobs" element={<SidebarLayout><MachineLearningJob /></SidebarLayout>} />
      <Route path="/productivity/data-analysis-jobs" element={<SidebarLayout><DataAnalysisJob /></SidebarLayout>} />
      <Route path="/productivity/ios-jobs" element={<SidebarLayout><IosJob /></SidebarLayout>} />
      <Route path="/productivity/flutter-jobs" element={<SidebarLayout><FlutterJob /></SidebarLayout>} />
      <Route path="/productivity/android-jobs" element={<SidebarLayout><AndroidJob /></SidebarLayout>} />
      <Route path="/productivity/kotlin-jobs" element={<SidebarLayout><KotlinJob /></SidebarLayout>} />
      <Route path="/productivity/swift-jobs" element={<SidebarLayout><SwiftJob /></SidebarLayout>} />
      <Route path="/productivity/unreal-engine-jobs" element={<SidebarLayout><UnrealEngineJob /></SidebarLayout>} />
      <Route path="/productivity/unity-jobs" element={<SidebarLayout><UnityJob /></SidebarLayout>} />
      <Route path="/productivity/game-developer-jobs" element={<SidebarLayout><GameDeveloperJob /></SidebarLayout>} />
      <Route path="/productivity/database-jobs" element={<SidebarLayout><DatabaseJob /></SidebarLayout>} />
      <Route path="/productivity/sql-jobs" element={<SidebarLayout><SqlJob /></SidebarLayout>} />
      <Route path="/productivity/mysql-jobs" element={<SidebarLayout><MySqlJob /></SidebarLayout>} />
      <Route path="/productivity/mongodb-jobs" element={<SidebarLayout><MongoDBJob /></SidebarLayout>} />
      <Route path="/productivity/qa-engineer-jobs" element={<SidebarLayout><QaEngineerJob /></SidebarLayout>} />
      <Route path="/productivity/testing-jobs" element={<SidebarLayout><TestingJob /></SidebarLayout>} />
      <Route path="/productivity/backend-jobs" element={<SidebarLayout><BackendJob /></SidebarLayout>} />

      <Route path="/productivity/frontend-jobs" element={<SidebarLayout><FrontendJob /></SidebarLayout>} />
      <Route path="/productivity/full-stack-jobs" element={<SidebarLayout><FullStackJob /></SidebarLayout>} />
      <Route path="/productivity/system-architect-jobs" element={<SidebarLayout><SystemArchitectJob/></SidebarLayout>} />
      <Route path="/productivity/solution-architect-jobs" element={<SidebarLayout><SolutionArchitectJob/></SidebarLayout>} />
      <Route path="/productivity/aws-jobs" element={<SidebarLayout><AwsJob/></SidebarLayout>} />
      <Route path="/productivity/azure-jobs" element={<SidebarLayout><AzureJob/></SidebarLayout>} />
      <Route path="/productivity/cloud-engineer-jobs" element={<SidebarLayout><CloudEngineerJob/></SidebarLayout>} />
      <Route path="/productivity/dotnet-jobs" element={<SidebarLayout><DotnetJob/></SidebarLayout>} />
      <Route path="/productivity/network-engineer-jobs" element={<SidebarLayout><NetworkEngineerJob/></SidebarLayout>} />
      <Route path="/productivity/cyber-security-jobs" element={<SidebarLayout><CyberSecurityJob/></SidebarLayout>} />
      <Route path="/productivity/data-scientist-jobs" element={<SidebarLayout><DataScientistJob/></SidebarLayout>} />
      <Route path="/productivity/designer-jobs" element={<SidebarLayout><DesignerJob/></SidebarLayout>} />
      <Route path="/productivity/creative-jobs" element={<SidebarLayout><CreativeJob/></SidebarLayout>} />
      <Route path="/productivity/game-designer-jobs" element={<SidebarLayout><GameDesignerJob/></SidebarLayout>} />
      <Route path="/productivity/web-designer-jobs" element={<SidebarLayout><WebDesignerJob/></SidebarLayout>} />
      <Route path="/productivity/interior-designer-jobs" element={<SidebarLayout><InteriorDesignerJob/></SidebarLayout>} />
      <Route path="/productivity/ui-designer-jobs" element={<SidebarLayout><UiDesignerJob/></SidebarLayout>} />
      <Route path="/productivity/ux-designer-jobs" element={<SidebarLayout><UxDesignerJob/></SidebarLayout>} />
      <Route path="/productivity/ui-ux-jobs" element={<SidebarLayout><UiUxJob/></SidebarLayout>} />

      <Route path="/productivity/architect-jobs" element={<SidebarLayout><ArchitectJob/></SidebarLayout>} />
      <Route path="/productivity/3d-jobs" element={<SidebarLayout><ThreeDJob/></SidebarLayout>} />
      <Route path="/productivity/graphic-designer-jobs" element={<SidebarLayout><GraphicDesignerJob/></SidebarLayout>} />
      <Route path="/productivity/figma-jobs" element={<SidebarLayout><FigmaJob/></SidebarLayout>} />
      <Route path="/productivity/adobe-jobs" element={<SidebarLayout><AdobeJob/></SidebarLayout>} />
      <Route path="/productivity/merchandise-jobs" element={<SidebarLayout><MerchandiseJob/></SidebarLayout>} />
      <Route path="/productivity/animation-jobs" element={<SidebarLayout><AnimationJob/></SidebarLayout>} />
      <Route path="/productivity/video-editor-jobs" element={<SidebarLayout><VideoEditorJob/></SidebarLayout>} />
      <Route path="/productivity/music-jobs" element={<SidebarLayout><MusicJob/></SidebarLayout>} />
      <Route path="/productivity/audio-jobs" element={<SidebarLayout><AudioJob/></SidebarLayout>} />
      <Route path="/productivity/fashion-designer-jobs" element={<SidebarLayout><FashionDesignerJob/></SidebarLayout>} />
      <Route path="/productivity/marketing-jobs" element={<SidebarLayout><MarketingJob/></SidebarLayout>} />
      <Route path="/productivity/branding-jobs" element={<SidebarLayout><BrandingJob/></SidebarLayout>} />
      <Route path="/productivity/advertising-jobs" element={<SidebarLayout><AdvertisingJob/></SidebarLayout>} />
      <Route path="/productivity/facebook-ads-jobs" element={<SidebarLayout><FacebookAdsJob/></SidebarLayout>} />
      <Route path="/productivity/google-ads-jobs" element={<SidebarLayout><GoogleAdsJob/></SidebarLayout>} />
      <Route path="/productivity/social-media-jobs" element={<SidebarLayout><SocialMediaJob/></SidebarLayout>} />
      <Route path="/productivity/public-relation-jobs" element={<SidebarLayout><PublicRelationJob/></SidebarLayout>} />


      <Route path="/productivity/influencer-jobs" element={<SidebarLayout><InfluencerJob/></SidebarLayout>} />
      <Route path="/productivity/affiliate-jobs" element={<SidebarLayout><AffiliateJob/></SidebarLayout>} />
      <Route path="/productivity/seo-jobs" element={<SidebarLayout><SeoJob/></SidebarLayout>} />
      <Route path="/productivity/content-jobs" element={<SidebarLayout><ContentJob/></SidebarLayout>} />
      <Route path="/productivity/email-jobs" element={<SidebarLayout><EmailJob/></SidebarLayout>} />
      <Route path="/productivity/ecommerce-jobs" element={<SidebarLayout><EcommerceJob/></SidebarLayout>} />
      <Route path="/productivity/video-production-jobs" element={<SidebarLayout><VideoProductionJob/></SidebarLayout>} />
      <Route path="/productivity/writer-jobs" element={<SidebarLayout><WriterJob/></SidebarLayout>} />
      <Route path='/productivity/writing-jobs' element={<SidebarLayout><WritingJob/></SidebarLayout>} />
      <Route path='/productivity/content-writer-jobs' element={<SidebarLayout><ContentWriterJob/></SidebarLayout>} />
      <Route path='/productivity/proposal-writer-jobs' element={<SidebarLayout><ProposalWriterJob/></SidebarLayout>} />
      <Route path='/productivity/technical-writer-jobs' element={<SidebarLayout><TechnicalWriterJob/></SidebarLayout>} />
      <Route path='/productivity/medical-writer-jobs' element={<SidebarLayout><MedicalWriterJob/></SidebarLayout>} />
      <Route path='/productivity/business-writer-jobs' element={<SidebarLayout><BusinessWriterJob/></SidebarLayout>} />
      <Route path='/productivity/seo-writer-jobs' element={<SidebarLayout><SeoWriterJob/></SidebarLayout>} />
      <Route path='/productivity/proofreading-jobs' element={<SidebarLayout><ProofreadingJob/></SidebarLayout>} />
      <Route path='/productivity/translator-jobs' element={<SidebarLayout><TranslatorJob/></SidebarLayout>} />

      <Route path='/productivity/education-jobs' element={<SidebarLayout><EducationJob/></SidebarLayout>} />
      <Route path='/productivity/language-jobs' element={<SidebarLayout><LanguageJob/></SidebarLayout>} />
      <Route path='/productivity/profession-jobs' element={<SidebarLayout><ProfessionJob/></SidebarLayout>} />
      <Route path='/productivity/researcher-jobs' element={<SidebarLayout><ResearcherJob/></SidebarLayout>} />
      <Route path='/productivity/teacher-jobs' element={<SidebarLayout><TeacherJob/></SidebarLayout>} />
      <Route path='/productivity/trainer-jobs' element={<SidebarLayout><TrainerJob/></SidebarLayout>} />
      <Route path='/productivity/tutor-jobs' element={<SidebarLayout><TutorJob/></SidebarLayout>} />

      <Route path='/productivity/automation-engineer-jobs' element={<SidebarLayout><AutomationEngineerJob/></SidebarLayout>} />
      <Route path='/productivity/biomedical-engineer-jobs' element={<SidebarLayout><BiomedicalEngineerJob/></SidebarLayout>} />
      <Route path='/productivity/chemical-engineer-jobs' element={<SidebarLayout><ChemicalEngineerJob/></SidebarLayout>} />
      <Route path='/productivity/civil-engineer-jobs' element={<SidebarLayout><CivilEngineerJob/></SidebarLayout>} />
      <Route path='/productivity/electrical-engineer-jobs' element={<SidebarLayout><ElectricalEngineerJob/></SidebarLayout>} />
      <Route path='/productivity/electronic-engineer-jobs' element={<SidebarLayout><ElectronicEngineerJob/></SidebarLayout>} />
      <Route path='/productivity/engineer-jobs' element={<SidebarLayout><EngineerJob/></SidebarLayout>} />
      <Route path='/productivity/environmental-engineer-jobs' element={<SidebarLayout><EnvironmentalEngineerJob/></SidebarLayout>} />
      <Route path='/productivity/manufacturing-engineer-jobs' element={<SidebarLayout><ManufacturingEngineerJob/></SidebarLayout>} />
      <Route path='/productivity/mechanical-engineer-jobs' element={<SidebarLayout><MechanicalEngineerJob/></SidebarLayout>} />
      <Route path='/productivity/software-engineer-jobs' element={<SidebarLayout><SoftwareEngineerJob/></SidebarLayout>} />
      <Route path='/productivity/structural-engineer-jobs' element={<SidebarLayout><StructuralEngineerJob/></SidebarLayout>} />

      <Route path='/productivity/accountant-jobs' element={<SidebarLayout><AccountantJob/></SidebarLayout>} />
      <Route path='/productivity/administration-jobs' element={<SidebarLayout><AdministrationJob/></SidebarLayout>} />
      <Route path='/productivity/analysis-jobs' element={<SidebarLayout><AnalysisJob/></SidebarLayout>} />
      <Route path='/productivity/analytics-jobs' element={<SidebarLayout><AnalyticsJob/></SidebarLayout>} />
      <Route path='/productivity/audit-jobs' element={<SidebarLayout><AuditJob/></SidebarLayout>} />
      <Route path='/productivity/banking-jobs' element={<SidebarLayout><BankingJob/></SidebarLayout>} />
      <Route path='/productivity/blockchain-jobs' element={<SidebarLayout><BlockchainJob/></SidebarLayout>} />
      <Route path='/productivity/bookkeeping-jobs' element={<SidebarLayout><BookkeepingJob/></SidebarLayout>} />
      <Route path='/productivity/business-jobs' element={<SidebarLayout><BusinessJob/></SidebarLayout>} />
      <Route path='/productivity/business-development-jobs' element={<SidebarLayout><BusinessDevelopmentJob/></SidebarLayout>} />
      <Route path='/productivity/communication-jobs' element={<SidebarLayout><CommunicationJob/></SidebarLayout>} />
      <Route path='/productivity/consultant-jobs' element={<SidebarLayout><ConsultantJob/></SidebarLayout>} />
      <Route path='/productivity/counseling-jobs' element={<SidebarLayout><CounselingJob/></SidebarLayout>} />
      <Route path='/productivity/crypto-jobs' element={<SidebarLayout><CryptoJob/></SidebarLayout>} />
      <Route path='/productivity/customer-support-jobs' element={<SidebarLayout><CustomerSupportJob/></SidebarLayout>} />
      <Route path='/productivity/data-analyst-jobs' element={<SidebarLayout><DataAnalystJob/></SidebarLayout>} />
      <Route path='/productivity/data-analytics-jobs' element={<SidebarLayout><DataAnalyticsJob/></SidebarLayout>} />
      <Route path='/productivity/data-entry-jobs' element={<SidebarLayout><DataEntryJob/></SidebarLayout>} />
      <Route path='/productivity/data-modeling-jobs' element={<SidebarLayout><DataModelingJob/></SidebarLayout>} />
      <Route path='/productivity/entrepreneurship-jobs' element={<SidebarLayout><EntrepreneurshipJob/></SidebarLayout>} />
      <Route path='/productivity/finance-jobs' element={<SidebarLayout><FinanceJob/></SidebarLayout>} />
      <Route path='/productivity/human-resource-jobs' element={<SidebarLayout><HumanResourceJob/></SidebarLayout>} />
      <Route path='/productivity/journalist-jobs' element={<SidebarLayout><JournalistJob/></SidebarLayout>} />
      <Route path='/productivity/legal-jobs' element={<SidebarLayout><LegalJob/></SidebarLayout>} />
      <Route path='/productivity/logistics-jobs' element={<SidebarLayout><LogisticsJob/></SidebarLayout>} />
      <Route path='/productivity/manager-jobs' element={<SidebarLayout><ManagerJob/></SidebarLayout>} />
      <Route path='/productivity/microsoft-jobs' element={<SidebarLayout><MicrosoftJob/></SidebarLayout>} />
      <Route path='/productivity/operations-jobs' element={<SidebarLayout><OperationJob/></SidebarLayout>} />
      <Route path='/productivity/oracle-jobs' element={<SidebarLayout><OracleJob/></SidebarLayout>} />
      <Route path='/productivity/payroll-jobs' element={<SidebarLayout><PayrollJob/></SidebarLayout>} />
      <Route path='/productivity/personal-assistant-jobs' element={<SidebarLayout><PersonalAssistantJob/></SidebarLayout>} />
      <Route path='/productivity/product-manager-jobs' element={<SidebarLayout><ProductManagerJob/></SidebarLayout>} />
      <Route path='/productivity/project-manager-jobs' element={<SidebarLayout><ProjectManagerJob/></SidebarLayout>} />
      <Route path='/productivity/quality-analyst-jobs' element={<SidebarLayout><QualityAnalystJob/></SidebarLayout>} />
      <Route path='/productivity/quality-manager-jobs' element={<SidebarLayout><QualityManagerJob/></SidebarLayout>} />
      <Route path='/productivity/recruiting-jobs' element={<SidebarLayout><RecruitingJob/></SidebarLayout>} />
      <Route path='/productivity/research-jobs' element={<SidebarLayout><ResearchJob/></SidebarLayout>} />
      <Route path='/productivity/sales-jobs' element={<SidebarLayout><SalesJob/></SidebarLayout>} />
      <Route path='/productivity/sap-jobs' element={<SidebarLayout><SapJob/></SidebarLayout>} />
      <Route path='/productivity/startup-jobs' element={<SidebarLayout><StartupJob/></SidebarLayout>} />
      <Route path='/productivity/supply-chain-jobs' element={<SidebarLayout><SupplyChainJob/></SidebarLayout>} />
      <Route path='/productivity/support-jobs' element={<SidebarLayout><SupportJob/></SidebarLayout>} />
      <Route path='/productivity/taxes-jobs' element={<SidebarLayout><TaxesJob/></SidebarLayout>} />
      <Route path='/productivity/technical-support-jobs' element={<SidebarLayout><TechnicalSupportJob/></SidebarLayout>} />
      <Route path='/productivity/trading-jobs' element={<SidebarLayout><TradingJob/></SidebarLayout>} />
      <Route path='/productivity/typing-jobs' element={<SidebarLayout><TypingJob/></SidebarLayout>} />
      <Route path='/productivity/virtual-assistant-jobs' element={<SidebarLayout><VirtualAssistantJob/></SidebarLayout>} />
      <Route path='/productivity/volunteer-jobs' element={<SidebarLayout><VolunteerJob/></SidebarLayout>} />
      
      <Route path='/productivity/cardiology-jobs' element={<SidebarLayout><CardiologyJob/></SidebarLayout>} />
      <Route path='/productivity/dermatologist-jobs' element={<SidebarLayout><DermatologistJob/></SidebarLayout>} />
      <Route path='/productivity/dietitian-jobs' element={<SidebarLayout><DietitianJob/></SidebarLayout>} />
      <Route path='/productivity/doctor-jobs' element={<SidebarLayout><DoctorJob/></SidebarLayout>} />
      <Route path='/productivity/gastroenterology-jobs' element={<SidebarLayout><GastroenterologyJob/></SidebarLayout>} />
      <Route path='/productivity/lab-jobs' element={<SidebarLayout><LabJob/></SidebarLayout>} />
      <Route path='/productivity/lab-scientist-jobs' element={<SidebarLayout><LabScientistJob/></SidebarLayout>} />
      <Route path='/productivity/lab-technician-jobs' element={<SidebarLayout><LabTechnicianJob/></SidebarLayout>} />
      <Route path='/productivity/lab-technologist-jobs' element={<SidebarLayout><LabTechnologistJob/></SidebarLayout>} />
      <Route path='/productivity/medical-jobs' element={<SidebarLayout><MedicalJob/></SidebarLayout>} />
      <Route path='/productivity/medical-assistant-jobs' element={<SidebarLayout><MedicalAssistantJob/></SidebarLayout>} />
      <Route path='/productivity/medical-technician-jobs' element={<SidebarLayout><MedicalTechnicianJob/></SidebarLayout>} />
      <Route path='/productivity/neurologist-jobs' element={<SidebarLayout><NeurologistJob/></SidebarLayout>} />
      <Route path='/productivity/neuroradiologist-jobs' element={<SidebarLayout><NeuroradiologistJob/></SidebarLayout>} />
      <Route path='/productivity/nurse-jobs' element={<SidebarLayout><NurseJob/></SidebarLayout>} />
      <Route path='/productivity/nutrition-jobs' element={<SidebarLayout><NutritionJob/></SidebarLayout>} />
      <Route path='/productivity/oncology-jobs' element={<SidebarLayout><OncologyJob/></SidebarLayout>} />
      <Route path='/productivity/orthopaedics-jobs' element={<SidebarLayout><OrthopaedicsJob/></SidebarLayout>} />
      <Route path='/productivity/pathologist-jobs' element={<SidebarLayout><PathologistJob/></SidebarLayout>} />
      <Route path='/productivity/pharmacy-jobs' element={<SidebarLayout><PharmacyJob/></SidebarLayout>} />
      <Route path='/productivity/physician-jobs' element={<SidebarLayout><PhysicianJob/></SidebarLayout>} />
      <Route path='/productivity/psychiatrist-jobs' element={<SidebarLayout><PsychiatristJob/></SidebarLayout>} />
      <Route path='/productivity/radiology-jobs' element={<SidebarLayout><RadiologyJob/></SidebarLayout>} />
      <Route path='/productivity/therapist-jobs' element={<SidebarLayout><TherapistJob/></SidebarLayout>} />
      <Route path='/productivity/yoga-jobs' element={<SidebarLayout><YogaJob/></SidebarLayout>} />
    </Routes>
  );
};

export default JobsRouter;
