import React, { useState } from 'react'
import classes from "./styles/SimpleInterest.module.css";
import RightSidebar from '../../../common/RightSidebar';
import { Helmet } from 'react-helmet';
import { FullWidthWiraaAds } from '../../../common/Ads/FullWidthWiraaAds';

const PercentCalculator = () => {
    const [inputValue, setInputValue] = useState();
    const [interestDetails, setInterestDetails] = useState(null);
    const [addInput, setAddInput] = useState({"revenue" : ""})
    const [errorMessage, setErrorMessage] = useState();


    const handleCalculation = () => {
      const cost = parseFloat(inputValue);
      const revenue = parseFloat(addInput.revenue);
  
      if (isNaN(cost) || isNaN(revenue) || cost < 0 || revenue < 0) {
          setErrorMessage("Please enter valid positive numbers.");
          setInterestDetails(null);
          return;
      }
  
      setErrorMessage("");
  
      const amount = revenue - cost;
      const percent = (amount / cost) * 100;
  
      setInterestDetails({
          amount: amount.toFixed(2),
          percent: percent.toFixed(1),
      });
  };
  
  
  
    
      const handleClearBtn = () => {
        setInputValue("");
        setAddInput({"revenue" : ""});
        setInterestDetails(null);
        setErrorMessage("");
      }  

  return (
    <div className={classes.page}>
      <Helmet>
                <title>Percent Calculator - Wiraa tools</title>
                <meta name="description" content="Use our Percentage Calculator to determine profit and loss percentages effortlessly. Get accurate results for your business and financial calculations in seconds!" />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Percent Calculator</h1>
        <p className={classes.subTitle}>Quick & Easy Percentage Calculator for Profit and Loss Calculations</p>
      <div className={classes.innerCon}>
        <div className={classes.inputCon}>
            <p className={classes.inputplaceholder}>Cost</p>
            <input value={inputValue} onChange={(e) => setInputValue(e.target.value)} className={classes.inputfield} placeholder='Enter amount'/>
            {errorMessage && <p className={classes.errorMsg}>{errorMessage}</p>}
        </div>
        <div className={classes.additionalInputCon}>
                    <div className={classes.additionalInputDiv}>
                        <p className={classes.addplaceholder}>Revenue</p>
                        <input value={addInput.revenue} onChange={(e) => setAddInput({ ...addInput, revenue: e.target.value })} className={classes.addInputfield} placeholder='Enter amount'/>
                    </div>
                </div>
        <div className={classes.btnCon}>
            <button className={classes.convertBtn} onClick={handleCalculation}>Calculate</button>
            <button className={classes.clearBtn} onClick={handleClearBtn}>Clear</button>
        </div>
        {interestDetails ? <div className={classes.outputSalaryCon}>
                    <div>
                      <p className={classes.outputplaceholder}>Profit/Loss Amount:</p>
                      <p className={classes.outputfield}>{interestDetails.amount}</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Profit/Loss Percent:</p>
                      <p className={classes.outputfield}>{interestDetails.percent}%</p>
                    </div>  
        </div> : <div className={classes.outputSalaryCon}>
        <div>
                      <p className={classes.outputplaceholder}>Profit/Loss Amount:</p>
                      <p className={classes.outputfield}>0</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Profit/Loss Percent:</p>
                      <p className={classes.outputfield}>0%</p>
                    </div>
          </div>}
      </div>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is a Percentage Calculator?</h3>
        <p>In mathematics, a percentage is a quantity or ratio that is a fraction of 100. It is one of several approaches for representing a dimensionless connection between two numbers; others include ratios, fractions, and decimals. Percentages are commonly represented by the symbol "%" following the number. They can also be represented by adding "percent" or "pct" after the integer. For example, 35% is equivalent to the decimal 0.35. Percentages are computed by multiplying the value of a ratio by 100. For example, if 25 out of 50 students in a classroom are male,25/50 = 0.5. The value of the ratio is therefore 0.5, and multiplying this by 100 yields:</p>
        <p>0.5 × 100 = 50</p>
        <p>In other words, the ratio of 25 males to students in the classroom is equivalent to 50% of students in the classroom being male.</p>
        <h3>Percentage formula</h3>
        <p>Although the percentage formula can be written in different forms, it is essentially an algebraic equation involving three values.</p>
        <p>P × V1 = V2</p>
        <p>P is the percentage, V1 is the first value that the percentage will modify, and V2 is the result of the percentage operating on V1. The calculator provided automatically converts the input percentage into a decimal to compute the solution. However, if solving for the percentage, the value returned will be the actual percentage, not its decimal representation.</p>
        <p>If solving manually, the formula requires the percentage in decimal form, so the solution for P needs to be multiplied by 100 to convert it to a percent. This is essentially what the calculator above does, except that it accepts inputs in percent rather than decimal form.</p>

        <h3>Benefits of Using a Percentage Calculator</h3>
        <ol>
          <li><b>Quick and Accurate Calculations:</b> Instantly calculates percentages, eliminating the chances of errors in manual calculations.</li>
          <li><b>Saves Time:</b> Speeds up percentage calculations, especially useful when dealing with multiple transactions or data points.</li>
          <li><b>Easy to Use:</b> Simple to operate, making it accessible for anyone, regardless of their mathematical expertise.</li>
          <li><b>Improves Financial Decision-Making:</b> Allows businesses and people to make more educated decisions on discounts, interest rates, tax calculations, and other topics.</li>
          <li><b>Versatile:</b> Calculates discounts, earnings, taxes, and growth rates in a variety of settings, including sales, finance, and education.</li>
        </ol>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default PercentCalculator;
