import React from 'react'
import classes from "./styles/Converters.module.css";
import { NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet";

const Productivity = () => {
  return (
    <div className={classes.Container}>
        <Helmet>
                <title>Top Productivity Tools to Maximize Efficiency and Streamline Your Workflow</title>
                <meta name="description" content="Discover the best productivity tools to boost your efficiency, improve your workflow, and achieve more every day." />
        </Helmet>
        <h1 className={classes.title}>Productivity Tools</h1>
        <p className={classes.subTitle}>Explore the Best Productivity Tools to Enhance Your Workflow and Maximize Efficiency Every Day.</p>

        <h3 className={classes.subHeadings}>Task Management</h3>
        <div className={classes.toolsContainer}>
          <NavLink to="/productivity/to-do-list" className={({ isActive }) => (isActive ? classes.active : "")}>To Do List</NavLink>
          <NavLink to="/productivity/online-notepad" className={({ isActive }) => (isActive ? classes.active : "")}>Online Notepad</NavLink>
          <NavLink to="/productivity/online-timer" className={({ isActive }) => (isActive ? classes.active : "")}>Online Timer</NavLink>
          <NavLink to="/productivity/online-stopwatch" className={({ isActive }) => (isActive ? classes.active : "")}>Online Stopwatch</NavLink>
        </div>

        <h3 className={classes.subHeadings}>Utility Tools</h3>
        <div className={classes.toolsContainer}>
          <NavLink to="/productivity/qr-code-generator" className={({ isActive }) => (isActive ? classes.active : "")}>QR Code Generator</NavLink>
          <NavLink to="/productivity/random-password-generator" className={({ isActive }) => (isActive ? classes.active : "")}>Random Password Generator</NavLink>
          <NavLink to="/productivity/password-strength-checker" className={({ isActive }) => (isActive ? classes.active : "")}>Password Strength Checker</NavLink>
        </div>
  
        <h3 className={classes.subHeadings}>Job Finders</h3>
        <div className={classes.toolsContainer}>
                <NavLink to="/productivity/developer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Developer Job Finder</NavLink>
                <NavLink to="/productivity/web-developer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Web Developer Job Finder</NavLink>
                <NavLink to="/productivity/app-developer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>App Developer Job Finder</NavLink>
                <NavLink to="/productivity/react-js-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>React Js Job Finder</NavLink>
                <NavLink to="/productivity/react-native-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>React Native Job Finder</NavLink>
                <NavLink to="/productivity/java-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Java Job Finder</NavLink>
                <NavLink to="/productivity/angular-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Angular Job Finder</NavLink>
                <NavLink to="/productivity/css-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>CSS Job Finder</NavLink>
                <NavLink to="/productivity/html-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Html Job Finder</NavLink>
                <NavLink to="/productivity/next-js-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Next Js Job Finder</NavLink>
                <NavLink to="/productivity/node-js-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Node Js Job Finder</NavLink>
                <NavLink to="/productivity/wordpress-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Wordpress Job Finder</NavLink>
                <NavLink to="/productivity/python-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Python Job Finder</NavLink>
                <NavLink to="/productivity/data-science-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Data Science Job Finder</NavLink>
                <NavLink to="/productivity/artificial-intelligence-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Artificial Intelligence Job Finder</NavLink>
                <NavLink to="/productivity/deep-learning-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Deep Learning Job Finder</NavLink>
                <NavLink to="/productivity/machine-learning-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Machine Learning Job Finder</NavLink>
                <NavLink to="/productivity/data-analysis-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Data Analysis Job Finder</NavLink>
                <NavLink to="/productivity/ios-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>IOS Job Finder</NavLink>
                <NavLink to="/productivity/flutter-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Flutter Job Finder</NavLink>
                <NavLink to="/productivity/android-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Android Job Finder</NavLink>
                <NavLink to="/productivity/kotlin-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Kotlin Job Finder</NavLink>
                <NavLink to="/productivity/swift-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Swift Job Finder</NavLink>
                <NavLink to="/productivity/unreal-engine-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Unreal Engine Job Finder</NavLink>
                <NavLink to="/productivity/unity-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Unity Job Finder</NavLink>
                <NavLink to="/productivity/game-developer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Game Developer Job Finder</NavLink>
                <NavLink to="/productivity/database-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Database Job Finder</NavLink>
                <NavLink to="/productivity/sql-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>SQL Job Finder</NavLink>
                <NavLink to="/productivity/mysql-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>MySQL Job Finder</NavLink>
                <NavLink to="/productivity/mongodb-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>MongoDB Job Finder</NavLink>
                <NavLink to="/productivity/qa-engineer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>QA Engineer Job Finder</NavLink>
                <NavLink to="/productivity/testing-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Testing Job Finder</NavLink>
                <NavLink to="/productivity/backend-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Backend Job Finder</NavLink>
                <NavLink to="/productivity/frontend-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Frontend Job Finder</NavLink>
                <NavLink to="/productivity/full-stack-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Full Stack Job Finder</NavLink>
                <NavLink to="/productivity/system-architect-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>System Architect Job Finder</NavLink>
                <NavLink to="/productivity/solution-architect-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Solution Architect Job Finder</NavLink>
                <NavLink to="/productivity/aws-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>AWS Job Finder</NavLink>
                <NavLink to="/productivity/azure-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Azure Job Finder</NavLink>
                <NavLink to="/productivity/cloud-engineer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Cloud Engineer Job Finder</NavLink>
                <NavLink to="/productivity/dotnet-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>DotNet Job Finder</NavLink>
                <NavLink to="/productivity/network-engineer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Network Engineer Job Finder</NavLink>
                <NavLink to="/productivity/cyber-security-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Cyber Security Job Finder</NavLink>
                <NavLink to="/productivity/data-scientist-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Data Scientist Job Finder</NavLink>

                <NavLink to="/productivity/designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Designer Job Finder</NavLink>
                <NavLink to="/productivity/creative-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Creative Job Finder</NavLink>
                <NavLink to="/productivity/game-designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Game Designer Job Finder</NavLink>
                <NavLink to="/productivity/web-designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Web Designer Job Finder</NavLink>
                <NavLink to="/productivity/interior-designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Interior Designer Job Finder</NavLink>
                <NavLink to="/productivity/ui-designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>UI Designer Job Finder</NavLink>
                <NavLink to="/productivity/ux-designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>UX Designer Job Finder</NavLink>
                <NavLink to="/productivity/ui-ux-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>UI/UX Job Finder</NavLink>
                <NavLink to="/productivity/architect-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Architect Job Finder</NavLink>
                <NavLink to="/productivity/3d-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>3D Job Finder</NavLink>
                <NavLink to="/productivity/graphic-designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Graphic Designer Job Finder</NavLink>
                <NavLink to="/productivity/figma-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Figma Job Finder</NavLink>
                <NavLink to="/productivity/adobe-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Adobe Job Finder</NavLink>
                <NavLink to="/productivity/merchandise-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Merchandise Job Finder</NavLink>
                <NavLink to="/productivity/animation-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Animation Job Finder</NavLink>
                <NavLink to="/productivity/video-editor-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Video Editor Job Finder</NavLink>
                <NavLink to="/productivity/music-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Music Job Finder</NavLink>
                <NavLink to="/productivity/audio-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Audio Job Finder</NavLink>
                <NavLink to="/productivity/fashion-designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Fashion Designer Job Finder</NavLink>
                
                <NavLink to="/productivity/marketing-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Marketing Job Finder</NavLink>
                <NavLink to="/productivity/branding-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Branding Job Finder</NavLink>
                <NavLink to="/productivity/advertising-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Advertising Job Finder</NavLink>
                <NavLink to="/productivity/facebook-ads-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Facebook Ads Job Finder</NavLink>
                <NavLink to="/productivity/google-ads-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Google Ads Job Finder</NavLink>
                <NavLink to="/productivity/social-media-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Social Media Job Finder</NavLink>
                <NavLink to="/productivity/public-relation-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Public Relation Job Finder</NavLink>
                <NavLink to="/productivity/influencer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Influencer Job Finder</NavLink>
                <NavLink to="/productivity/affiliate-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Affiliate Job Finder</NavLink>
                <NavLink to="/productivity/seo-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>SEO Job Finder</NavLink>
                <NavLink to="/productivity/content-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Content Job Finder</NavLink>
                <NavLink to="/productivity/email-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Email Job Finder</NavLink>
                <NavLink to="/productivity/ecommerce-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>eCommerce Job Finder</NavLink>
                <NavLink to="/productivity/video-production-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Video Production Job Finder</NavLink>

                <NavLink to="/productivity/writer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Writer Job Finder</NavLink>
                <NavLink to="/productivity/writing-jobs" className={({isActive}) => (isActive ? classes.active : "")}>Writing Job Finder</NavLink>
                <NavLink to='/productivity/content-writer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Content Writer Job Finder</NavLink>
                <NavLink to='/productivity/proposal-writer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Proposal Writer Job Finder</NavLink>
                <NavLink to='/productivity/technical-writer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Technical Writer Job Finder</NavLink>
                <NavLink to='/productivity/medical-writer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Medical Writer Job Finder</NavLink>
                <NavLink to='/productivity/business-writer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Business Writer Job Finder</NavLink>
                <NavLink to='/productivity/seo-writer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>SEO Writer Job Finder</NavLink>
                <NavLink to='/productivity/proofreading-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Proofreading Job Finder</NavLink>
                <NavLink to='/productivity/translator-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Translator Job Finder</NavLink>

                <NavLink to='/productivity/education-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Education Job Finder</NavLink>
                <NavLink to='/productivity/language-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Language Job Finder</NavLink>
                <NavLink to='/productivity/profession-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Profession Job Finder</NavLink>
                <NavLink to='/productivity/researcher-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Researcher Job Finder</NavLink>
                <NavLink to='/productivity/teacher-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Teacher Job Finder</NavLink>
                <NavLink to='/productivity/trainer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Trainer Job Finder</NavLink>
                <NavLink to='/productivity/tutor-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Tutor Job Finder</NavLink>
                
                <NavLink to='/productivity/automation-engineer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Automation Engineer Job Finder</NavLink>
                <NavLink to='/productivity/biomedical-engineer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Biomedical Engineer Job Finder</NavLink>
                <NavLink to='/productivity/chemical-engineer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Chemical Engineer Job Finder</NavLink>
                <NavLink to='/productivity/civil-engineer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Civil Engineer Job Finder</NavLink>
                <NavLink to='/productivity/electrical-engineer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Electrical Engineer Job Finder</NavLink>
                <NavLink to='/productivity/electronic-engineer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Electronic Engineer Job Finder</NavLink>
                <NavLink to='/productivity/engineer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Engineer Job Finder</NavLink>
                <NavLink to='/productivity/environmental-engineer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Environmental Engineer Job Finder</NavLink>
                <NavLink to='/productivity/manufacturing-engineer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Manufacturing Engineer Job Finder</NavLink>
                <NavLink to='/productivity/mechanical-engineer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Mechanical Engineer Job Finder</NavLink>
                <NavLink to='/productivity/software-engineer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Software Engineer Job Finder</NavLink>
                <NavLink to='/productivity/structural-engineer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Structural Engineer Job Finder</NavLink>

                <NavLink to='/productivity/accountant-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Accountant Job Finder</NavLink>
                <NavLink to='/productivity/administration-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Administration Job Finder</NavLink>
                <NavLink to='/productivity/analysis-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Analysis Job Finder</NavLink>
                <NavLink to='/productivity/analytics-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Analytics Job Finder</NavLink>
                <NavLink to='/productivity/audit-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Audit Job Finder</NavLink>
                <NavLink to='/productivity/banking-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Banking Job Finder</NavLink>
                <NavLink to='/productivity/blockchain-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Blockchain Job Finder</NavLink>
                <NavLink to='/productivity/bookkeeping-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Bookkeeping Job Finder</NavLink>
                <NavLink to='/productivity/business-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Business Job Finder</NavLink>
                <NavLink to='/productivity/business-development-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Business Development Job Finder</NavLink>
                <NavLink to='/productivity/communication-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Communication Job Finder</NavLink>
                <NavLink to='/productivity/consultant-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Consultant Job Finder</NavLink>
                <NavLink to='/productivity/counseling-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Counseling Job Finder</NavLink>
                <NavLink to='/productivity/crypto-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Crypto Job Finder</NavLink>
                <NavLink to='/productivity/customer-support-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Customer Support Job Finder</NavLink>
                <NavLink to='/productivity/data-analyst-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Data Analyst Job Finder</NavLink>
                <NavLink to='/productivity/data-analytics-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Data Analytics Job Finder</NavLink>
                <NavLink to='/productivity/data-entry-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Data Entry Job Finder</NavLink>
                <NavLink to='/productivity/data-modeling-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Data Modeling Job Finder</NavLink>
                <NavLink to='/productivity/entrepreneurship-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Entrepreneurship Job Finder</NavLink>
                <NavLink to='/productivity/finance-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Finance Job Finder</NavLink>
                <NavLink to='/productivity/human-resource-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Human Resource Job Finder</NavLink>
                <NavLink to='/productivity/journalist-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Journalist Job Finder</NavLink>
                <NavLink to='/productivity/legal-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Legal Job Finder</NavLink>

                <NavLink to='/productivity/logistics-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Logistics Job Finder</NavLink>
                <NavLink to='/productivity/manager-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Manager Job Finder</NavLink>
                <NavLink to='/productivity/microsoft-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Microsoft Job Finder</NavLink>
                <NavLink to='/productivity/operations-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Operations Job Finder</NavLink>
                <NavLink to='/productivity/oracle-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Oracle Job Finder</NavLink>
                <NavLink to='/productivity/payroll-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Payroll Job Finder</NavLink>
                <NavLink to='/productivity/personal-assistant-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Personal Assistant Job Finder</NavLink>
                <NavLink to='/productivity/product-manager-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Product Manager Job Finder</NavLink>
                <NavLink to='/productivity/project-manager-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Project Manager Job Finder</NavLink>
                <NavLink to='/productivity/quality-analyst-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Quality Analyst Job Finder</NavLink>
                <NavLink to='/productivity/quality-manager-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Quality Manager Job Finder</NavLink>
                <NavLink to='/productivity/recruiting-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Recruiting Job Finder</NavLink>
                <NavLink to='/productivity/research-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Research Job Finder</NavLink>
                <NavLink to='/productivity/sales-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Sales Job Finder</NavLink>
                <NavLink to='/productivity/sap-jobs' className={({isActive}) => (isActive ? classes.active : "")}>SAP Job Finder</NavLink>
                <NavLink to='/productivity/startup-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Startup Job Finder</NavLink>
                <NavLink to='/productivity/supply-chain-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Supply Chain Job Finder</NavLink>
                <NavLink to='/productivity/support-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Support Job Finder</NavLink>
                <NavLink to='/productivity/taxes-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Taxes Job Finder</NavLink>
                <NavLink to='/productivity/technical-support-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Technical Support Job Finder</NavLink>
                <NavLink to='/productivity/trading-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Trading Job Finder</NavLink>
                <NavLink to='/productivity/typing-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Typing Job Finder</NavLink>
                <NavLink to='/productivity/virtual-assistant-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Virtual Assistant Job Finder</NavLink>
                <NavLink to='/productivity/volunteer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Volunteer Job Finder</NavLink>
        
                <NavLink to='/productivity/cardiology-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Cardiology Job Finder</NavLink>
                <NavLink to='/productivity/dermatologist-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Dermatologist Job Finder</NavLink>
                <NavLink to='/productivity/dietitian-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Dietitian Job Finder</NavLink>
                <NavLink to='/productivity/doctor-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Doctor Job Finder</NavLink>
                <NavLink to='/productivity/gastroenterology-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Gastroenterology Job Finder</NavLink>
                <NavLink to='/productivity/lab-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Lab Job Finder</NavLink>
                <NavLink to='/productivity/lab-scientist-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Lab Scientist Job Finder</NavLink>
                <NavLink to='/productivity/lab-technician-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Lab Technician Job Finder</NavLink>
                <NavLink to='/productivity/lab-technologist-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Lab Technologist Job Finder</NavLink>
                <NavLink to='/productivity/medical-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Medical Job Finder</NavLink>
                <NavLink to='/productivity/medical-assistant-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Medical Assistant Job Finder</NavLink>
                <NavLink to='/productivity/medical-technician-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Medical Technician Job Finder</NavLink>
                <NavLink to='/productivity/neurologist-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Neurologist Job Finder</NavLink>
                <NavLink to='/productivity/neuroradiologist-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Neuroradiologist Job Finder</NavLink>
                <NavLink to='/productivity/neuroradiologist-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Neuroradiologist Job Finder</NavLink>
                <NavLink to='/productivity/nurse-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Nurse Job Finder</NavLink>
                <NavLink to='/productivity/nutrition-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Nutrition Job Finder</NavLink>
                <NavLink to='/productivity/oncology-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Oncology Job Finder</NavLink>
                <NavLink to='/productivity/orthopaedics-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Orthopaedics Job Finder</NavLink>
                <NavLink to='/productivity/pathologist-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Pathologist Job Finder</NavLink>
                <NavLink to='/productivity/pharmacy-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Pharmacy Job Finder</NavLink>
                <NavLink to='/productivity/physician-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Physician Job Finder</NavLink>
                <NavLink to='/productivity/psychiatrist-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Psychiatrist Job Finder</NavLink>
                <NavLink to='/productivity/radiology-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Radiology Job Finder</NavLink>
                <NavLink to='/productivity/therapist-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Therapist Job Finder</NavLink>
                <NavLink to='/productivity/yoga-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Yoga Job Finder</NavLink>
        </div>  
    </div>
  )
}

export default Productivity;
