import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const LabTechnicianJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Lab Technician Jobs</title>
                <meta name="description" content="Explore top lab technician job openings in healthcare, research, and diagnostic labs. Start your career in laboratory science today!" />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Lab Technician Job Finder</h1>
        <p className={classes.subTitle}>Discover Lab Technician Job Opportunities in Healthcare and Research</p>
      <JobFinder jobTitle={"Lab Technician"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default LabTechnicianJob;
