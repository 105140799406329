import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const AudioJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Audio Jobs</title>
                <meta name="description" content="Find the best audio jobs in the US and India. Connect with leading companies and advance your career with Audio Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Audio Job Finder</h1>
        <p className={classes.subTitle}>Explore Top Audio Job Opportunities to Create, Edit, and Master Sound</p>
      <JobFinder jobTitle={"Audio"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <p>Audio plays a crucial role in various industries, from entertainment and media to technology and gaming. Audio professionals are in charge of sound recording, mixing, production, and editing to provide high-quality sound experiences in music, movies, ads, podcasts, and more.</p>
        <h3>Career Paths in Audio</h3>
        <p>The audio industry offers multiple job opportunities such as:</p>
        <ul>
          <li><b>Audio Engineers:</b> Work with sound equipment to mix, edit, and improve audio quality.</li>
          <li><b>Sound Designers:</b> Create sound effects for animated movies, video games, and other media.</li>
          <li><b>Music Producers:</b> Work with composers and artists to oversee the recording and production of music.</li>
          <li><b>Voice-over Artists:</b> Provide character voices and narration for games, films, and ads.</li>
          <li><b>Audio editors:</b> Adjust and enhance the recorded sound to make it more impactful and clear.</li>
          <li><b>Podcasts Producers:</b> Manage the creative and technical facets of podcasting. </li>
        </ul>
        <h3>Salary & Job Opportunities</h3>
        <p>The salary in the audio industry varies based on expertise and location:</p>
        <ul>
          <li><b>In India:</b> Entry-level audio professionals can earn around ₹3–5 LPA, while experienced sound engineers and music producers can make ₹10 LPA or more.</li>
          <li><b>In the US:</b> Salaries range from $40,000 to $80,000 per year, with top professionals earning six figures in major media and entertainment companies.</li>
        </ul>
        <h3>The Future of Audio</h3>
        <p>As virtual reality (VR), gaming, podcasts, and streaming platforms gain popularity, there is an increasing need for high-quality audio. Immersive audio technologies and AI-driven sound design are changing the audio industry and opening up new career paths for audio professionals.</p>
        <h3>Conclusion</h3>
        <p>There are many appealing career options in the dynamic and developing industry of audio. Professionals in this field, whether they work in voice acting, sound design, or music creation, are essential in influencing how we experience sound in different media.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default AudioJob;
