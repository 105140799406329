import React, { useState } from 'react'
import classes from "./styles/HourlytoSalary.module.css";
import RightSidebar from '../../../common/RightSidebar';
import { Helmet } from 'react-helmet';
import { FullWidthWiraaAds } from '../../../common/Ads/FullWidthWiraaAds';

const HourlytoSalaryCalculator = () => {
    const [inputValue, setInputValue] = useState();
    const [addInput, setAddInput] = useState({"hour" : "8", "day" : "6"})
    const [outputValue, setOutputValue] = useState();
    const [errorMessage, setErrorMessage] = useState();


    const handleSalaryCalculation = () => {
        // Check if the input date is valid
        if (!inputValue || inputValue < 0) {
            setErrorMessage("Please enter a valid hourly wage.");
            setOutputValue("");
            return;
        }
    
        const { hour, day} = addInput;
    
        // Check if the additional inputs are valid numbers
        if (
            isNaN(hour) || hour < 0 ||
            isNaN(day) || day < 0 || day > 7
        ) {
            setErrorMessage("Please enter valid numbers for hours per day and days per week.");
            setOutputValue("");
            return;
        }
    
        setErrorMessage(""); // Clear any existing errors
        const weeklySalary = inputValue * hour * day; // Weekly salary
        const monthlySalary = (weeklySalary * 52) / 12;
        setOutputValue(monthlySalary);
    };
    
      const handleClearBtn = () => {
        setInputValue("");
        setOutputValue("");
        setErrorMessage("");
      }  

  return (
    <div className={classes.page}>
      <Helmet>
                <title>Hourly To Salary Calculator - Wiraa tools</title>
                <meta name="description" content="Use our Hourly to Salary Calculator to easily convert your hourly pay into a yearly, monthly, weekly, or daily salary. Perfect for budgeting and financial planning." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Hourly To Salary Calculator</h1>
        <p className={classes.subTitle}>Convert your hourly wage into an monthly salary effortlessly with our Hourly to Salary Calculator.</p>
      <div className={classes.innerCon}>
        <div className={classes.inputCon}>
            <p className={classes.inputplaceholder}>Hourly salary</p>
            <input value={inputValue} onChange={(e) => setInputValue(e.target.value)} className={classes.inputfield} placeholder='Enter your hourly salary'/>
            {errorMessage && <p className={classes.errorMsg}>{errorMessage}</p>}
        </div>
        <div className={classes.additionalInputCon}>
            <div className={classes.additionalInputDiv}>
                <p className={classes.addplaceholder}>Hours a day</p>
                <input value={addInput.hour} onChange={(e) => setAddInput({ ...addInput, hour: e.target.value })} className={classes.addInputfield} placeholder='Working hours in a day...'/>
            </div>
            <div className={classes.additionalInputDiv}>
                <p className={classes.addplaceholder}>Days in week</p>
                <input value={addInput.day} onChange={(e) => setAddInput({ ...addInput, day: e.target.value })} className={classes.addInputfield} placeholder='Working days in a week...'/>
            </div>
        </div>
        <div className={classes.btnCon}>
            <button className={classes.convertBtn} onClick={handleSalaryCalculation}>Calculate</button>
            <button className={classes.clearBtn} onClick={handleClearBtn}>Clear</button>
        </div>
        <div className={classes.outputCon}>
            <p className={classes.outputplaceholder}>Monthly Salary</p>
            <p className={classes.outputfield}>{outputValue}</p>
        </div>
      </div>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>Salary</h3>
        <p>An employee receives a salary or wages from their employer in exchange for their labor and time. Many nations implement minimum salaries set by the national or local governments to safeguard their workers. Additionally, unions may be established to establish norms within particular businesses or sectors.</p>
        <p>An hourly wage can be converted to an annual salary using the hourly-to-salary calculator, or vice versa. This calculator can assist employers in calculating the annual cost of increasing an hourly rate or the annual overhead business expenses of hourly workers.</p>

        <h3>The formula for calculating annual salary</h3>
        <p>The formula for calculating Annual Salary depends on the type of salary (hourly, weekly, or monthly) and its frequency. Below are the common formulas:</p>
        <h3>For Hourly Wage</h3>
        <p>Annual Salary=Hourly Wage×Hours Per Week×52</p>
        <ul>
          <li>Where 52 represents the number of weeks in a year.</li>
        </ul>

        <h3>For Weekly Wage</h3>
        <p>Annual Salary=Weekly Wage×52</p>

        <h3>For Monthly Salary</h3>
        <p>Annual Salary=Monthly Salary×12</p>
        <p>By calculating your hourly rate by the number of hours you work each week and then by the number of weeks you work each year, you may get your annual salary. Depending on whether you get paid leave, this is often either 52 or 50 weeks. The amount of your compensation will be determined before taxes and deductions.</p>

        <h3>Why Is Hourly to Salary Converter Useful?</h3>
        <ul>
          <li><b>Simplifies Calculations:</b> By automatically calculating based on hourly rates and labor hours, an hourly-to-salary converter makes estimating annual earnings simple.</li>
          <li><b>Budgeting and Planning:</b> People can better budget and prepare for savings or spending when they have a better understanding of their possible annual revenue.</li>
          <li><b>Job Comparison:</b> This tool allows job seekers to compare hourly and salaried job offers to make informed career decisions.</li>
          <li><b>Transparency for Freelancers:</b> Contractors and freelancers can compare their earnings to full-time positions or industry norms by converting their hourly rates into annual salaries.</li>
          <li><b>Customizable Options:</b> Numerous converters provide precise and customized answers for various job settings by accounting for work hours, overtime, or paid time off.</li>
        </ul>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default HourlytoSalaryCalculator;
