import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const DesignerJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Designer Jobs</title>
                <meta name="description" content="Find the best designer jobs in the US and India. Connect with leading companies and advance your career with Designer Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Designer Job Finder</h1>
        <p className={classes.subTitle}>Discover Top Designer Jobs to Create Innovative and Visually Stunning Designs</p>
      <JobFinder jobTitle={"Designer"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is a Designer?</h3>
        <p>A <b>Designer</b> is a creative professional who develops visual concepts, graphics, and layouts to communicate messages effectively. Designers work in various fields, including graphic design, web design, UI/UX design, and product design. Their goal is to create aesthetically appealing and functional designs that enhance user experience and brand identity.</p>

        <h3>Key Features of Design</h3>
        <ul>
          <li><b>Creativity:</b> Brings innovative ideas to life through visual storytelling.</li>
          <li><b>Typography & Color Theory:</b> Uses fonts and colors strategically to evoke emotions.</li>
          <li><b>User Experience (UX) Design:</b> Ensures intuitive and user-friendly interfaces.</li>
          <li><b>Brand Identity Development:</b> Designs logos, marketing materials, and brand assets.</li>
          <li><b>Software Proficiency:</b> Works with tools like Adobe Photoshop, Illustrator, Figma, and Sketch.</li>
        </ul>

        <h3>Types of Designers</h3>
        <p>Various design roles are catering to different industries:</p>
        <ul>
          <li><b>Graphic Designer:</b> Creates visual content for branding, advertisements, and digital media.</li>
          <li><b>UI/UX Designer:</b> Designs interactive interfaces for websites and mobile apps.</li>
          <li><b>Web Designer:</b> Focuses on the layout and aesthetics of websites.</li>
          <li><b>Product Designer:</b> Develops physical or digital products with functionality in mind.</li>
          <li><b>Motion Graphics Designer:</b> Works with animation and video content.</li>
        </ul>

        <h3>Average Salary of a Designer</h3>
        <p>The salary of a designer depends on their specialization, experience, and location:</p>
        <ul>
          <li><b>India:</b> ₹4 LPA – ₹15 LPA, depending on expertise and industry.</li>
          <li><b>US:</b> $50,000 – $120,000 per year, with higher salaries for UI/UX and product designers.</li>
        </ul>

        <h3>Scope of Design</h3>
        <p>The demand for designers is growing as businesses invest more in branding, user experience, and digital presence. With the expansion of e-commerce, mobile apps, and social media, skilled designers have diverse career opportunities in tech companies, advertising agencies, startups, and freelance work.</p>

        <h3>Conclusion</h3>
        <p>Design is an essential field that blends creativity with technology. Whether you specialize in graphic design, UI/UX, or product design, there are ample opportunities to build a successful career. As digital transformation continues, the demand for skilled designers will only increase, making it an exciting and rewarding profession.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default DesignerJob;
