import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const EngineerJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Engineer Jobs</title>
                <meta name="description" content="Find the best engineering jobs in the US and India. Connect with leading companies and advance your career with Engineer Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Engineer Job Finder</h1>
        <p className={classes.subTitle}>Explore Top Engineering Jobs to Innovate, Build, and Shape the Future</p>
      <JobFinder jobTitle={"Engineer"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>Who is an Engineer?</h3>
        <p>An engineer is a problem-solver who designs, develops, and enhances machines, systems, processes, and structures using scientific, mathematical, and technological expertise. Engineers work in a wide range of sectors, such as manufacturing, energy, healthcare, technology, and construction. Their knowledge is essential to efficiency, creativity, and the development of contemporary civilization.</p>
        <h3>Types of Engineering Fields</h3>
        <p>Engineering is a broad discipline with several specialized fields:</p>
        <ul>
          <li><b>Civil Engineering:</b> Designing and creating infrastructure, such as roads, bridges, and buildings.</li>
          <li><b>Mechanical Engineering:</b> It deals with thermal systems, engines, and machinery.</li>
          <li><b>Electrical Engineering:</b> Power generation, electrical circuits, and control systems are all included in electrical engineering.</li>
          <li><b>Electronic Engineering:</b> Work with communication systems, circuit boards, and semiconductors.</li>
          <li><b>Software Engineering:</b> It is the process of creating and refining software systems and applications.</li>
          <li><b>Chemical Engineering:</b> Includes the manufacturing of chemicals, medicines, and energy-related solutions.</li>
          <li><b>Biomedical Engineering:</b> Creates healthcare solutions and medical devices.</li>
          <li><b>Aerospace Engineering:</b> Designs defense systems, spacecraft, and aircraft.</li>
        </ul>
        <h3>Skills Required for Engineers</h3>
        <p>Engineers require a mix of technical knowledge, creativity, and problem-solving abilities:</p>
        <ul>
          <li>Strong mathematical and analytical skills.</li>
          <li>Knowledge of industry-specific software and tools.</li>
          <li>Creativity and innovation in designing solutions.</li>
          <li>Project management and teamwork abilities.</li>
          <li>Adaptability to new technologies and trends.</li>
        </ul>

        <h3>Salary Insights</h3>
        <p>Engineering salaries vary based on specialization, experience, and location:</p>
        <ul>
          <li><b>In India:</b> Freshers earn ₹3–7 LPA, while mid-level engineers make ₹8–15 LPA. Senior professionals can earn ₹20 LPA or more.</li>
          <li><b>In the US:</b> Entry-level engineers earn $60,000–80,000 per year, with experienced engineers making $100,000–150,000 annually.</li>
        </ul>
        <h3>Scope and Career Growth</h3>
        <p>With opportunities in robots, automation, renewable energy, and artificial intelligence, engineering is a job that will not go out of style. As industries change in response to new technologies, there will be an increasing need for engineers.</p>
        <h3>Conclusion</h3>
        <p>Engineering is a fulfilling and influential career. Engineers make the world a better place by promoting innovation and growth in fields like healthcare, technology, and construction.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default EngineerJob;
