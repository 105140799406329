import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const InfluencerJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Influencer Jobs</title>
                <meta name="description" content="Find the best influencer jobs in the US and India. Connect with leading brands and advance your career with Influencer Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Influencer Job Finder</h1>
        <p className={classes.subTitle}>Discover Top Influencer Jobs to Collaborate with Brands and Grow Your Reach</p>
      <JobFinder jobTitle={"Influencer"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <p>Influencers are essential in today's digital world because they use social media to engage audiences, promote products, and change opinions. In a variety of niches, including fashion, technology, fitness, and more, they use their online presence to influence consumer behavior, build credibility, and create insightful content.</p>
        <h3>What Influencers Do</h3>
        <p>Influencers leverage their credibility and large audience to promote products, services, and concepts. Their  key responsibilities include:</p>
        <ul>
          <li><b>Content Creation:</b> Creating captivating content such as videos, blog posts, reels, and social media updates. </li>
          <li><b>Brand Collaborations:</b> Collaborating with businesses for sponsored content and product promotions.</li>
          <li><b>Audience Engagement:</b>  Engaging with followers via comments, live broadcasts, and Q&A sessions.</li>
          <li><b>Social Media Growth:</b> Expanding their presence on platforms like Instagram, YouTube, TikTok, and Twitter. </li>
          <li><b>Personal Branding:</b> Positioning themselves as experts in a specific field or niche.</li>
          <li><b>Affiliate Marketing:</b> Generating income through product suggestions and referral links.</li>
        </ul>

        <h3>Salary and Earnings of Influencers</h3>
        <p>Earnings vary depending on the platform, follower count, engagement rate, and brand partnerships:</p>

        <ul>
          <li><b>Nano Influencers (1K–10K followers):</b> $50 – $500 per post | ₹2,000 – ₹50,000 per post</li>
          <li><b>Micro-Influencers (10K–100K followers):</b> $500 – $5,000 per post | ₹10,000 – ₹2 LPA per collaboration</li>
          <li><b>Macro Influencers (100K–1M followers):</b> $5,000 – $20,000 per post | ₹2 LPA – ₹10 LPA per campaign</li>
          <li><b>Mega Influencers (1M+ followers):</b> $20,000+ per post | ₹10 LPA+ per collaboration</li>
        </ul>

        <h3>Key Benefits of Being an Influencer</h3>
        <ul>
          <li><b>Flexible Work Schedule:</b> Enjoy the freedom to work from various locations and select collaborations.</li>
          <li><b>High Earning Potential:</b> There are chances to generate income through sponsorships, advertisements, and merchandise sales.</li>
          <li><b>Brand Authority:</b> Establishing a strong personal brand within a specific niche.</li>
          <li><b>Creative Freedom:</b> The opportunity to create content aligned with personal interests and passions.</li>
          <li><b>Networking Opportunities:</b> Engaging with brands, fellow influencers, and industry leaders.</li>
        </ul>

        <h3>Future of Influencer Marketing</h3>
        <p>As short-form content, AI-driven marketing, and niche communities gain popularity, influencers are expected to have an even more significant influence on consumer choices. Brands are increasingly relying on authentic influencers over conventional advertising, creating a landscape that is both competitive and rewarding.</p>

        <h3>Conclusion</h3>
        <p>Influencing has transformed into a profitable profession, providing creators a platform to share their knowledge, entertain, and engage with global audiences. Whether it's through lifestyle vlogs, technology reviews, or fashion showcases, influencers play a critical role in shaping trends and driving engagement in the digital landscape.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default InfluencerJob;
