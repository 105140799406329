import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const GraphicDesignerJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Graphic Designer Jobs</title>
                <meta name="description" content="Find the best graphic designer jobs in the US and India. Connect with leading companies and advance your career with Graphic Designer Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Graphic Designer Job Finder</h1>
        <p className={classes.subTitle}>Discover Top Graphic Designer Jobs to Create Stunning Visual Content</p>
      <JobFinder jobTitle={"Graphic Designer"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is a Graphic Designer?</h3>
        <p>A graphic designer is a creative professional who works with typography, color, images, and layout techniques to create visually appealing designs for digital and print media. They are essential to marketing, branding, advertising, and user experience. They use visual elements to convey messages, ideas, and branding for individuals, businesses, and organizations.</p>

        <h3>Key Features of Graphic Designing</h3>
        <ul>
          <li><b>Visual Communication:</b> Conveys messages effectively through images, typography, and colors.</li>
          <li><b>Brand Identity Design:</b> Creates logos, business cards, and branding materials for companies.</li>
          <li><b>UI & Web Design:</b> Designs user-friendly interfaces for websites and apps.</li>
          <li><b>Print & Digital Media:</b> Develops brochures, posters, social media graphics, and advertisements.</li>
          <li><b>Motion Graphics & Illustrations:</b> Works on animations, infographics, and digital illustrations.</li>
        </ul>

        <h3>Types of Graphic Design Jobs</h3>
        <p>There are several areas in which graphic designers can specialize, such as:</p>
        <ul>
          <li><b>Branding Designer:</b> Focuses on the creation of logos and brand identification.</li>
          <li><b>UI/UX Designer:</b> Creates user experiences and interfaces for apps and websites.</li>
          <li><b>Print Designer:</b> Works on printed ads, magazines, and packaging.</li>
          <li><b>Motion Graphic Designer:</b> Creates animated graphics for digital media and videos.</li>
          <li><b>Illustration Designer:</b> Digital drawing and illustrations for books, ads, or websites.</li>
        </ul>

        <h3>Average Salary of Graphic Designers</h3>
        <p>The salary of the graphic designers can vary based on expertise, experience, and industry:</p>
        <ul>
          <li><b>India:</b> ₹3 LPA – ₹12 LPA, with experienced designers earning higher.</li>
          <li><b>US:</b> $40,000 – $90,000 per year, depending on specialization.</li>
        </ul>

        <h3>Scope of Graphic Designing</h3>
        <p>With the rise of digital marketing, social media, and e-commerce, graphic designers are in high demand across industries. Businesses constantly need visual content for branding, websites, and advertising. Emerging technologies like AI-driven design tools and augmented reality (AR) offer even more opportunities for growth in this field.</p>
        <h3>Conclusion</h3>
        <p>Graphic designing is a dynamic and ever-evolving career path that combines technical know-how with creativity. Graphic designers have many chances to use images to influence others, whether they are employed by a firm or as independent contractors. This sector offers a profitable and exciting career if you have a passion for creativity and an eye for design.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default GraphicDesignerJob;
