import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const FigmaJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Figma Jobs</title>
                <meta name="description" content="Find the best Figma jobs in the US and India. Connect with leading companies and advance your career with Figma Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Figma Job Finder</h1>
        <p className={classes.subTitle}>Explore Top Figma Job Opportunities to Design Exceptional User Interfaces</p>
      <JobFinder jobTitle={"Figma"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is Figma?</h3>
        <p>Figma is a cloud-based design and prototyping tool widely used by UI/UX designers, web designers, and product teams to create interactive and collaborative designs. UI/UX designers, web designers, and product teams frequently utilize Figma, a cloud-based design and prototype tool, to produce interactive and cooperative ideas. Figma is a useful tool for teams because, in contrast to traditional design software, it enables several users to work on the same project in real time. In online and mobile app development, it is mostly utilized for wireframing, interface design, prototyping, and teamwork.</p>

        <h3>Key Features of Figma</h3>
        <ul>
          <li><b>Real-time collaboration:</b> Allows several people to collaborate on the same design simultaneously.</li>
          <li><b>Cloud-Based Platform:</b> Access designs from any location without installing software.</li>
          <li><b>Interactive design and prototyping:</b> Make interactive prototypes quickly and easily without knowing any code.</li>
          <li><b>Design Components & Libraries:</b> Consistency is ensured via reusable design components.</li>
          <li><b>Version Control & History:</b> Monitor modifications and roll back earlier iterations if required.</li>
          <li><b>Integration with Other Tools:</b> Integrate with Jira, FigJam, Slack, and other platforms, it also supports plugins.</li>
        </ul>

        <h3>Types of Jobs Using Figma</h3>
        <p>Figma is widely used by professionals in various fields, including:</p>
        <ul>
          <li><b>UI/UX Designers:</b> Create user interfaces, prototypes, and wireframes for web and mobile apps.</li>
          <li><b>Web Designers:</b> Develop website layouts and interactions.</li>
          <li><b>Product Designers:</b> Design complete digital product experiences.</li>
          <li><b>Graphic Designers:</b> Work on digital illustrations and branding materials.</li>
          <li><b>Freelance Designers:</b> Collaborate with clients remotely for creative projects.</li>
        </ul>

        <h3>Average Salary of Professionals Using Figma</h3>
        <p>Salaries for Figma Designer can vary based on role, experience, and location:</p>
        <ul>
          <li><b>India:</b> ₹4 LPA – ₹15 LPA for UI/UX designers.</li>
          <li><b>US:</b> $60,000 – $120,000 per year, depending on experience.</li>
        </ul>

        <h3>Conclusion</h3>
        <p>Figma is revolutionizing the way designers work by providing an intuitive, collaborative, and efficient design platform. Whether you are a UI/UX designer, web designer, or product developer, mastering Figma can open doors to exciting career opportunities. If you're looking to enhance your design skills and streamline your workflow, learning Figma is a great step forward.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default FigmaJob;
