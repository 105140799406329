import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const LogisticsJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Logistics Jobs</title>
                <meta name="description" content="Find top logistics job opportunities in the US and India. Connect with leading companies and grow your career with Logistics Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Logistics Job Finder</h1>
        <p className={classes.subTitle}>Explore Exciting Logistics Jobs in Supply Chain, Transportation, and Warehousing</p>
      <JobFinder jobTitle={"Logistics"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>Introduction</h3>
        <p>Logistics is the process of planning, implementing, and controlling the efficient movement of goods, services, and information from the point of origin to the final destination. It plays a crucial role in global trade, e-commerce, and manufacturing, ensuring timely delivery and cost-effective transportation. With the rise of online shopping and global supply chains, logistics has become one of the most critical industries in the world.</p>
        <h3>Key Responsibilities in Logistics</h3>
        <p>Professionals working in logistics handle various tasks, including:</p>
        <ul>
          <li><b>Supply Chain Management:</b> Coordinating raw materials, production, and distribution.</li>
          <li><b>Transportation & Shipping:</b> Managing freight, deliveries, and route optimization.</li>
          <li><b>Warehousing & Inventory Management:</b> Overseeing storage, stock levels, and order fulfillment.</li>
          <li><b>Procurement & Vendor Relations:</b> Sourcing suppliers and negotiating contracts.</li>
          <li><b>Technology Integration:</b> Utilizing AI and automation to improve tracking and efficiency.</li>
        </ul>
        <h3>Types of Logistics Careers</h3>
        <p>Logistics offers a range of career opportunities, such as:</p>
        <ul>
          <li><b>Logistics Manager:</b> Oversees supply chain operations and transportation.</li>
          <li><b>Freight Coordinator:</b> Handles shipments and ensures timely deliveries.</li>
          <li><b>Warehouse Manager:</b> Supervises storage facilities and inventory levels.</li>
          <li><b>Procurement Specialist:</b> Manages vendor relationships and purchasing strategies.</li>
          <li><b>Supply Chain Analyst:</b> Uses data to optimize logistics operations.</li>
        </ul>
        <h3>Salary Insights</h3>
        <p>Salaries in the logistics sector vary based on role, experience, and location:</p>
        <ul>
          <li><b>United States:</b> Logistics managers earn between $50,000 to $100,000 per year, while supply chain analysts can make $60,000 to $90,000 annually.</li>
          <li><b>India:</b> Salaries for logistics professionals range from ₹4 LPA to ₹15 LPA, with senior positions earning more.</li>
        </ul>
        <h3>Scope and Future of Logistics</h3>
        <ul>
          <li><b>E-commerce Growth:</b> The rise of online shopping has increased the need for advanced logistics solutions.</li>
          <li><b>Technology-Driven Operations:</b> AI, blockchain, and IoT are improving supply chain efficiency.</li>
          <li><b>Sustainable Logistics:</b> Companies are adopting eco-friendly transportation and packaging solutions.</li>
          <li><b>Global Trade Expansion:</b> International businesses require advanced logistics networks to stay competitive.</li>
        </ul>
        <h3>Conclusion</h3>
        <p>Logistics is an ever-evolving industry that plays a vital role in global trade and daily life. With technological advancements and increased demand for efficient supply chains, the field offers promising career opportunities. Whether in transportation, supply chain management, or warehousing, a career in logistics can be both rewarding and dynamic.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default LogisticsJob;
