import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const FullStackJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Full Stack Jobs</title>
                <meta name="description" content="Find the best full stack developer jobs in the US and India. Connect with leading companies and advance your career with Full Stack Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Full Stack Job Finder</h1>
        <p className={classes.subTitle}>Discover Top Full Stack Developer Jobs to Master Frontend and Backend Development</p>
      <JobFinder jobTitle={"Full Stack"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is Full Stack Development?</h3>
        <p>Full stack development refers to the ability to work on both the front end (client-side) and back end (server-side) of web applications. Full-stack developers are multifaceted experts in the computer sector, handling everything from database administration and server logic to user interface design. To create whole apps, they use a variety of programming languages, frameworks, and tools.</p>

        <h3>What Do Full Stack Developers Do?</h3>
        <p>Full-stack developers are tasked with designing, overseeing, and maintaining both the front end and back end of applications. Their primary duties involve: </p>
        <ul>
          <li>Creating user interfaces (UI) with frontend technologies such as HTML, CSS, and JavaScript.</li>
          <li>Implementing backend functionality using programming languages like Python, Node.js, PHP, or Java.</li>
          <li>Managing databases using MySQL, PostgreSQL, or MongoDB.</li>
          <li>Facilitate server and API integration to ensure seamless communication between the front end and back end.</li>
          <li>Responsible for safeguarding security and data integrity to mitigate cyber threats.</li>
          <li>Conduct testing and debugging to improve performance and resolve issues.</li>
        </ul>

        <h3>Salary Expectations for Full Stack Developers</h3>
        <p>Full stack developers are in high demand, leading to competitive salaries:</p>
        <ul>
          <li><b>India:</b> Entry-level salaries start at ₹6–10 LPA, with senior roles going up to ₹20–30 LPA.</li>
          <li><b>US:</b> Salaries range from $80,000 for junior developers to over $150,000 for experienced professionals.</li>
        </ul>

        <h3>Scope of Full Stack Development</h3>
        <ol>
          <li><b>Web and Mobile App Development</b> – Companies prefer full stack developers for their ability to handle complete projects.</li>
          <li><b>Freelancing & Remote Work</b> – High demand for independent full stack developers.</li>
          <li><b>Startup & Enterprise Applications</b> – Businesses seek developers who can build scalable applications.</li>
          <li><b>Cloud and AI Integration</b> – Growing need for developers skilled in cloud computing and AI-powered applications.</li>
          <li><b>DevOps & Automation</b> – Companies look for full stack developers with DevOps expertise for better deployment and maintenance.</li>
        </ol>

        <h3>Conclusion</h3>
        <p>Full stack development is a <b>highly rewarding career</b> with excellent growth potential. The ability to manage both frontend and backend makes full stack developers valuable assets to any organization. With continuous learning and experience, professionals in this field can secure high-paying jobs and work on innovative projects across industries.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default FullStackJob;
