import React, { useState } from 'react'
import classes from "./styles/SimpleInterest.module.css";
import RightSidebar from '../../../common/RightSidebar';
import { Helmet } from 'react-helmet';
import { FullWidthWiraaAds } from '../../../common/Ads/FullWidthWiraaAds';

const DiscountCalculator = () => {
    const [inputValue, setInputValue] = useState();
    const [interestDetails, setInterestDetails] = useState(null);
    const [addInput, setAddInput] = useState({"gstr" : ""})
    const [errorMessage, setErrorMessage] = useState();


    const handleGstCalculation = () => {
      const amount = parseFloat(inputValue);
      const gstRate = parseFloat(addInput.gstr);
  
      if (isNaN(amount) || amount <= 0) {
          setErrorMessage('Please enter a valid amount.');
          setInterestDetails(null);
          return;
      }
  
      if (isNaN(gstRate) || gstRate < 0) {
          setErrorMessage('Please enter a valid GST rate.');
          setInterestDetails(null);
          return;
      }
  
      const gstAmount = (amount * gstRate) / 100;
      const totalAmount = amount - gstAmount;
  
      setInterestDetails({
          gst: gstAmount.toFixed(2),
          total: totalAmount.toFixed(2),
      });
  
      setErrorMessage('');
  };
  
  
    
      const handleClearBtn = () => {
        setInputValue("");
        setAddInput({"gstr" : ""});
        setInterestDetails(null);
        setErrorMessage("");
      }  

  return (
    <div className={classes.page}>
      <Helmet>
                <title>Discount Calculator - Wiraa tools</title>
                <meta name="description" content="Easily calculate the discounted price with our Discount Calculator. Enter the original price and discount percentage to get the final price instantly. Perfect for quick savings calculations!" />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Discount Calculator</h1>
        <p className={classes.subTitle}>Quick & Easy Discount Calculator – Instantly Compute Your Savings and Final Price</p>
      <div className={classes.innerCon}>
        <div className={classes.inputCon}>
            <p className={classes.inputplaceholder}>Total Amount</p>
            <input value={inputValue} onChange={(e) => setInputValue(e.target.value)} className={classes.inputfield} placeholder='Enter amount'/>
            {errorMessage && <p className={classes.errorMsg}>{errorMessage}</p>}
        </div>
        <div className={classes.additionalInputCon}>
                    <div className={classes.additionalInputDiv}>
                        <p className={classes.addplaceholder}>Discount %</p>
                        <input value={addInput.gstr} onChange={(e) => setAddInput({ ...addInput, gstr: e.target.value })} className={classes.addInputfield} placeholder='Enter discount %'/>
                    </div>
                </div>
        <div className={classes.btnCon}>
            <button className={classes.convertBtn} onClick={handleGstCalculation}>Calculate</button>
            <button className={classes.clearBtn} onClick={handleClearBtn}>Clear</button>
        </div>
        {interestDetails ? <div className={classes.outputSalaryCon}>
                    <div>
                      <p className={classes.outputplaceholder}>Total Discount:</p>
                      <p className={classes.outputfield}>{interestDetails.gst}</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Final Amount:</p>
                      <p className={classes.outputfield}>{interestDetails.total}</p>
                    </div>  
        </div> : <div className={classes.outputSalaryCon}>
        <div>
                      <p className={classes.outputplaceholder}>Total Discount:</p>
                      <p className={classes.outputfield}>0</p>
                    </div>
                    <div>
                      <p className={classes.outputplaceholder}>Final Amount:</p>
                      <p className={classes.outputfield}>0</p>
                    </div>
          </div>}
      </div>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is a Discount Calculator?</h3>
        <p>The term "discount" refers to a variety of price reductions for goods or services. The two most popular types of discounts are percentage off and fixed amount off.</p>
        <p>A percent off of a price typically refers to getting some percent, say 10%, off of the original price of the product or service. For example, if a good costs $45, with a 10% discount, the final price would be calculated by subtracting 10% of $45, from $45, or equivalently, calculating 90% of $45:</p>
        <p>10% of $45 = 0.10 × 45 = $4.50</p>
        <p>$45 – $4.50 = $40.50</p>
        <p>or</p>
        <p>90% of $45 = 0.90 × 45 = $40.50</p>
        <p>In this example, you are saving 10%, or $4.50.</p>
        <p>A fixed amount off of a price means removing whatever the predetermined amount is from the original price. For example, if a service ordinarily costs $95, and you get a discount voucher for $20 off, you would deduct $20 from $95, yielding the final price:</p>
        <p>$95 - $20 = $75</p>
        <p>In this example, you are saving the fixed amount of $20.</p>

        <h3>Benefits of Using a Discount Calculator</h3>
        <ol>
          <li><b>Quick Calculation:</b> Instantly calculates discounts and final prices, saving time compared to manual computations.</li>
          <li><b>Accurate Results:</b> Eliminates the risk of errors in discount calculations, ensuring the correct final price.</li>
          <li><b>Helps with Budgeting:</b> Allows individuals and businesses to easily determine the discounted price, aiding in better budget management.</li>
          <li><b>User-Friendly:</b> Simple interface makes it accessible for everyone, even those with minimal math knowledge.</li>
          <li><b>Effective Price Comparison:</b> Helps consumers compare prices across different products or stores by calculating the discounted amount easily.</li>
        </ol>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default DiscountCalculator;
