import React, { useEffect, useState } from 'react'
import classes from "./styles/Timer.module.css";
import RightSidebar from '../../../common/RightSidebar';
import { Helmet } from 'react-helmet';
import { FullWidthWiraaAds } from '../../../common/Ads/FullWidthWiraaAds';

const OnlineTimer = () => {
    const [isTimerStart, setIsTimerStart] = useState(false);
    const [hours, setHours] = useState();
    const [minutes, setMinutes] = useState();
    const [seconds, setSeconds] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [totalSeconds, setTotalSeconds] = useState(0);

    useEffect(() => {
      let timer;
      if (isTimerStart && totalSeconds > 0) {
          timer = setInterval(() => {
              setTotalSeconds((prev) => prev - 1);
          }, 1000);
      } else if (totalSeconds === 0) {
          setIsTimerStart(false);
      }
      return () => clearInterval(timer);
  }, [isTimerStart, totalSeconds]);

  useEffect(() => {
      // Convert totalSeconds back to hours, minutes, and seconds for display
      setHours(Math.floor(totalSeconds / 3600));
      setMinutes(Math.floor((totalSeconds % 3600) / 60));
      setSeconds(totalSeconds % 60);
  }, [totalSeconds]);

    const handleStart = () => {
      if (!hours && !minutes && !seconds) {
        setErrorMessage("Please enter a valid time.");
        return;
    }
    setErrorMessage("");
    setTotalSeconds(parseInt(hours || 0) * 3600 + parseInt(minutes || 0) * 60 + parseInt(seconds || 0));
    setIsTimerStart((prev) => !prev);
    }

    const handleRestart = () => {
      setIsTimerStart(false);
      setHours("");
      setMinutes("");
      setSeconds("");
      setTotalSeconds(0);
  };


  return (
    <div className={classes.page}>
      <Helmet>
                <title>Online Timer - Countdown - Wiraa tools</title>
                <meta name="description" content="Use our Online Timer for precise countdowns. Set, start, and track time efficiently for workouts, study sessions, cooking, and more. Stay focused and productive!" />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Online Timer - Countdown</h1>
        <p className={classes.subTitle}>Stay on Track with Precision: Set, Start, and Manage Your Countdown Effortlessly!</p>
      <div className={classes.innerCon}>
        <div className={classes.additionalInputCon}>
                        <div className={classes.additionalInputDiv}>
                            <p className={classes.inputplaceholder}>Hours</p>
                            <input type="number" min="0" max="23" value={hours} onChange={(e) => setHours(e.target.value)} placeholder="HH" className={classes.addInputfield}/>
                        </div>
                        <div className={classes.additionalInputDiv}>
                            <p className={classes.inputplaceholder}>Minutes</p>
                            <input type="number" min="0" max="59" value={minutes} onChange={(e) => setMinutes(e.target.value)} placeholder="MM" className={classes.addInputfield}/>
                        </div>
                        <div className={classes.additionalInputDiv}>
                            <p className={classes.inputplaceholder}>Seconds</p>
                            <input type="number" min="0" max="59" value={seconds} onChange={(e) => setSeconds(e.target.value)} placeholder="SS" className={classes.addInputfield}/>
                        </div>
                    </div>
                    {errorMessage && <p className={classes.errorMsg}>{errorMessage}</p>}
        <div className={classes.btnCon}>
            <button className={classes.convertBtn} onClick={handleStart}>{isTimerStart ? "Pause" : "Start"}</button>
            <button className={classes.clearBtn} onClick={handleRestart}>Restart</button>
        </div>
        <div className={classes.outputCon}>
          <p className={classes.outputplaceholder}>Time Remaining:</p>
          <p className={classes.outputfield}>{String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}</p>
        </div>
      </div>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default OnlineTimer;
