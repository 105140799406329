import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const TeacherJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Teacher Jobs</title>
                <meta name="description" content="Find the best teacher jobs in the US and India. Connect with top schools and institutions to advance your teaching career with Teacher Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Teacher Job Finder</h1>
        <p className={classes.subTitle}>Discover Top Teacher Jobs to Educate, Inspire, and Shape Future Generations</p>
      <JobFinder jobTitle={"Teacher"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <p>Teaching is one of the most impactful careers, shaping the minds of future generations. Teachers have a crucial role in education because they guide students in various subjects and help them develop lifelong skills.</p>

        <h3>What Do Teachers Do?</h3>
        <p>Teachers are responsible for sharing information, guiding students, and creating a supportive learning environment. Depending on the educational level they teach, their responsibilities differ:</p>

        <ul>
          <li><b>Primary & Secondary School Teachers:</b> Focus on teaching core courses like science, math, and languages.</li>
          <li><b>High School Teachers:</b> Prepare students for higher education by specializing in specific subjects.</li>
          <li><b>College & University Professors:</b> Teach complex subjects, carry out research, and mentor students in their professional development.</li>
          <li><b>Online & Private Tutors:</b>  Provide individualized, adaptable learning opportunities.</li>
        </ul>

        <h3>Salary Insights</h3>
        <p>Teacher salaries depend on the institution, experience, and location:</p>
        <ul>
          <li><b>India:</b> ₹3–12 LPA, with higher pay for experienced and private school educators.</li>
          <li><b>US:</b> $45,000–$80,000 per year, with variations based on grade level and school district.</li>
        </ul>

        <h3>Skills Required</h3>
        <ul>
          <li>Strong communication and presentation skills</li>
          <li>Subject expertise and curriculum planning</li>
          <li>Patience and adaptability to diverse learning needs</li>
          <li>Classroom management and student engagement strategies</li>
          <li>Technology integration for modern teaching methods</li>
        </ul>

        <h3>Future Scope of Teaching</h3>
        <p>With the rise of e-learning platforms, online tutoring, and EdTech innovations, the teaching profession is evolving rapidly. Teachers now have more opportunities beyond traditional classrooms, including remote education and content creation for digital learning.</p>

        <h3>Conclusion</h3>
        <p>A career in teaching is fulfilling for those passionate about education and student development. Whether in schools, universities, or online platforms, teachers play a vital role in shaping the future.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default TeacherJob;
