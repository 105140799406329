import React, { useRef, useState } from 'react'
import QRCode from "react-qr-code";
import classes from "./styles/qrcode.module.css";
import RightSidebar from '../../../common/RightSidebar';
import { Helmet } from 'react-helmet';
import { FullWidthWiraaAds } from '../../../common/Ads/FullWidthWiraaAds';
import html2canvas from 'html2canvas';

const QrCodeGenerator = () => {
    const [type, setType] = useState("url");
    const [url, setUrl] = useState("");
    const [text, setText] = useState("");
    const [qrData, setQrData] = useState("");
    const qrRef = useRef(null);
    const [name, setName] = useState("Scan Me!");

    // Function to Generate QR Code
    const generateQR = () => {
      if (type === "url" && url.trim() !== "") {
        setQrData(url);
      } else if (type === "text" && text.trim() !== "") {
        setQrData(text);
      } else {
        alert("Please enter valid input!");
      }
    };
    

      const handleReset = () => {
        setUrl("");
        setText("");
      }

      const downloadQR = () => {
        if (!qrData) {
            alert("No QR Code to download!");
            return;
        }
        html2canvas(qrRef.current).then((canvas) => {
            const link = document.createElement("a");
            link.href = canvas.toDataURL("image/png");
            link.download = "QRCode.png";
            link.click();
        });
    };


  return (
    <div className={classes.page}>
      <Helmet>
                <title>QR Code Generator - Wiraa tools</title>
                <meta name="description" content="Easily generate QR codes for URLs and text with our free QR Code Generator. Scan and share QR codes effortlessly!" />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>QR Code Generator</h1>
        <p className={classes.subTitle}>Generate QR Codes for URLs and Text Instantly!</p>
      <div className={classes.innerCon}>
        <div className={classes.additionalInputCon}>
            <div className={classes.additionalInputDiv}>
                <p className={classes.inputplaceholder}>Type</p>
                <input type="radio" id="url" name="type" value="url" onChange={(e) => setType(e.target.value)} checked={type === "url"}/>
                    <label htmlFor="url">URL</label>
                <input type="radio" id="text" name="type" value="text" onChange={(e) => setType(e.target.value)} className={classes.radioInput}/>
                    <label htmlFor="text">Text</label>
            </div>
            {type === "url" &&
            <div className={classes.additionalInputDiv}>
                <p className={classes.inputplaceholder}>URL</p>
                <input type="url" value={url} onChange={(e) => setUrl(e.target.value)} className={classes.addInputfield} placeholder='eg: https://www.wiraa.com'/>
            </div>
            }
            {type === "text" &&
            <div className={classes.additionalInputDiv}>
                <p className={classes.inputplaceholder}>Text</p>
                <textarea rows="5" type="text" value={text} onChange={(e) => setText(e.target.value)} className={classes.addInputfield} placeholder='Message...'/>
            </div>
            }
        </div>
        <div className={classes.btnCon}>
            <button className={classes.convertBtn} onClick={generateQR}>Generate</button>
            <button className={classes.clearBtn} onClick={handleReset}>Reset</button>
        </div>
            <div 
              className={classes.outputCon}
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center'
              }}>
              
              {qrData && (
                <div>
              <div ref={qrRef} className={classes.qrcode}>
                <p>{name}</p>
                <QRCode value={qrData} size={200} />
              </div>
              <input className={classes.QRInputfield} type='text' value={name} onChange={(e) => setName(e.target.value)}/>
              <button className={classes.downloadBtn} onClick={downloadQR}>Download QR Code</button>
              </div>
            )}
            </div>  
      </div>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default QrCodeGenerator;
