import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const MusicJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Music Jobs</title>
                <meta name="description" content="Find the best animation jobs in the US and India. Connect with leading studios and advance your career with Animation Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Music Job Finder</h1>
        <p className={classes.subTitle}>Discover Top Music Job Opportunities to Create, Perform, and Inspire</p>
      <JobFinder jobTitle={"Music"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <p>Music is a powerful way of expression, a universal language that connects people and evokes emotions. Careers in the music industry provide creative brains with countless opportunities, whether they involve writing, performing, producing, or managing.</p>

        <h3>Career Paths in Music</h3>
        <p>There are many distinct roles in the music industry that cater to different talents and skills:</p>
        <ul>
          <li><b>Musicians & Singers:</b> Performers that compose and perform music in different genres.</li>
          <li><b>Music Producers:</b> Oversees song and album recording and production.</li>
          <li><b>Sound Engineers:</b> Works on mastering, mixing, and enhancing sound quality.</li>
          <li><b>Composers and Songwriters:</b> Write unique songs and lyrics for movies, TV shows, and commercials.</li>
          <li><b>Teachers and Music Instructors:</b> Instruct potential musicians and assist them in refining their skills.</li>
          <li><b>Music Directors:</b> Conducting choirs, orchestras, and musical concerts.</li>
        </ul>

        <h3>Salary & Opportunities in Music</h3>
        <p>The earnings in the music industry change according to experience, role, and location:</p>

        <ul>
          <li><b>In India:</b> Entry-level musicians or producers may earn ₹3–5 LPA, while established professionals can make ₹15 LPA or more, especially in Bollywood and independent music.</li>
          <li><b>In the US:</b> Salaries range from $40,000 to $100,000 per year, but top musicians and producers earn significantly higher through royalties, performances, and collaborations.</li>
        </ul>

        <h3>The Future of Music</h3>
        <p>With the growth of digital platforms like Spotify, YouTube, and Apple Music, independent artists now have more opportunities to reach a global audience. AI and technology are also reshaping music production, making it more accessible to creators.</p>

        <h3>Conclusion</h3>
        <p>Passion, creativity, and imagination are what boost the music industry. For individuals committed to becoming experts in their field, the sector provides fascinating prospects as a producer, artist, or behind-the-scenes specialist.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default MusicJob;
