import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const TestingJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>Testing Jobs</title>
                <meta name="description" content="Find the best testing jobs in the US and India. Connect with leading companies and advance your career with Testing Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Testing Job Finder</h1>
        <p className={classes.subTitle}>Explore Top Testing Opportunities and Ensure the Quality of Cutting-Edge Software</p>
      <JobFinder jobTitle={"Testing"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
        <h3>What is Testing?</h3>
        <p>Testing is the process of assessing software programs to make sure they are error-free, fulfill user requirements, and operate as intended. Before a product is delivered to end users, it is an essential step in the software development lifecycle (SDLC) that helps preserve its quality, performance, and security. Depending on the project objectives and software complexity, testing can be either automated or manual.</p>

        <h3>What Do Testers Do?</h3>
        <p>Key responsibilities of the tester include:</p>

        <ul>
          <li><b>Understanding Requirements:</b> Interpreting software specifications and user needs.</li>
          <li><b>Formulating Test Plans:</b> Crafting organized test cases and testing methodologies.</li>
          <li><b>Conducting Tests:</b> Implementing both manual and automated test cases to identify defects.</li>
          <li><b>Identifying Bugs:</b> Recognizing errors, discrepancies, and problems within the software. </li>
          <li><b>Reporting Issues:</b> Recording bugs and collaborating with developers for resolution.</li>
          <li><b>Regression Testing:</b> Verifying that recent updates do not introduce new defects.</li>
          <li><b>Performance & Security Testing:</b> Evaluating the software's performance under various conditions while ensuring its security against potential threats</li>
        </ul>

        <h3>Types of Software Testing</h3>
        <ol>
          <li><b>Manual Testing</b> – Conducted by testers without the use of automation tools.</li>
          <li><b>Automation Testing</b> – Employs scripts and tools such as Selenium, TestNG, or JUnit for testing automation.</li>
          <li><b>Unit Testing</b> – Assessing individual components or functions of the software.</li>
          <li><b>Integration Testing</b> – Verifying that various modules integrate seamlessly.</li>
          <li><b>System Testing</b> – Evaluating the entire system as a unified entity.</li>
          <li><b>User Acceptance Testing (UAT)</b> – Testing the software in a real-world environment before launch.</li>
          <li><b>Performance Testing</b> – Measuring the application’s speed, responsiveness, and stability.</li>
          <li><b>Security Testing</b> – Detecting vulnerabilities and ensuring data protection.</li>
        </ol>

        <h3>Salary Expectations for Testers</h3>
        <p>Software testers are well-compensated due to the critical role they play:</p>
        <ul>
          <li><b>India:</b> Entry-level salaries range from ₹4–8 LPA, with experienced testers earning ₹12–20 LPA.</li>
          <li><b>US:</b> Salaries start at $70,000 annually and can go beyond $120,000 for senior professionals.</li>
        </ul>

        <h3>Conclusion</h3>
        <p>Software development requires testing to make sure that programs function as intended, remain secure, and provide a flawless user experience. The need for qualified testers is increasing due to automation, artificial intelligence, and cloud computing, which makes it a lucrative job choice in the tech sector.</p>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default TestingJob;
